import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AntMetricType } from './antMetrics';

interface PreferencesValue {
  liveMetricTypes: AntMetricType[];
  setLiveMetricTypes: (types: AntMetricType[]) => void;
  liveFontScale: number;
  setLiveFontScale: (scale: number) => void;
  deviceNames: { [key: string]: string };
  setDeviceName: (id: string, name: string) => void;
  targetWatts?: number;
  setTargetWatts: (value?: number) => void;
}

const PreferencesContext = createContext<PreferencesValue>(
  // @ts-ignore
  {}
);

const USER_PREFERENCES = 'ANT_USER_PREFERENCES';

interface Preferences {
  liveMetricTypes: AntMetricType[];
  liveFontScale: number;
  deviceNames: {};
  targetWatts?: number;
}

let initialPreferences: Preferences = {
  liveMetricTypes: [
    AntMetricType.time,
    AntMetricType.distance,
    AntMetricType.rate,
    AntMetricType.watts,
    AntMetricType.intensity,
  ],
  liveFontScale: 100,
  targetWatts: 202,
  deviceNames: {},
};

const Store = {
  getPreferences: () => {
    const value = localStorage.getItem(USER_PREFERENCES);
    if (value) {
      let storedValues = JSON.parse(value) as Preferences;
      return { ...initialPreferences, ...storedValues };
    } else {
      return initialPreferences;
    }
  },
  setPreferences: (preferences: Preferences) =>
    localStorage.setItem(USER_PREFERENCES, JSON.stringify(preferences)),
};

export const PreferencesProvider = (props: any) => {
  const [preferences, setPreferences] = useState(initialPreferences);

  const savePreferences = useCallback((newPreferences: Preferences) => {
    initialPreferences = newPreferences;
    setPreferences(newPreferences);
    Store.setPreferences(newPreferences);
  }, []);

  useEffect(() => {
    const preferences = Store.getPreferences();
    initialPreferences = preferences;
    setPreferences(preferences);
  }, []);

  const { liveMetricTypes, liveFontScale, deviceNames, targetWatts } =
    preferences;

  const setLiveMetricTypes = useCallback(
    (types: AntMetricType[]) => {
      savePreferences({
        ...initialPreferences,
        liveMetricTypes: types,
      });
    },
    [savePreferences]
  );

  const setLiveFontScale = useCallback(
    (liveFontScale: number) => {
      savePreferences({ ...initialPreferences, liveFontScale });
    },
    [savePreferences]
  );

  const setDeviceName = useCallback(
    (id: string, name: string) => {
      savePreferences({
        ...initialPreferences,
        deviceNames: { ...initialPreferences.deviceNames, [id]: name },
      });
    },
    [savePreferences]
  );

  const setTargetWatts = useCallback(
    (value?: number) => {
      savePreferences({
        ...initialPreferences,
        targetWatts: value,
      });
    },
    [savePreferences]
  );

  const value = {
    liveMetricTypes,
    setLiveMetricTypes,
    liveFontScale,
    setLiveFontScale,
    deviceNames,
    setDeviceName,
    targetWatts,
    setTargetWatts,
  };

  return <PreferencesContext.Provider value={value} {...props} />;
};

const usePreferences = () => {
  return useContext(PreferencesContext) as PreferencesValue;
};

export default usePreferences;
