import React from 'react';
import { TextStyle } from 'react-native';
import { Text } from 'react-native-paper';
import { formatTime, roundFloat } from 'shared/utils';
import { FitnessEquipmentSensorState } from 'utils/ant/fitness-equipment-sensors';

export enum AntMetricType {
  time = 'time',
  distance = 'distance',
  pace = 'pace',
  rate = 'rate',
  strokes = 'strokes',
  dragFactor = 'dragFactor',
  calories = 'calories',
  watts = 'watts',
  spi = 'spi',
  intensity = 'intensity',
}

export interface AntDevices {
  [key: string]: FitnessEquipmentSensorState;
}

interface HeaderProps {
  devices: AntDevices;
  fontSize: number;
  targetWatts?: number;
}

interface CellProps {
  device: FitnessEquipmentSensorState;
  fontSize: number;
  targetWatts?: number;
}

const RenderText = ({
  value,
  fontSize,
  style,
}: {
  value: string | number | undefined;
  fontSize: number;
  style?: TextStyle;
}) => <Text style={[{ fontSize }, style]}>{value || '-'}</Text>;

export const ANT_METRICS = {
  [AntMetricType.time]: {
    name: 'Time',
    renderHeader: ({ fontSize, devices }: HeaderProps) => (
      <RenderText
        value={formatTime(calculateMax(devices, 'ElapsedTime'))}
        fontSize={fontSize}
      />
    ),
    renderCell: ({ fontSize, device }: CellProps) => (
      <RenderText
        value={formatTime(device.ElapsedTime || 0)}
        fontSize={fontSize}
      />
    ),
  },
  [AntMetricType.distance]: {
    name: 'Distance',
    renderHeader: ({ fontSize, devices }: HeaderProps) => (
      <RenderText
        value={calculateTotal(devices, 'Distance')}
        fontSize={fontSize}
      />
    ),
    renderCell: ({ fontSize, device }: CellProps) => (
      <RenderText value={device.Distance} fontSize={fontSize} />
    ),
  },
  [AntMetricType.pace]: {
    name: 'Pace',
    renderHeader: ({ fontSize, devices }: HeaderProps) => (
      <RenderText
        value={
          calculateAvg(devices, 'InstantaneousPower')
            ? calculatePace(calculateAvg(devices, 'InstantaneousPower'))
            : ''
        }
        fontSize={fontSize}
      />
    ),
    renderCell: ({ fontSize, device }: CellProps) => (
      <RenderText
        value={
          device.InstantaneousPower
            ? calculatePace(device.InstantaneousPower!)
            : ''
        }
        fontSize={fontSize}
      />
    ),
  },
  [AntMetricType.watts]: {
    name: 'Watts',
    renderHeader: ({ fontSize, devices }: HeaderProps) => (
      <RenderText
        value={calculateAvg(devices, 'InstantaneousPower')}
        fontSize={fontSize}
      />
    ),
    renderCell: ({ fontSize, device }: CellProps) => (
      <RenderText value={device.InstantaneousPower} fontSize={fontSize} />
    ),
  },
  [AntMetricType.spi]: {
    name: 'SPI',
    renderHeader: ({ fontSize, devices }: HeaderProps) => {
      const values = Object.values(devices)
        .map(calculateSpi)
        .filter((v) => !!v);
      const total = values.reduce((acc, v) => (acc || 0) + (v || 0), 0) || 0;
      const totalSpi = total / values.length;

      return (
        <RenderText
          value={totalSpi ? totalSpi.toFixed(1) : 0}
          fontSize={fontSize}
        />
      );
    },
    renderCell: ({ fontSize, device }: CellProps) => {
      const spi = calculateSpi(device);
      return (
        <RenderText value={spi ? spi.toFixed(1) : 0} fontSize={fontSize} />
      );
    },
  },
  [AntMetricType.rate]: {
    name: 'Rate',
    renderHeader: ({ fontSize, devices }: HeaderProps) => (
      <RenderText
        value={calculateAvg(devices, 'Cadence')}
        fontSize={fontSize}
      />
    ),
    renderCell: ({ fontSize, device }: CellProps) => (
      <RenderText value={device.Cadence} fontSize={fontSize} />
    ),
  },
  [AntMetricType.strokes]: {
    name: 'Strokes',
    renderHeader: ({ fontSize, devices }: HeaderProps) => (
      <RenderText
        value={calculateTotal(devices, 'Strokes')}
        fontSize={fontSize}
      />
    ),
    renderCell: ({ fontSize, device }: CellProps) => (
      <RenderText value={device.Strokes} fontSize={fontSize} />
    ),
  },
  [AntMetricType.calories]: {
    name: 'Calories',
    renderHeader: ({ fontSize, devices }: HeaderProps) => (
      <RenderText
        value={calculateTotal(devices, 'Calories')}
        fontSize={fontSize}
      />
    ),
    renderCell: ({ fontSize, device }: CellProps) => (
      <RenderText value={device.Calories} fontSize={fontSize} />
    ),
  },
  [AntMetricType.dragFactor]: {
    name: 'Drag',
    renderHeader: ({ fontSize, devices }: HeaderProps) => (
      <RenderText
        value={calculateAvg(devices, 'Resistance')}
        fontSize={fontSize}
      />
    ),
    renderCell: ({ fontSize, device }: CellProps) => (
      <RenderText value={device.Resistance} fontSize={fontSize} />
    ),
  },
  [AntMetricType.intensity]: {
    name: 'Intensity',
    renderHeader: ({ fontSize, devices, targetWatts }: HeaderProps) => {
      const percentage = calculatePercentage(
        calculateAvg(devices, 'InstantaneousPower'),
        targetWatts
      );
      const color = percentageColor(percentage);
      if (percentage) {
        return (
          <RenderText
            value={`${percentage}%`}
            fontSize={fontSize}
            style={{ color }}
          />
        );
      } else {
        return <RenderText value="-" fontSize={fontSize} />;
      }
    },
    renderCell: ({ fontSize, device, targetWatts }: CellProps) => {
      const percentage = calculatePercentage(
        device.InstantaneousPower,
        targetWatts
      );
      const color = percentageColor(percentage);
      if (percentage) {
        return (
          <RenderText
            value={`${percentage}%`}
            fontSize={fontSize}
            style={{ color }}
          />
        );
      } else {
        return <RenderText value="-" fontSize={fontSize} />;
      }
    },
  },
};

function calculateSpi(device: FitnessEquipmentSensorState) {
  return device.InstantaneousPower && device.Cadence
    ? device.InstantaneousPower / device.Cadence
    : 0;
}

function calculateTotal(
  devices: AntDevices,
  key: 'Distance' | 'Strokes' | 'Calories'
) {
  return Object.values(devices).reduce(
    (acc, device) => acc + (device[key] || 0),
    0
  );
}

function calculateMax(devices: AntDevices, key: 'ElapsedTime') {
  const values = Object.values(devices).map((d) => d[key] || 0);
  return Math.max(...values);
}

function calculateAvg(
  devices: AntDevices,
  key: 'Cadence' | 'InstantaneousPower' | 'Resistance'
) {
  const values = Object.values(devices)
    .map((d) => d[key])
    .filter((v) => v !== undefined);

  const total = values.reduce((acc, v) => (acc || 0) + (v || 0), 0) || 0;

  return roundFloat(total / values.length);
}

const calculatePace = (watts: number) => {
  const pacecubed = 2.8 / watts;
  const cuberoot = Math.pow(pacecubed, 1 / 3);
  const pace = cuberoot * 500;
  const pacemin = pace / 60;
  const pacesecs = (pacemin - Math.floor(pacemin)) * 60;
  let tenths = pacesecs.toFixed(1);

  if (Number(tenths) < 10) {
    tenths = 0 + tenths;
  }

  return Math.floor(pacemin) + ':' + tenths;
};

function calculatePercentage(value: number | undefined, targetWatts?: number) {
  if (value && targetWatts) {
    return Math.floor((value / targetWatts) * 100);
  } else {
    return 0;
  }
}
function percentageColor(percentage: number) {
  if (percentage >= 90) {
    return '#51a316';
  } else if (percentage >= 50) {
    return '#fe9e0a';
  } else {
    return '#da2b44';
  }
}
