import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { IconButton } from 'react-native-paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Channel } from 'phoenix';

const supportedEmojis = ['😍', '❤️', '🔥', '👍', '💪', '👊', '👏', '🌶️', '🍿'];

const TOOLBAR_HEIGHT = 48;

const generateEmoji = (element: any, emojiIndex: number) => {
  const yPosition = Math.random() * (TOOLBAR_HEIGHT / 2);
  const emoji = supportedEmojis[emojiIndex];

  const newElement = document.createElement('div');
  newElement.className = 'emoji';
  newElement.style.top = `${yPosition}px`;

  newElement.appendChild(document.createTextNode(emoji));

  element.insertBefore(newElement, element.firstChild);

  setTimeout(() => {
    newElement.remove();
  }, 4000);
};

interface Props {
  channel: Channel;
  liveReactionIndex: number;
  setLiveReactionIndex: (index: number) => void;
}
const EmojiDisplay = ({
  channel,
  liveReactionIndex,
  setLiveReactionIndex,
}: Props) => {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    channel.on('new_reaction', ({ index }) => {
      generateEmoji(containerRef.current, index);
    });

    return () => {
      channel.off('new_reaction');
    };
  }, [channel, containerRef]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{ position: 'fixed', height: TOOLBAR_HEIGHT, width: '100vw' }}
      // @ts-ignore
      ref={containerRef}
    >
      <div
        style={{ position: 'absolute', right: 60, top: 4 }}
        ref={buttonRef}
      />
      <IconButton
        style={styles.button}
        color="white"
        icon={() => <div>{supportedEmojis[liveReactionIndex]}</div>}
        onPress={() => {
          generateEmoji(containerRef.current, liveReactionIndex);
          channel.push('new_reaction', { index: liveReactionIndex });
        }}
        onLongPress={() => {
          setAnchorEl(buttonRef.current);
        }}
      />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {supportedEmojis.map((emoji, index) => (
          <MenuItem
            key={emoji}
            selected={index === liveReactionIndex}
            onClick={() => {
              setLiveReactionIndex(index);
              handleClose();
            }}
          >
            {emoji}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    right: 12,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0, 0.1)',
  },
});

export default EmojiDisplay;
