import moment from 'moment';
import React, { useRef, useState } from 'react';
import { View } from 'react-native';
import { Button, Card } from 'react-native-paper';
import OptionsPicker from 'shared/components/OptionsPicker';
import { RaceEvent, RaceStatus, Workout } from 'shared/types';
import { formatTimeWithHours } from 'shared/utils';
import { isMobileApp } from 'utils/mobile';

interface Props {
  workout: Workout;
  sendRaceEvent: (raceEvent: RaceEvent) => void;
}

const RaceSettings = ({ workout, sendRaceEvent }: Props) => {
  const [countdownOption, setCountdownOption] = useState<number | string>(30);
  const scheduledWorkouts =
    workout && workout.scheduledWorkouts ? workout.scheduledWorkouts : [];

  const [raceEvent, setRaceEvent] = useState<RaceEvent>({
    status: RaceStatus.Waiting,
  });
  const countdownRunning = useRef(false);

  const sendRaceEventLocal = (raceEvent: RaceEvent) => {
    sendRaceEvent(raceEvent);
    setRaceEvent(raceEvent);
  };

  if (raceEvent.status === RaceStatus.Go || isMobileApp()) {
    return null;
  }

  return (
    <View style={{ width: '100%', marginTop: 20, alignItems: 'center' }}>
      <Card
        style={{
          padding: 20,
        }}
      >
        {raceEvent.status === RaceStatus.Waiting && (
          <>
            <OptionsPicker
              currentValue={countdownOption}
              height={40}
              paddingHorizontal={10}
              onChange={(value) => {
                setCountdownOption(value);
              }}
              options={[
                ...scheduledWorkouts.map((s) => ({
                  value: s.scheduledAt,
                  name: `${moment(s.scheduledAt).format('ddd, MMM D, h:mm A')}: ${s.title}`,
                })),
                { value: 10, name: '10 seconds' },
                { value: 30, name: '30 seconds' },
                { value: 60, name: '1 minute' },
                { value: 300, name: '5 minutes' },
                { value: 600, name: '10 minutes' },
                { value: 1800, name: '30 minutes' },
                { value: 3600, name: '1 hour' },
              ]}
            />
            <Button
              icon="play"
              mode="outlined"
              style={{ marginTop: 20 }}
              onPress={() => {
                const endTime =
                  typeof countdownOption === 'number'
                    ? moment().add(countdownOption + 1, 'seconds')
                    : moment(countdownOption);

                if (endTime.isBefore(moment())) {
                  return;
                }

                countdownRunning.current = true;

                const interval = setInterval(() => {
                  const countdown = Math.floor(endTime.diff(moment()) / 1000);
                  if (countdown <= 0) {
                    clearInterval(interval);
                    sendRaceEventLocal({ status: RaceStatus.Go });
                  } else if (!countdownRunning.current) {
                    clearInterval(interval);
                  } else {
                    sendRaceEventLocal({
                      status: RaceStatus.Countdown,
                      countdown: countdown,
                    });
                  }
                }, 500);
              }}
            >
              Start Race Countdown
            </Button>
          </>
        )}

        {raceEvent.status === RaceStatus.Countdown && (
          <Button
            icon="stop"
            color={'black'}
            style={{ backgroundColor: 'yellow' }}
            onPress={() => {
              countdownRunning.current = false;
              sendRaceEventLocal({ status: RaceStatus.Waiting });
            }}
          >
            {formatTimeWithHours(raceEvent.countdown || 0)} TO START
          </Button>
        )}
      </Card>
    </View>
  );
};

export default React.memo(RaceSettings);
