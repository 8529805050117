import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  List,
  TextInput,
} from 'react-native-paper';
import OptionsPicker from 'shared/components/OptionsPicker';
import { AntMetricType, ANT_METRICS } from './antMetrics';
import usePreferences from './useAntPreferences';

interface Props {
  onDismiss: () => void;
}

const MetricItem = ({
  metric,
  index,
  availableMetrics,
  liveMetricTypes,
  setLiveMetricTypes,
}: {
  metric: AntMetricType;
  index: number;
  availableMetrics: AntMetricType[];
  liveMetricTypes: AntMetricType[];
  setLiveMetricTypes: (types: AntMetricType[]) => void;
}) => {
  const options = availableMetrics
    .filter((m) => m === metric || !liveMetricTypes.includes(m))
    .map((m) => ({
      value: m,
      name: ANT_METRICS[m].name,
    }));

  return (
    <List.Item
      title={`Metric ${index + 1}`}
      right={() => (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <OptionsPicker
            options={options}
            currentValue={metric}
            onChange={(newMetric) => {
              liveMetricTypes[index] = newMetric;
              setLiveMetricTypes([...liveMetricTypes]);
            }}
          />
          <IconButton
            icon="trash-can-outline"
            onPress={() =>
              setLiveMetricTypes(liveMetricTypes.filter((m) => m !== metric))
            }
          />
        </View>
      )}
    />
  );
};

const availableMetrics = [
  AntMetricType.time,
  AntMetricType.distance,
  AntMetricType.pace,
  AntMetricType.rate,
  AntMetricType.watts,
  AntMetricType.calories,
  AntMetricType.strokes,
  AntMetricType.dragFactor,
  AntMetricType.intensity,
  AntMetricType.spi,
];

const AntSettings = ({ onDismiss }: Props) => {
  const { liveMetricTypes, setLiveMetricTypes, targetWatts, setTargetWatts } =
    usePreferences();

  return (
    <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
      <Dialog visible onDismiss={onDismiss} style={styles.dialog}>
        <Dialog.Title>Metrics</Dialog.Title>

        <ScrollView>
          <List.Item
            title={`Target Watts`}
            right={() => (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <TextInput
                  mode="outlined"
                  style={{ maxWidth: 120 }}
                  dense
                  value={targetWatts ? String(targetWatts) : undefined}
                  onChangeText={(text) =>
                    setTargetWatts(!!text ? parseInt(text) : undefined)
                  }
                />
              </View>
            )}
          />
          {liveMetricTypes.map((metric, index) => (
            <MetricItem
              key={metric}
              index={index}
              availableMetrics={availableMetrics}
              metric={metric}
              liveMetricTypes={liveMetricTypes}
              setLiveMetricTypes={setLiveMetricTypes}
            />
          ))}
          <Button
            icon="plus"
            style={{ alignSelf: 'center' }}
            onPress={() => {
              const nextMetric = availableMetrics.find(
                (m) => !liveMetricTypes.includes(m)
              );
              if (nextMetric) {
                setLiveMetricTypes([...liveMetricTypes, nextMetric]);
              }
            }}
          >
            Add new metric
          </Button>
        </ScrollView>

        <Divider />
        <Dialog.Actions>
          <Button onPress={onDismiss}>Close</Button>
        </Dialog.Actions>
      </Dialog>
    </div>
  );
};

const styles = StyleSheet.create({
  dialog: {
    height: '90vh',
    alignSelf: 'center',
    width: '90%',
    maxWidth: 400,
  },
});

export default AntSettings;
