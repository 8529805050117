import React, { useEffect } from 'react';
import { ActivityIndicator, StatusBar, StyleSheet, View } from 'react-native';
import { persistSessionToken } from 'utils/session';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

interface Params {
  sessionId: string;
}

const SESSION_CREATION = gql`
  mutation CreateSession($token: String!) {
    session: createSession(token: $token, device: {}) {
      id
    }
  }
`;

const AuthLoadingScreen = () => {
  const [createSession] = useMutation(SESSION_CREATION, {});
  const { sessionId } = useParams<Params>();
  useEffect(() => {
    if (sessionId) {
      createSession({ variables: { token: sessionId } }).then(({ data }) => {
        if (data) {
          persistSessionToken(data.session.id);
          window.location.href = '/';
        }
      });
    }
  }, [sessionId, createSession]);

  return (
    <View style={styles.container}>
      <ActivityIndicator />
      <StatusBar barStyle="default" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default AuthLoadingScreen;
