import React from 'react';
import {
  StyleSheet,
  ViewStyle,
  TextStyle,
  ImageStyle,
  View,
  Image,
} from 'react-native';

const styled = (
  Component: any,
  style: ViewStyle | TextStyle | ImageStyle = {}
) => {
  const styles = StyleSheet.create({
    style,
  });

  return (props: any) => <Component style={styles.style} {...props} />;
};

styled.view = (style: ViewStyle = {}) => styled(View, style);
styled.text = (style: TextStyle = {}) => styled(Text, style);
styled.image = (style: ImageStyle = {}) => styled(Image, style);

export default styled;
