import useCurrentUser from 'hooks/useCurrentUser';
import LoginPage from 'pages/LoginPage';
import SourcesList from 'pages/SourcesList';
import TrackShow from 'pages/TrackShow';
import UserShow from 'pages/UserShow';
import UserStatsPage from 'pages/UserStatsPage';
import TrackStatsPage from 'pages/TrackStatsPage';
import WorkoutShow from 'pages/WorkoutShow';
import WorkoutLive from 'pages/WorkoutLive';
import WorkoutLeaderboard from 'pages/WorkoutLeaderboard';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthLoading from './pages/AuthLoading';
import BillingPage from './pages/BillingPage';
import UsersList from './pages/UsersList';
import LiveList from './pages/LiveList';
import AntPlusPage from './pages/AntPlus/AntPlusPage';
import { isBillingAvailable } from 'utils/helpers';

const Routes = () => {
  const { adminTracks, currentUser } = useCurrentUser();
  return (
    <Switch>
      <Route path="/auth/:sessionId">
        <AuthLoading />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      {currentUser && currentUser.superadmin && (
        <Route path="/sources">
          <SourcesList />
        </Route>
      )}
      <Route path="/live" exact>
        <LiveList />
      </Route>
      <Route path="/antplus" exact>
        <AntPlusPage />
      </Route>
      <Route path="/tracks/:trackId/:tab?">
        <TrackShow />
      </Route>
      <Route path="/live/:workoutToken" exact>
        <WorkoutLive />
      </Route>
      <Route path="/workouts/:workoutId/live" exact>
        <WorkoutLive />
      </Route>
      <Route path="/workouts/:workoutId/leaderboard" exact>
        <WorkoutLeaderboard />
      </Route>
      <Route path="/stats" exact>
        <UserStatsPage />
      </Route>
      <Route path="/track-stats/:trackId" exact>
        <TrackStatsPage />
      </Route>
      <Route path="/workouts/:workoutId/:tab?">
        <WorkoutShow />
      </Route>
      <Route path="/users/:userId/:tab?">
        <UserShow />
      </Route>
      {currentUser && currentUser.superadmin && (
        <Route path="/users">
          <UsersList />
        </Route>
      )}
      {currentUser && isBillingAvailable(currentUser) && (
        <Route path="/billing">
          <BillingPage />
        </Route>
      )}
      <Route path="/" exact>
        <Redirect
          to={{
            pathname:
              adminTracks.length > 0
                ? currentUser.superadmin
                  ? '/live'
                  : `/tracks/${adminTracks[0].id}/workouts`
                : '/login',
          }}
        />
      </Route>
    </Switch>
  );
};

export default Routes;
