import { useQuery } from '@apollo/react-hooks';
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridSortModel,
} from '@mui/x-data-grid';
import { gql } from 'apollo-boost';
import Link from 'components/Link';
import ResultModal from 'components/ResultModal';
import useCurrentUser from 'hooks/useCurrentUser';
import { capitalize } from 'lodash';
import MetricTypesField from 'pages/WorkoutLive/MetricTypesField';
import Papa from 'papaparse';
import React, { useMemo, useState } from 'react';
import { ScrollView, View } from 'react-native';
import {
  ActivityIndicator,
  Button,
  IconButton,
  List,
  Menu,
  Text,
  Theme,
} from 'react-native-paper';
import { METRICS, MetricType } from 'shared/components/metrics';
import { WORKOUT_RESULT_FRAGMENT } from 'shared/graphql/fragments';
import { colors, theme } from 'shared/styles';

import usePreferences from 'hooks/usePreferences';
import {
  DeviceStatus,
  ErgType,
  Interval,
  User,
  UserDisplay,
  Workout,
  WorkoutResult,
} from 'shared/types';
import {
  calculateDeviceTypes,
  calculatePace,
  formatFitScore,
  getInitialErgBenchmarks,
} from 'shared/utils';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { pm5Firmware } from 'utils/helpers';
import styled from 'utils/styled';

const boldTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    regular: theme.fonts.medium,
  },
};

const INITIAL_ERG_BENCHMARKS = getInitialErgBenchmarks();
const fontSize = 14;
const RenderCell = ({
  params,
  metricType,
}: {
  params: any;
  metricType: MetricType;
}) => {
  const RenderInterval = METRICS[metricType].renderCell;
  const RenderOverall = METRICS[metricType].renderHeaderCell;
  const user = params.getValue(params.id, 'user');
  const ergType = params.getValue(params.id, 'ergType');
  const intervals = params.getValue(params.id, 'intervals') as Interval[];

  const overall = (fontTheme: Theme) => (
    <RenderOverall
      theme={fontTheme}
      fontSize={fontSize}
      intervals={intervals}
      ergType={ergType}
      deviceTypes={calculateDeviceTypes(intervals)}
      ergBenchmarks={INITIAL_ERG_BENCHMARKS}
      currentUser={user}
      elapsedTime={(params.getValue(params.id, 'time') || 0) * 100}
      elapsedDistance={(params.getValue(params.id, 'distance') || 0) * 10}
      activeIndex={0}
      deviceStatus={DeviceStatus.WorkoutEnded}
      isPro={true}
    />
  );

  return params.getValue(params.id, 'expand') ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        lineHeight: 'normal',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }}
    >
      {overall(boldTheme)}
      {intervals.map((interval: Interval, index: number) => (
        <RenderInterval
          key={index}
          interval={interval}
          theme={theme}
          fontSize={fontSize}
          currentUser={user}
          ergType={interval.ergType || ergType}
          ergBenchmarks={INITIAL_ERG_BENCHMARKS}
          isPro={true}
        />
      ))}
    </div>
  ) : (
    overall(theme)
  );
};

enum ColumnType {
  name = 'name',
  date = 'date',
  score = 'score',
  rx = 'rx',
  ranked = 'ranked',
  status = 'status',
  time = 'time',
  distance = 'distance',
  pace = 'pace',
  c2Id = 'c2Id',
  device = 'device',
  firmware = 'firmware',
  actions = 'actions',
}

enum IntervalMode {
  expand = 'expand',
  pace = 'pace',
}

export const availableExtraMetrics = [
  MetricType.rate,
  MetricType.heartRate,
  MetricType.heartRateRaw,
  MetricType.watts,
  MetricType.spi,
  MetricType.calories,
  MetricType.rest,
  MetricType.strokes,
  MetricType.dragFactor,
  MetricType.driveLength,
  MetricType.strokeDistance,
  MetricType.peakPower,
  MetricType.avgForce,
  MetricType.peakForce,
  MetricType.forceEfficiency,
  MetricType.ratio,
  MetricType.wattsKg,
  MetricType.forceCurve,
  MetricType.calHour,
];

const defaultOptions = {
  width: 160,
  flex: 0.8,
  headerAlign: 'right',
  align: 'right',
};
const RESULT_COLUMNS = [
  {
    field: ColumnType.name,
    headerName: 'Name',
    width: 150,
    flex: 1,
    valueGetter: (params: any) => params.getValue(params.id, 'user').name,
    renderCell: (params: any) => (
      <Link to={`/users/${params.getValue(params.id, 'user').id}/results`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: ColumnType.date,
    headerName: 'Date',
    type: 'date',
    ...defaultOptions,
    valueGetter: (params: any) => params.getValue(params.id, 'insertedAt'),
    renderCell: (params: any) => {
      const notes = params.getValue(params.id, 'notes');
      return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {!!notes && (
            <Tooltip title={notes}>
              <span>
                <IconButton
                  size={16}
                  color={colors.textColor}
                  icon="note-outline"
                ></IconButton>
              </span>
            </Tooltip>
          )}
          {new Date(params.value).toLocaleDateString()}
        </span>
      );
    },
  },
  {
    field: ColumnType.score,
    headerName: 'Score',
    ...defaultOptions,
    valueGetter: (params: any) => params.getValue(params.id, 'scoreValue'),
    renderCell: (params: any) =>
      formatFitScore(params.value, params.getValue(params.id, 'scoreType')),
  },
  {
    field: ColumnType.rx,
    headerName: 'Rx',
    type: 'boolean',
    ...defaultOptions,
    valueGetter: (params: any) => params.getValue(params.id, 'isRx'),
  },
  {
    field: ColumnType.ranked,
    headerName: 'Ranked',
    type: 'boolean',
    ...defaultOptions,
    valueGetter: (params: any) => params.getValue(params.id, 'hasScore'),
  },
  {
    field: ColumnType.status,
    headerName: 'Status',
    ...defaultOptions,
    valueGetter: (params: any) =>
      capitalize(
        params.value ||
          (params.getValue(params.id, 'hasScore') ? 'ranked' : 'not ranked')
      ),
  },
  {
    field: ColumnType.time,
    headerName: 'Time',
    ...defaultOptions,
    renderCell: (params: any) => (
      <RenderCell params={params} metricType={MetricType.time} />
    ),
  },
  {
    field: ColumnType.distance,
    headerName: 'Distance',
    ...defaultOptions,
    renderCell: (params: any) => (
      <RenderCell params={params} metricType={MetricType.distance} />
    ),
  },
  {
    field: ColumnType.pace,
    headerName: 'Pace',
    ...defaultOptions,
    renderCell: (params: any) => (
      <RenderCell params={params} metricType={MetricType.avgPace} />
    ),
  },
  {
    field: ColumnType.c2Id,
    headerName: 'C2 Id',
    ...defaultOptions,
    valueGetter: (params: any) => params.getValue(params.id, 'concept2Id'),
  },
  {
    field: ColumnType.device,
    headerName: 'Device',
    ...defaultOptions,
    valueGetter: (params: any) =>
      params.getValue(params.id, 'metadata').deviceOs,
  },
  {
    field: ColumnType.firmware,
    headerName: 'Firmware',
    ...defaultOptions,
    valueGetter: (params: any) =>
      pm5Firmware(params.getValue(params.id, 'metadata')),
  },
  {
    field: ColumnType.actions,
    headerName: 'Details',
    width: 100,
    flex: 0.4,
    headerAlign: 'right',
    align: 'right',
    sortable: false,
    renderCell: (params: any) => (
      <Tooltip title="Full details">
        <span>
          <IconButton
            icon="fullscreen"
            onPress={() =>
              params.getValue(params.id, 'setResultId')(params.id, 'replaceIn')
            }
          />
        </span>
      </Tooltip>
    ),
  },
  ...availableExtraMetrics.map((metric) => {
    return {
      field: `Metric-${metric}`,
      headerName: METRICS[metric].header({
        ergType: ErgType.row,
        ergBenchmarks: INITIAL_ERG_BENCHMARKS,
      }),
      ...defaultOptions,
      sortable: false,
      renderCell: (params: any) => (
        <RenderCell params={params} metricType={metric} />
      ),
    };
  }),
] as GridColumns;

const GET_WORKOUT = gql`
  query GetWorkout($id: ID!, $offset: Int, $limit: Int, $userDisplay: Int) {
    workout(id: $id) {
      id
      ergType: workoutType
      scoreType
      title
      track {
        id
        compStatus
      }
      intervals {
        type
      }
      allResults(offset: $offset, limit: $limit, showAll: true) {
        ...ResultFragment
        concept2Id
        metadata {
          deviceOs
          pm5Fw
        }
        user {
          id
          name(display: $userDisplay)
          settings {
            maxHeartRate
            restingHeartRate
            weight
            weightUnit
            age
            benchmarkValues {
              distance
              name
              scoreNumber
              group
              field
              ergType
            }
          }
        }
      }
    }
  }
  ${WORKOUT_RESULT_FRAGMENT}
`;

interface WorkoutResultData extends WorkoutResult {
  user: User;
}

interface WorkoutData extends Workout {
  allResults: WorkoutResultData[];
}

interface Query {
  workout: WorkoutData;
}

const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
      {
        outline: 'none',
      },
    borderWidth: 0,
  },
});

const ActionsContainer = styled.view({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  margin: 10,
});

const limitOptions = [10, 25, 50, 100, 1000];

interface Props {
  workoutId: string;
  resultsCount: number;
  showActions: boolean;
  userDisplay: UserDisplay;
}

export default function ResultsTable({
  workoutId,
  userDisplay,
  resultsCount,
  showActions,
}: Props) {
  const { currentUser } = useCurrentUser();
  const classes = useStyles();
  const [resultId, setResultId] = useQueryParam('id', StringParam);
  const { resultMetricTypes, setResultMetricTypes } = usePreferences();
  const [intervalsMode, setIntervalsMode] = useQueryParam(
    'intervals',
    StringParam
  );
  const [showMetricsSettings, setShowMetricsSettings] = useState(false);
  const [pageParam, setPage] = useQueryParam('page', NumberParam);
  const page = pageParam || 1;
  const [limitParam, setLimit] = useQueryParam('limit', NumberParam);
  const limit = limitOptions.includes(limitParam || 0)
    ? limitParam!
    : limitOptions[2];
  const { data, loading } = useQuery<Query>(GET_WORKOUT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: workoutId,
      offset: (page - 1) * limit,
      limit,
      userDisplay,
    },
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: ColumnType.date, sort: 'desc' },
  ]);

  const expand = intervalsMode === IntervalMode.expand;
  const workout = data && data.workout;
  const results = data && data.workout ? data.workout.allResults : [];
  const rows = useMemo(() => {
    if (!workout) return [];

    return results.map((result) => {
      return {
        ...result,
        scoreType: workout.scoreType,
        time: (result.elapsedTime || 0) / 100,
        distance: (result.elapsedDistance || 0) / 10,
        pace: calculatePace(
          (result.elapsedTime || 0) / 100,
          (result.elapsedDistance || 0) / 10,
          result.ergType
        ),
        setResultId,
        intervals: result.intervals.map((i) => ({
          ...i,
          time: i.elapsedTime,
          distance: i.elapsedDistance,
          pace: i.avgPace,
        })),
        expand,
      };
    });
  }, [results, workout, expand, setResultId]);

  const intervalsHeight =
    expand && results.length ? results[0].intervals.length * 18 : 0;

  const columns = useMemo(() => {
    const types = [ColumnType.name, ColumnType.date];

    if (workout) {
      if (workout.track.compStatus) {
        types.push(ColumnType.ranked);
        types.push(ColumnType.status);
      }
      if (workout.ergType === ErgType.fit) {
        types.push(ColumnType.score);
        types.push(ColumnType.rx);
      } else {
        if (currentUser.superadmin) {
          types.push(ColumnType.c2Id);
          types.push(ColumnType.device);
          types.push(ColumnType.firmware);
        }
        types.push(ColumnType.time);
        types.push(ColumnType.distance);
        types.push(ColumnType.pace);

        resultMetricTypes.forEach((metric) => {
          types.push(`Metric-${metric}` as any);
        });
      }
    }
    types.push(ColumnType.actions);
    const fields = RESULT_COLUMNS.reduce<{ [key: string]: number }>(
      (acc, c, i) => ({
        ...acc,
        [c.field]: i,
      }),
      {}
    );

    const cols = types
      .map((t) => RESULT_COLUMNS[fields[t]])
      .reduce<GridColDef[]>((acc, col) => {
        acc.push(col);
        if (col.field === ColumnType.pace) {
          if (workout && intervalsMode === IntervalMode.pace) {
            const RenderInterval = METRICS[MetricType.avgPace].renderCell;
            workout.intervals.forEach((_, index) => {
              acc.push({
                field: `Interval-${index}`,
                headerName: `# ${index + 1}`,
                sortable: false,
                ...defaultOptions,
                renderCell: (params: any) => {
                  const user = params.getValue(params.id, 'user');
                  const ergType = params.getValue(params.id, 'ergType');
                  const intervals = params.getValue(
                    params.id,
                    'intervals'
                  ) as Interval[];
                  const interval = intervals[index];

                  if (interval) {
                    return (
                      <RenderInterval
                        interval={interval}
                        theme={theme}
                        fontSize={fontSize}
                        currentUser={user}
                        ergType={interval.ergType || ergType}
                        ergBenchmarks={INITIAL_ERG_BENCHMARKS}
                        isPro={true}
                      />
                    );
                  } else {
                    return <Text>-</Text>;
                  }
                },
              } as GridColDef);
            });
          }
        }

        return acc;
      }, []);

    return cols;
  }, [workout, resultMetricTypes, currentUser, intervalsMode]);

  if (!workout) return <ActivityIndicator style={{ margin: 50 }} />;

  return (
    <>
      {showActions && (
        <ActionsContainer>
          <Menu
            visible={showMetricsSettings}
            onDismiss={() => setShowMetricsSettings(false)}
            anchor={
              <Button
                icon="table-edit"
                mode="outlined"
                onPress={() => {
                  setShowMetricsSettings(true);
                }}
              >
                Change Metrics
              </Button>
            }
          >
            <View style={{ width: '90vw', maxWidth: 300 }}>
              <List.Item title="Metric 1" right={() => <Text>Time</Text>} />
              <List.Item title="Metric 2" right={() => <Text>Distance</Text>} />
              <List.Item title="Metric 3" right={() => <Text>Pace</Text>} />
              <ScrollView>
                <MetricTypesField
                  availableMetrics={availableExtraMetrics}
                  metricTypes={resultMetricTypes}
                  setMetricTypes={setResultMetricTypes}
                  startIndexLabel={4}
                />
              </ScrollView>
            </View>
          </Menu>
          <FormControl
            variant="outlined"
            style={{
              marginRight: 10,
              marginLeft: 8,
              minWidth: 120,
              marginTop: 4,
            }}
            margin="dense"
          >
            <InputLabel htmlFor="intervals-select">Intervals</InputLabel>

            <Select
              value={intervalsMode}
              onChange={(event: any) => setIntervalsMode(event.target.value)}
              id="intervals-select"
              label="Intervals"
            >
              <MenuItem value="">
                <em>Clear</em>
              </MenuItem>
              {[IntervalMode.expand, IntervalMode.pace].map((mode) => {
                return (
                  <MenuItem key={mode} value={mode}>
                    {intervalModeDisplay(mode)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button
            style={{ marginLeft: 'auto' }}
            icon="file-download-outline"
            mode="text"
            onPress={() => {
              downloadCsv(workout.title, expand);
            }}
          >
            Download CSV
          </Button>
        </ActionsContainer>
      )}

      <div style={{ display: 'flex', height: '100%' }}>
        <div id="results-grid" style={{ flexGrow: 1 }}>
          <DataGrid
            className={classes.root}
            autoHeight
            rows={rows}
            rowHeight={52 + intervalsHeight}
            // @ts-ignore
            columns={columns}
            disableSelectionOnClick
            pagination
            page={page - 1}
            pageSize={limit}
            onPageSizeChange={(newPageSize: number) => setLimit(newPageSize)}
            rowsPerPageOptions={limitOptions}
            rowCount={resultsCount}
            paginationMode="server"
            onPageChange={(newPage: number) => setPage(newPage + 1)}
            loading={loading}
            sortModel={sortModel}
            onSortModelChange={(model: GridSortModel) => setSortModel(model)}
            sortingOrder={['asc', 'desc']}
            disableColumnMenu
            components={{
              ColumnResizeIcon: () => null,
            }}
          />
        </div>
        {resultId && (
          <ResultModal
            resultId={resultId}
            onDismiss={() => setResultId(undefined, 'replaceIn')}
          />
        )}
      </div>
    </>
  );
}

function intervalModeDisplay(mode: IntervalMode): React.ReactNode {
  switch (mode) {
    case IntervalMode.expand:
      return 'Expanded';
    case IntervalMode.pace:
      return 'Pace Columns';

    default:
      return mode;
  }
}

function downloadCsv(workoutName: string, expand: boolean | null | undefined) {
  const node = document.getElementById('results-grid');
  if (!node) return;
  const headers = Array.from(
    node.querySelectorAll('.MuiDataGrid-columnHeader')
  ).map((cell: any) => cell.innerText);
  headers.pop();
  const rows = Array.from(node.querySelectorAll('.MuiDataGrid-row')).reduce<
    string[][]
  >(
    (acc, row: any) => {
      const cells = Array.from(row.querySelectorAll('.MuiDataGrid-cell')).map(
        (cell: any) =>
          cell.innerText
            .replace(/\s+%/g, '%')
            .replace(/[^a-zA-Z0-9 -.:%/\s]/g, '')
            .trim()
      );
      cells.pop();

      const normalizeValue = (v: string) => (v === '-' ? '' : v);

      if (expand) {
        const allValues = cells.map((text) => text.split('\n'));
        const maxLength = Math.max(...allValues.map((el) => el.length));
        Array.from(Array(maxLength).keys()).forEach((i) => {
          acc.push(allValues.map((value) => normalizeValue(value[i])));
        });
      } else {
        acc.push(cells.map(normalizeValue));
      }
      return acc;
    },
    [headers]
  );

  const csv = Papa.unparse(rows);
  const file = new Blob([csv], { type: 'text/csv' });
  const link = document.createElement('a');
  link.download = `${workoutName}.csv`;
  link.href = window.URL.createObjectURL(file);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
