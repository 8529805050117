import useCurrentUser from 'hooks/useCurrentUser';
import usePreferences from 'hooks/usePreferences';
import React from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, Dialog, Portal } from 'react-native-paper';
import WorkoutEdit from 'shared/components/WorkoutEdit';
import { Workout } from 'shared/types';

interface Props {
  workout?: Workout;
  onDismiss(): void;
  refreshWorkouts?: () => void;
}

const WorkoutModal: React.FC<Props> = ({
  workout,
  onDismiss,
  refreshWorkouts,
}) => {
  const { currentUser, adminTracks } = useCurrentUser();
  const { setDefaultErgType, setIntervalConfig, intervalConfig } =
    usePreferences();
  return (
    <Portal>
      <Dialog visible={!!workout} onDismiss={onDismiss} style={styles.dialog}>
        {workout ? (
          <>
            <Dialog.Title>{workout.id ? 'Edit' : 'New'} Workout</Dialog.Title>
            <WorkoutEdit
              workout={workout}
              adminTracks={adminTracks}
              currentUser={currentUser}
              onCancel={onDismiss}
              onErgChange={setDefaultErgType}
              onIntervalConfigChange={setIntervalConfig}
              intervalConfig={intervalConfig}
              onSave={onDismiss}
              refreshWorkouts={refreshWorkouts}
            />
          </>
        ) : (
          <ActivityIndicator />
        )}
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: { alignSelf: 'center', height: '80vh', width: '60vw' },
});

export default WorkoutModal;
