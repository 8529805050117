import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { genderOptions } from 'shared/components/WorkoutLeaderboard/WorkoutLeaderboard';
import { ageGroupOptions } from 'shared/utils';

interface Params {
  intervalIndex?: number;
  gender?: string;
  year?: number;
  age?: string;
  isRx?: boolean;
}

interface PreferencesValue {
  gridColumns: number;
  gridRows: number;
  setGridColumns: (value: number) => void;
  setGridRows: (value: number) => void;
  hideFlag: boolean;
  setHideFlag: (value: boolean) => void;
  dark: boolean;
  setDark: (value: boolean) => void;
  paramsList: Params[];
  updateParam: (index: number, name: string, value?: string) => void;
}

const PreferencesContext = createContext<PreferencesValue>(
  // @ts-ignore
  {}
);

const USER_PREFERENCES = 'WORKOUT_LEADERBOARD_PREFERENCES';

interface Preferences {
  gridColumns: number;
  gridRows: number;
  hideFlag: boolean;
  dark: boolean;
  paramsList: Params[];
}

const initialParamsList = genderOptions.flatMap(({ value }) => {
  return ageGroupOptions.map(({ name }) => {
    return { gender: value, age: name };
  });
});

let initialPreferences: Preferences = {
  gridColumns: 3,
  gridRows: 4,
  paramsList: initialParamsList,
  hideFlag: false,
  dark: true,
};

const Store = {
  getPreferences: () => {
    const value = localStorage.getItem(USER_PREFERENCES);
    if (value) {
      let storedValues = JSON.parse(value) as Preferences;
      return { ...initialPreferences, ...storedValues };
    } else {
      return initialPreferences;
    }
  },
  setPreferences: (preferences: Preferences) =>
    localStorage.setItem(USER_PREFERENCES, JSON.stringify(preferences)),
};

export const PreferencesProvider = (props: any) => {
  const [preferences, setPreferences] = useState(initialPreferences);

  const { gridColumns, gridRows, hideFlag, dark, paramsList } = preferences;

  const savePreferences = useCallback((newPreferences: Preferences) => {
    initialPreferences = newPreferences;
    setPreferences(newPreferences);
    Store.setPreferences(newPreferences);
  }, []);

  useEffect(() => {
    const preferences = Store.getPreferences();
    initialPreferences = preferences;
    setPreferences(preferences);
  }, []);

  const setGridColumns = useCallback(
    (value: number) => {
      savePreferences({ ...initialPreferences, gridColumns: value });
    },
    [savePreferences]
  );

  const setGridRows = useCallback(
    (value: number) => {
      savePreferences({ ...initialPreferences, gridRows: value });
    },
    [savePreferences]
  );

  const setHideFlag = useCallback(
    (value: boolean) => {
      savePreferences({ ...initialPreferences, hideFlag: value });
    },
    [savePreferences]
  );

  const setDark = useCallback(
    (value: boolean) => {
      savePreferences({ ...initialPreferences, dark: value });
    },
    [savePreferences]
  );

  const updateParam = useCallback(
    (index: number, name: string, value?: any) => {
      console.log({ name, value });
      const newList = Array.from(Array(gridColumns * gridRows).keys()).map(
        (i) => {
          const params = initialPreferences.paramsList[i] || {};

          if (i === index) {
            return { ...params, [name]: value };
          } else {
            return params;
          }
        }
      );

      savePreferences({
        ...initialPreferences,
        paramsList: newList,
      });
    },
    [savePreferences, gridColumns, gridRows]
  );

  const value = {
    gridColumns,
    gridRows,
    setGridColumns,
    setGridRows,
    hideFlag,
    setHideFlag,
    dark,
    setDark,
    paramsList,
    updateParam,
  };

  return <PreferencesContext.Provider value={value} {...props} />;
};

const usePreferences = () => {
  return useContext(PreferencesContext) as PreferencesValue;
};

export default usePreferences;
