import useCurrentUser from 'hooks/useCurrentUser';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Dialog, Portal } from 'react-native-paper';
import { useHistory } from 'react-router';
import TrackNew from 'shared/components/TrackEdit/TrackNew';

interface Props {
  onDismiss(): void;
}

const NewTrackModal: React.FC<Props> = ({ onDismiss }) => {
  const { currentUser, reload } = useCurrentUser();
  const history = useHistory();

  return (
    <Portal>
      <Dialog visible onDismiss={onDismiss} style={styles.dialog}>
        <Dialog.Title>New Group</Dialog.Title>
        <TrackNew
          currentUser={currentUser}
          onBeforeSave={(callback) => {
            callback();
          }}
          onCancel={onDismiss}
          onSave={(trackId) => {
            onDismiss();
            reload();
            history.push(`/tracks/${trackId}/details`);
          }}
        />
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: { alignSelf: 'center', height: '80vh', width: '80vw', maxWidth: 768 },
});

export default NewTrackModal;
