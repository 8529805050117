import React from 'react';
import { StyleSheet } from 'react-native';
import { Dialog, Portal } from 'react-native-paper';
import ManualResultInput from 'shared/components/Manual/ManualResultInput';
import ManualResultFitInput from 'shared/components/Manual/ManualResultFitInput';
import { ErgType, Workout } from 'shared/types';

interface Props {
  workout: Workout;
  onDismiss(): void;
}

const ManualResultModal: React.FC<Props> = ({ workout, onDismiss }) => {
  const Input =
    workout.ergType === ErgType.fit ? ManualResultFitInput : ManualResultInput;
  return (
    <Portal>
      <Dialog visible={!!workout} onDismiss={onDismiss} style={styles.dialog}>
        <Dialog.Title>Manual Result</Dialog.Title>
        <Input
          workout={workout}
          onSave={onDismiss}
          timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        />
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: { alignSelf: 'center', height: '80vh', width: '60vw' },
});

export default ManualResultModal;
