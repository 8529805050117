import { useQuery } from '@apollo/react-hooks';
import { FormControl, MenuItem, Select, Tooltip } from '@material-ui/core';
import DataTableRow from 'components/DataTableRow';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import { startCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import { View } from 'react-native';
import { Card, Chip, DataTable, Headline, Searchbar } from 'react-native-paper';
import { useHistory, useLocation } from 'react-router-dom';
import useDebounceState from 'shared/hooks/useDebounceState';
import { User } from 'shared/types';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import styled from 'utils/styled';

const GET_USERS = gql`
  query GetUsers(
    $offset: Int
    $guest: Boolean
    $sort: String
    $search: String
    $statuses: [String]
  ) {
    subscriptionStatuses
    users(
      offset: $offset
      guest: $guest
      sort: $sort
      search: $search
      subscriptionStatuses: $statuses
    ) {
      id
      name
      email
      c2Username
      insertedAt
      workoutResultsCount
      appSubscription {
        statusMessage
        currentPeriodStart
        currentPeriodEnd
      }
    }
  }
`;

interface AppSubscription {
  statusMessage: string;
  currentPeriodStart?: string;
  currentPeriodEnd?: string;
}

interface UserData extends User {
  email: string;
  workoutResultsCount: number;
  insertedAt: string;
  c2Username: string;
  appSubscription: AppSubscription;
}

interface QueryData {
  users: UserData[];
  subscriptionStatuses: string[];
}

interface Props {}

const FilterContainer = styled.view({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  margin: 10,
});

const UsersList: React.FC<Props> = () => {
  const history = useHistory();
  const location = useLocation();

  const { currentUser } = useCurrentUser();
  const [pageParam, setPage] = useQueryParam('page', NumberParam);
  const page = pageParam || 1;

  const [sortParam, setSort] = useQueryParam('sort', StringParam);
  const sort = sortParam || 'insertedAt.desc';

  const [guestParam, setGuest] = useQueryParam('guest', StringParam);
  const guest = guestParam === 'true';

  const [statusParam, setStatusParam] = useQueryParam('status', StringParam);
  const status = statusParam || 'all';

  const [search, setSearch, unbouncedSearch] = useDebounceState(
    new URLSearchParams(location.search).get('search') || '',
    (newValue) => {
      const searchParams = new URLSearchParams(location.search);
      if (newValue) {
        searchParams.set('search', newValue);
      } else {
        searchParams.delete('search');
      }
      history.replace({ search: searchParams.toString() });
    }
  );

  const { data } = useQuery<QueryData>(GET_USERS, {
    variables: {
      offset: (page - 1) * 10,
      guest,
      sort,
      search: !!search ? search : null,
      statuses: status !== 'all' ? [status] : [],
    },
    fetchPolicy: 'cache-and-network',
  });

  const sortDirection = (name: string) => {
    const fields: {
      [x: string]: 'descending' | 'ascending' | undefined;
    } = {
      [`${name}.desc`]: 'descending',
      [`${name}.asc`]: 'ascending',
    };
    return fields[sort];
  };
  const toggleSort = (name: string) => {
    const newSort =
      {
        [`${name}.desc`]: `${name}.asc`,
        [`${name}.asc`]: `${name}.desc`,
      }[sort] || `${name}.asc`;
    setSort(newSort);
  };

  const users = data ? data.users : [];
  const subscriptionStatuses = data ? data.subscriptionStatuses : [];
  return (
    <View>
      <Headline>Users List</Headline>
      <Card>
        <View>
          <FilterContainer>
            <Searchbar
              inputStyle={{ width: 300, fontSize: 15 }}
              style={{ marginRight: 10 }}
              placeholder="Search by name, email, or username"
              onChangeText={setSearch}
              value={unbouncedSearch}
            />
            {subscriptionStatuses.length > 0 && (
              <FormControl variant="outlined" margin="dense">
                <Select
                  value={status}
                  onChange={(e) => {
                    if (page !== 1) {
                      setPage(1);
                    }
                    setStatusParam(String(e.target.value));
                  }}
                >
                  <MenuItem value="all">
                    <em>All Status</em>
                  </MenuItem>
                  {subscriptionStatuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {startCase(status)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {currentUser.superadmin && (
              <Chip
                icon="account"
                style={{ marginLeft: 10 }}
                selected={guest}
                onPress={() => setGuest(String(!guest))}
              >
                Guest
              </Chip>
            )}
          </FilterContainer>
          <DataTable>
            <DataTable.Header>
              <DataTable.Title>Name</DataTable.Title>
              {currentUser.superadmin && (
                <DataTable.Title>Email</DataTable.Title>
              )}
              <DataTable.Title>Username</DataTable.Title>
              <DataTable.Title>Status</DataTable.Title>
              <DataTable.Title style={{ flex: 0.4 }}>Results</DataTable.Title>
              <DataTable.Title
                style={{ flex: 0.4 }}
                sortDirection={sortDirection('insertedAt')}
                onPress={() => toggleSort('insertedAt')}
              >
                Joined
              </DataTable.Title>
            </DataTable.Header>

            {users.map((user) => {
              return (
                <DataTableRow
                  key={user.id}
                  onPress={() => history.push(`/users/${user.id}`)}
                >
                  <DataTable.Cell>{user.name}</DataTable.Cell>
                  {currentUser.superadmin && (
                    <DataTable.Cell>{user.email || '-'} </DataTable.Cell>
                  )}
                  <DataTable.Cell>{user.c2Username || '-'}</DataTable.Cell>
                  <DataTable.Cell>
                    <Tooltip
                      title={
                        user.appSubscription.currentPeriodStart
                          ? `Period: ${moment(user.appSubscription.currentPeriodStart).format('LL')} - ${moment(
                              user.appSubscription.currentPeriodEnd
                            ).format('LL')}`
                          : 'Not Started'
                      }
                    >
                      <span>{user.appSubscription.statusMessage}</span>
                    </Tooltip>
                  </DataTable.Cell>
                  <DataTable.Cell style={{ flex: 0.4 }}>
                    {user.workoutResultsCount}
                  </DataTable.Cell>
                  <DataTable.Cell style={{ flex: 0.4 }}>
                    {user ? moment(user.insertedAt).format('L') : ''}
                  </DataTable.Cell>
                </DataTableRow>
              );
            })}

            <DataTable.Pagination
              page={page}
              numberOfPages={0}
              onPageChange={setPage}
              label={`Page ${page}`}
            />
          </DataTable>
        </View>
      </Card>
    </View>
  );
};

export default UsersList;
