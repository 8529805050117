import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import React from 'react';
import { View } from 'react-native';
import { Avatar, Card, DataTable, Headline } from 'react-native-paper';
import moment from 'moment';
import DataTableRow from 'components/DataTableRow';
import { SCHEDULED_WORKOUT_FRAGMENT } from 'shared/graphql/fragments';
import Link from 'components/Link';
import { CurrentUser, Workout } from 'shared/types';
import { sumBy, uniq } from 'lodash';

const GET_LIVE = gql`
  query GetLiveWorkouts {
    liveWorkouts {
      id
      token
      title
      liveCount
      viewerCount
      track {
        id
        name
        imageUrl
      }
    }
    scheduledWorkouts {
      ...ScheduledWorkoutFragment
      workout {
        id
        token
        title
        track {
          id
          name
          imageUrl
        }
      }
    }
  }
  ${SCHEDULED_WORKOUT_FRAGMENT}
`;

interface Props {}

const WorkoutLink = ({
  currentUser,
  display,
  workout,
}: {
  currentUser: CurrentUser;
  display: String;
  workout: Workout;
}) => {
  return currentUser.superadmin ? (
    <Link to={`/tracks/${workout.track.id}/workouts`}>{display}</Link>
  ) : (
    <>{display}</>
  );
};

const LiveWorkouts: React.FC<Props> = () => {
  const { data } = useQuery(GET_LIVE, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 30000,
  });
  const { currentUser } = useCurrentUser();

  const workouts = data ? data.liveWorkouts : [];
  const scheduledWorkouts = data ? data.scheduledWorkouts : [];

  return (
    <View>
      <Headline>Live Workouts</Headline>
      <Card>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title style={{ flex: 0.2 }}> </DataTable.Title>
            <DataTable.Title>Name</DataTable.Title>
            <DataTable.Title
              style={{ flex: currentUser.superadmin ? 0.4 : 0.8 }}
            >
              Athletes ({sumBy(workouts, 'liveCount')})
            </DataTable.Title>
            {currentUser.superadmin && (
              <DataTable.Title style={{ flex: 0.4 }}>
                Viewers ({sumBy(workouts, 'viewerCount')})
              </DataTable.Title>
            )}
          </DataTable.Header>

          {workouts.map((workout: any) => {
            return (
              <DataTableRow
                key={workout.id}
                onPress={() => {
                  window.location.href = `/live/${workout.token}`;
                }}
              >
                <DataTable.Cell style={{ flex: 0.2 }}>
                  <Avatar.Image
                    size={30}
                    source={{ uri: workout.track.imageUrl }}
                  />
                </DataTable.Cell>
                <DataTable.Cell>
                  <WorkoutLink
                    currentUser={currentUser}
                    workout={workout}
                    display={`${workout.track.name} - ${workout.title}`}
                  />
                </DataTable.Cell>
                <DataTable.Cell
                  style={{ flex: currentUser.superadmin ? 0.4 : 0.8 }}
                >
                  {workout.liveCount}
                </DataTable.Cell>
                {currentUser.superadmin && (
                  <DataTable.Cell style={{ flex: 0.4 }}>
                    {workout.viewerCount}
                  </DataTable.Cell>
                )}
              </DataTableRow>
            );
          })}
        </DataTable>
      </Card>

      <Headline style={{ marginTop: 40 }}>Scheduled Workouts</Headline>
      <Card>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title style={{ flex: 0.2 }}> </DataTable.Title>
            <DataTable.Title>Name</DataTable.Title>
            <DataTable.Title style={{ flex: 0.4 }}>
              Athletes ({sumBy(scheduledWorkouts, 'numAthletes')})
            </DataTable.Title>
            <DataTable.Title style={{ flex: 0.4 }}>Date</DataTable.Title>
          </DataTable.Header>

          {scheduledWorkouts.map((scheduledWorkout: any) => {
            return (
              <DataTableRow
                key={scheduledWorkout.id}
                onPress={() => {
                  window.location.href = `/live/${scheduledWorkout.workout.token}`;
                }}
              >
                <DataTable.Cell style={{ flex: 0.2 }}>
                  <Avatar.Image
                    size={30}
                    source={{ uri: scheduledWorkout.workout.track.imageUrl }}
                  />
                </DataTable.Cell>
                <DataTable.Cell>
                  <WorkoutLink
                    currentUser={currentUser}
                    workout={scheduledWorkout.workout}
                    display={uniq([
                      scheduledWorkout.workout.track.name,
                      scheduledWorkout.workout.title,
                      scheduledWorkout.title,
                    ]).join(' - ')}
                  />
                </DataTable.Cell>
                <DataTable.Cell style={{ flex: 0.4 }}>
                  {scheduledWorkout.numAthletes}
                </DataTable.Cell>
                <DataTable.Cell style={{ flex: 0.4 }}>
                  {moment(scheduledWorkout.scheduledAt).format(
                    'ddd, MMM D, h:mm A'
                  )}
                </DataTable.Cell>
              </DataTableRow>
            );
          })}
        </DataTable>
      </Card>
    </View>
  );
};

export default LiveWorkouts;
