import usePreferences from 'hooks/usePreferences';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Dialog, Portal } from 'react-native-paper';
import GridView from 'shared/components/GridView';
import IntervalsProgress from 'shared/components/IntervalsProgress';
import { colors, darkTheme } from 'shared/styles';
import { CurrentUser, MonitorState } from 'shared/types';
import { userColor } from 'shared/utils';
import { IconButton } from './utils';

enum ViewMode {
  Table,
  Grid,
}

const LiveUser = ({
  user,
  onDismiss,
  monitorState,
}: {
  user: CurrentUser;
  onDismiss: () => void;
  monitorState: MonitorState;
}) => {
  const { tableMetrics, setTableMetrics, gridMetrics, setGridMetrics } =
    usePreferences();

  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Table);

  return (
    <Portal theme={darkTheme}>
      <Dialog visible onDismiss={onDismiss} style={styles.dialog}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Dialog.Title style={{ color: userColor(user.name) }}>
            {user.name}
          </Dialog.Title>
          <IconButton
            icon="view-sequential"
            style={viewMode !== ViewMode.Table && { opacity: 0.3 }}
            onPress={() => setViewMode(ViewMode.Table)}
          />
          <IconButton
            icon="view-grid"
            style={viewMode !== ViewMode.Grid && { opacity: 0.3 }}
            onPress={() => setViewMode(ViewMode.Grid)}
          />
        </View>
        <Dialog.ScrollArea>
          {viewMode === ViewMode.Table && (
            <ScrollView>
              <IntervalsProgress
                sharing={false}
                currentUser={user}
                workoutState={monitorState}
                initialMetrics={tableMetrics}
                onMetricsChange={setTableMetrics}
                fontSizeVw={1.8}
                darkMode={true}
                hideLastRow
              />
            </ScrollView>
          )}
          {viewMode === ViewMode.Grid && (
            <GridView
              currentUser={user}
              workoutState={monitorState}
              metrics={gridMetrics}
              onMetricsChange={setGridMetrics}
              topInset={0}
            />
          )}
        </Dialog.ScrollArea>
        <Dialog.Actions>
          <Button onPress={onDismiss}>Close</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: {
    height: '80vh',
    width: '90vw',
    alignSelf: 'center',
    backgroundColor: colors.darkBackground,
  },
});

export default LiveUser;
