import EditableText from 'pages/TrackShow/EditableText';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import {
  Button,
  Card,
  DataTable,
  IconButton,
  Provider as PaperProvider,
  Text,
} from 'react-native-paper';
import useOrientation from 'shared/hooks/useOrientation';
import { colors, theme } from 'shared/styles';
import { USBDriver } from 'utils/ant/ant';
import { FitnessEquipmentScanner } from 'utils/ant/fitness-equipment-sensors';
import styled from 'utils/styled';
import { AntDevices, ANT_METRICS } from './antMetrics';
import Settings from './AntPlusSettings';
import usePreferences, { PreferencesProvider } from './useAntPreferences';

interface Props {}

// const DATA = {
//   33135: {
//     DeviceID: 33135,
//     EquipmentType: 'Rower',
//     ElapsedTime: 182.75,
//     Distance: 809,
//     RealSpeed: 4.892,
//     State: 'IN_USE',
//     CycleLength: 0.91,
//     Resistance: 197,
//     CaloricBurnRate: 1428,
//     Calories: 148,
//     Strokes: 281,
//     Cadence: 45,
//     InstantaneousPower: 328,
//     HwVersion: 81,
//     ManId: 40,
//     ModelNum: 7,
//     SwVersion: 207,
//     SerialNumber: 431098671,
//     PairedDevices: [],
//   },
//   33136: {
//     DeviceID: 33136,
//     EquipmentType: 'Rower',
//     ElapsedTime: 182.75,
//     Distance: 809,
//     RealSpeed: 4.892,
//     State: 'IN_USE',
//     CycleLength: 0.91,
//     Resistance: 187,
//     CaloricBurnRate: 1428,
//     Calories: 148,
//     Strokes: 281,
//     Cadence: 45,
//     InstantaneousPower: 170,
//     HwVersion: 81,
//     ManId: 40,
//     ModelNum: 7,
//     SwVersion: 207,
//     SerialNumber: 431098671,
//     PairedDevices: [],
//   },
//   33137: {
//     DeviceID: 33135,
//     EquipmentType: 'Rower',
//     ElapsedTime: undefined,
//     Distance: undefined,
//     RealSpeed: undefined,
//     State: 'IN_USE',
//     CycleLength: undefined,
//     Resistance: undefined,
//     CaloricBurnRate: undefined,
//     Calories: undefined,
//     Strokes: undefined,
//     Cadence: undefined,
//     InstantaneousPower: undefined,
//     HwVersion: 81,
//     ManId: 40,
//     ModelNum: 7,
//     SwVersion: 207,
//     SerialNumber: 431098671,
//     PairedDevices: [],
//   },
// };

const ActionsContainer = styled.view({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  margin: 10,
});

const boldTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    regular: theme.fonts.medium,
  },
};

const AntPlusPage: React.FC<Props> = () => {
  const [devices, setDevices] = useState<AntDevices>({
    // @ts-ignore
    // DATA
  });

  const [connected, setConnected] = useState(false);

  const onConnect = useCallback(() => {
    const start = async () => {
      try {
        const device = await navigator.usb.requestDevice({ filters: [] });
        const stick = new USBDriver(device);

        await stick.open();

        const fitnessEquipmentScanner = new FitnessEquipmentScanner(stick);
        fitnessEquipmentScanner.on('fitnessData', (data) => {
          // @ts-ignore
          setDevices((d) => ({ ...d, [data.DeviceID]: data }));
        });

        stick.on('startup', function () {
          fitnessEquipmentScanner.scan();
        });

        stick.run();

        setConnected(true);
      } catch (e) {
        alert(e);
        console.log(e);
      }
    };

    start();
  }, []);

  const [showSettings, setShowSettings] = useState(false);
  const { vw } = useOrientation();
  const {
    liveFontScale,
    setLiveFontScale,
    liveMetricTypes,
    deviceNames,
    setDeviceName,
    targetWatts,
  } = usePreferences();
  const defaultfontSize = 16;
  const fontSize = Math.min(vw(5), defaultfontSize) * (liveFontScale / 100);

  return (
    <View>
      <Card>
        <ActionsContainer>
          <Button
            mode="outlined"
            icon="usb"
            onPress={onConnect}
            disabled={connected}
          >
            {connected ? 'Connected' : 'Connect'}
          </Button>

          <IconButton
            color={colors.textColor}
            icon="settings"
            onPress={() => setShowSettings(true)}
          />

          <IconButton
            icon="format-font-size-decrease"
            color={colors.textColor}
            onPress={() => setLiveFontScale(Math.max(10, liveFontScale - 10))}
          />
          <Text style={{ color: colors.textColor }}>{liveFontScale}%</Text>
          <IconButton
            icon="format-font-size-increase"
            color={colors.textColor}
            onPress={() => setLiveFontScale(liveFontScale + 10)}
          />
        </ActionsContainer>

        <DataTable>
          <DataTable.Row>
            <DataTable.Cell style={{ flex: 0.8 }}>
              <Text style={{ fontSize, color: colors.textColor }}>Id</Text>
            </DataTable.Cell>
            {liveMetricTypes.map((metric) => (
              <DataTable.Cell key={metric} numeric>
                <Text style={{ fontSize, color: colors.textColor }}>
                  {ANT_METRICS[metric].name}
                </Text>
              </DataTable.Cell>
            ))}
          </DataTable.Row>

          {Object.keys(devices).length > 0 && (
            <PaperProvider theme={boldTheme}>
              <DataTable.Row
                style={{
                  backgroundColor: colors.lightGray,
                }}
              >
                <DataTable.Cell style={{ flex: 0.8 }}>
                  <Text style={{ fontSize }}>All</Text>
                </DataTable.Cell>
                {liveMetricTypes.map((metric) => (
                  <DataTable.Cell key={metric} numeric>
                    {ANT_METRICS[metric].renderHeader({
                      devices,
                      fontSize,
                      targetWatts,
                    })}
                  </DataTable.Cell>
                ))}
              </DataTable.Row>
            </PaperProvider>
          )}

          {Object.keys(devices).map((deviceId: string) => {
            return (
              <DataTable.Row key={deviceId}>
                <DataTable.Cell style={{ flex: 0.8 }}>
                  <EditableText
                    multiline={false}
                    style={{ fontSize, height: '100%' }}
                    initialValue={deviceNames[deviceId] || deviceId}
                    onValueChange={(value) => {
                      setDeviceName(deviceId, value);
                    }}
                  />
                  <Text style={{ fontSize }}>{deviceId}</Text>
                </DataTable.Cell>
                {liveMetricTypes.map((metric) => (
                  <DataTable.Cell key={metric} numeric>
                    {ANT_METRICS[metric].renderCell({
                      device: devices[deviceId],
                      fontSize,
                      targetWatts,
                    })}
                  </DataTable.Cell>
                ))}
              </DataTable.Row>
            );
          })}
        </DataTable>
      </Card>
      {showSettings && <Settings onDismiss={() => setShowSettings(false)} />}
    </View>
  );
};

const AntPlusWithPreferences = () => (
  <PreferencesProvider>
    <AntPlusPage />
  </PreferencesProvider>
);

export default AntPlusWithPreferences;
