import { useMutation } from '@apollo/react-hooks';
import DataTableRow from 'components/DataTableRow';
import gql from 'graphql-tag';
import { padStart } from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';
import React, { useState } from 'react';
import { Button, DataTable, Title } from 'react-native-paper';
import { Interval, Label } from 'shared/types';
import styled from 'utils/styled';

const WORKOUT_CREATION = gql`
  mutation CreateWorkout($workout: WorkoutInput!) {
    workout: workoutUpsert(workout: $workout) {
      id
    }
  }
`;

const FieldContainer = styled.view({
  flexDirection: 'row',
  marginTop: 10,
  alignItems: 'center',
});
const Container = styled.view({ margin: 10 });

interface Props {
  trackId: string;
}

interface Workout {
  title: string;
  description: string;
  intervals: Interval[];
  labels: Label[];
  ergType: string;
}

const WorkoutRow = ({ workout }: { workout: Workout }) => {
  return (
    <DataTableRow key={workout.title}>
      <DataTable.Cell>{workout.title}</DataTable.Cell>
      <DataTable.Cell>{workout.ergType}</DataTable.Cell>
      <DataTable.Cell
        style={{
          marginVertical: 5,
        }}
      >
        <p style={{ whiteSpace: 'pre-line' }}>{workout.description}</p>
      </DataTable.Cell>
      <DataTable.Cell>
        <p style={{ whiteSpace: 'pre-line' }}>
          {workout.labels.map((l) => `${l.type}: ${l.name}`).join('\n')}
        </p>
      </DataTable.Cell>
    </DataTableRow>
  );
};

const getTitle = (row: any) => {
  const number = padStart(row['#'], 3, '0');
  return `Workout ${number} - ${row['Name']}`;
};

const getErgType = (row: any) => {
  if (row['Row'] === '✓') {
    return 'row';
  } else if (row['Ski'] === '✓') {
    return 'ski';
  } else if (row['Bike'] === '✓') {
    return 'bike';
  } else {
    return 'row';
  }
};

const getDescription = (row: any) => {
  const description = [
    `${row['Format']}: ${row['Mins/Cap']}min\n`,
    row['Line 1'],
    row['Line 2'],
    row['Line 3'],
    row['Line 4'],
    row['Line 5'],
    `\nCoaches Notes: ${row['Coaches Notes']}`,
    `\nScaling: ${row['Scaling']}`,
  ]
    .filter((l) => !!l)
    .join('\n');

  return description;
};

const getLabels = (row: any) => {
  const columns = Object.keys(row);

  const labels = [];

  const movementStart = columns.indexOf('Bike') + 1;
  const movementEnd = columns.indexOf('Yoke Carry');
  for (let index = movementStart; index <= movementEnd; index++) {
    const name = columns[index];
    const value = row[name];
    if (value === '✓') {
      labels.push({ type: 'movement', name });
    }
  }

  const equipmentStart = columns.indexOf('Bike Erg (or Air Bike)') + 1;
  const equipmentEnd = columns.indexOf('Yoke');

  for (let index = equipmentStart; index <= equipmentEnd; index++) {
    const name = columns[index];
    const value = row[name];
    if (value === '✓') {
      labels.push({ type: 'equipment', name });
    }
  }

  return labels;
};

const CsvWorkoutImporter: React.FC<Props> = ({ trackId }) => {
  const [workouts, setWorkouts] = useState<any[]>([]);
  const [date, setDate] = useState(moment().add(1, 'day').format('YYYY-MM-DD'));
  const [uploading, setUploading] = useState(false);
  const [createWorkout] = useMutation(WORKOUT_CREATION, {});

  const handleCsvUpload = (file: any) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const parsedWorkouts = results.data.map((row) => {
          const title = getTitle(row);
          const ergType = getErgType(row);
          const description = getDescription(row);
          const labels = getLabels(row);

          return {
            title,
            description,
            labels,
            ergType,
            intervals: [{ type: 'distance', value: 1000 }],
          };
        });
        setWorkouts(parsedWorkouts);
      },
    });
  };

  return (
    <Container>
      <Title>CSV ERGWOD Import</Title>
      <FieldContainer>
        <input
          type="file"
          accept=".csv"
          onChange={(e) => handleCsvUpload(e.target.files![0])}
        />

        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />

        {workouts.length > 0 && (
          <Button
            style={{ marginLeft: 10 }}
            icon="content-save"
            loading={uploading}
            disabled={uploading}
            mode="contained"
            onPress={() => {
              const uploadAll = async () => {
                setUploading(true);
                for (const workout of workouts) {
                  await createWorkout({
                    variables: {
                      workout: {
                        trackId,
                        status: 'draft',
                        workoutType: workout.ergType,
                        description: workout.description,
                        publishedAt: date,
                        title: workout.title,
                        intervals: workout.intervals,
                        labels: workout.labels,
                      },
                    },
                  });
                }
                setUploading(false);
              };

              uploadAll();
            }}
          >
            Import All
          </Button>
        )}
      </FieldContainer>
      {workouts.length > 0 && (
        <DataTable>
          <DataTable.Header>
            <DataTable.Title>Name</DataTable.Title>
            <DataTable.Title>Erg</DataTable.Title>
            <DataTable.Title>Description</DataTable.Title>
            <DataTable.Title>Labels</DataTable.Title>
          </DataTable.Header>
          {workouts.map((workout, index) => (
            <WorkoutRow key={index} workout={workout} />
          ))}
        </DataTable>
      )}
    </Container>
  );
};

export default CsvWorkoutImporter;
