import { AdminCurrentUser } from 'hooks/useCurrentUser';
import { useEffect } from 'react';

interface Props {
  currentUser: AdminCurrentUser;
}

const scripts = [
  `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`,
  `window.Beacon('init', '967433af-6791-4dab-85d3-262e252c52dc')`,
];
const BeforeBody = ({ currentUser }: Props) => {
  const { email, name, superadmin } = currentUser;
  useEffect(() => {
    if (!superadmin) {
      scripts.forEach((content) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = content;
        document.body.appendChild(script);
      });

      // @ts-ignore
      window.Beacon('prefill', {
        name,
        email,
      });
      // @ts-ignore
      window.Beacon('navigate', '/ask/message/');
    }
  }, [name, email, superadmin]);

  return null;
};

export default BeforeBody;
