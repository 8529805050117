import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { SERVER_URL } from 'utils/config';
import { getSessionToken, removeSessionToken } from './utils/session';

const httpLink = createHttpLink({
  uri: `${SERVER_URL}/api`,
});

const resetToken = onError(({ networkError }) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    removeSessionToken();
    redirectToAuth();
  }
});

const redirectToAuth = () => {
  window.location.href = '/login';
};

const withToken = setContext((_, { headers }) => {
  if (window.location.pathname.startsWith('/auth/')) {
    return { headers };
  }

  const token = getSessionToken();

  if (!token) {
    redirectToAuth();
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const authLink = withToken.concat(resetToken);
const httpLinkWithAuth = authLink.concat(httpLink);

const client = new ApolloClient({
  link: httpLinkWithAuth,
  cache: new InMemoryCache(),
});

export default client;
