import React, { useState } from 'react';
import { TextInput } from 'react-native';

interface Props {
  initialValue?: string;
  placeholder?: string;
  onValueChange(value: string): void;
  disabled?: boolean;
  multiline?: boolean;
  style?: any;
}
const EditableText = ({
  initialValue,
  placeholder,
  onValueChange,
  disabled,
  style,
  multiline = true,
}: Props) => {
  const [scrollHeight, setScrollHeight] = useState<number | undefined>();
  return (
    <TextInput
      editable={!disabled}
      key={initialValue}
      defaultValue={initialValue}
      placeholder={placeholder}
      style={[{ height: scrollHeight, padding: 2, width: '100%' }, style]}
      onContentSizeChange={(e) => {
        setScrollHeight(e.nativeEvent.contentSize.height);
      }}
      onBlur={(e: any) => onValueChange(e.target.value)}
      multiline={multiline}
    />
  );
};

export default EditableText;
