import { ApolloProvider } from '@apollo/react-hooks';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { CurrentUserProvider } from 'hooks/useCurrentUser';
import { PreferencesProvider } from 'hooks/usePreferences';
import React from 'react';
import { Portal, Provider as PaperProvider } from 'react-native-paper';
import Routes from 'routes';
import { ConfirmModalProvider } from 'shared/hooks/useConfirmModal';
import { OrientationProvider } from 'shared/hooks/useOrientation';
import { SnackBarProvider } from 'shared/hooks/useSnackBar';
import { theme } from 'shared/styles';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import './App.css';
import AppLayout from 'components/AppLayout';
import apolloClient from './apolloClient';
import { muiThemeAttributes } from 'utils/helpers';

const muiTheme = createMuiTheme(muiThemeAttributes);

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={muiTheme}>
        <PaperProvider theme={theme}>
          <>
            <style type="text/css">{`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'MaterialIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Solid';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'Nucleo';
          src: url(${require('shared/assets/Nucleo.ttf')}) format('truetype');
        }
        textarea, input[type='text'] {
          outline: none;
        }
      `}</style>
            <CurrentUserProvider>
              <PreferencesProvider>
                <OrientationProvider>
                  <ConfirmModalProvider>
                    <SnackBarProvider>
                      <Portal.Host>
                        <BrowserRouter>
                          <QueryParamProvider ReactRouterRoute={Route}>
                            <AppLayout>
                              <Routes />
                            </AppLayout>
                          </QueryParamProvider>
                        </BrowserRouter>
                      </Portal.Host>
                    </SnackBarProvider>
                  </ConfirmModalProvider>
                </OrientationProvider>
              </PreferencesProvider>
            </CurrentUserProvider>
          </>
        </PaperProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
