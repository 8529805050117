import { useMutation, useQuery } from '@apollo/react-hooks';
import { Tab, Tabs, Tooltip } from '@material-ui/core';
import DataTableRow from 'components/DataTableRow';
import ImageUploadModal from 'components/ImageUploadModal';
import Link, { MuiLink } from 'components/Link';
import ResultModal from 'components/ResultModal';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import { chain } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import {
  Avatar,
  Button,
  Card,
  DataTable,
  IconButton,
  Paragraph,
  Text,
} from 'react-native-paper';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import ErgIcon from 'shared/components/ErgIcon';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import { colors, sizes } from 'shared/styles';
import { BenchmarkValue, ErgType, TrackRole } from 'shared/types';
import {
  formatBenchmarkScoreNumber,
  formatDistance,
  formatFitScore,
  formatTimeWithHours,
  slugify,
} from 'shared/utils';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { pm5Firmware } from 'utils/helpers';
import styled from 'utils/styled';
import TracksTable from './TracksTable';

const VerticalTable = styled.view({});

const Row = styled.view({
  flexDirection: 'row',
  borderBottomColor: colors.dividerColor,
  borderBottomWidth: sizes.pixel,
  padding: 10,
});

const HeaderCell = styled.view({
  display: 'flex',
  margin: 10,
  padding: 1,
  flexDirection: 'row',
  flex: 0.8,
});

const ContentCell = styled.view({
  borderBottomColor: '#ccc',
  marginBottom: '10px solid #ccc',
  display: 'flex',
  margin: 10,
  padding: 1,
  flexDirection: 'row',
  flex: 0.2,
});

const ButtonContainer = styled.view({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  margin: 10,
});

const styles = StyleSheet.create({
  boldText: {
    fontWeight: 'bold',
  },
});

const GET_USER = gql`
  query GetUser($id: ID!, $offset: Int) {
    user(id: $id) {
      id
      name
      email
      stripeCustomerId
      country
      countryUrl
      avatarUrl
      age
      dob
      maxHeartRate
      restingHeartRate
      weightClass
      settings {
        gender
        benchmarkValues {
          name
          scoreNumber
          group
          ergType
        }
      }
      concept2Info {
        id
        username
      }
      deviceName
      insertedAt
      bikeCount: resultsCount(ergType: "bike")
      skiCount: resultsCount(ergType: "ski")
      rowCount: resultsCount(ergType: "row")
      fitCount: resultsCount(ergType: "fit")
      multiCount: resultsCount(ergType: "multi")
      resultsCount
      appSubscription {
        store
        statusMessage
        currentPeriodStart
        currentPeriodEnd
        currentActiveUsersCount
        appPrice {
          name
        }
      }
      workoutResults(offset: $offset) {
        elapsedTime
        elapsedDistance
        ergType
        rowerType
        id
        scoreValue
        isRx
        notes
        concept2Id
        insertedAt
        metadata {
          buildDate
          clientVersion
          device
          deviceOs
          deviceOsVersion
          pm5Fw
          pm5Hw
          pm5Name
          manufacturer
        }
        workout {
          id
          title
          scoreType
          track {
            id
            name
            role
            source {
              id
              imageUrl
            }
          }
        }
      }
    }
  }
`;

const USER_UPDATE_MUTATION = gql`
  mutation UpdateUser($user: UserInput!) {
    userUpdate(user: $user) {
      id
      avatarUrl
    }
  }
`;

interface Props {}
interface Params {
  userId: string;
  tab: string;
}

const UserShow: React.FC<Props> = () => {
  const { currentUser } = useCurrentUser();
  const { userId, tab } = useParams<Params>();
  const history = useHistory();

  const [resultId, setResultId] = useQueryParam('id', StringParam);
  const [pageParam, setPage] = useQueryParam('page', NumberParam);
  const page = pageParam || 1;
  const [sortParam, setSort] = useQueryParam('sort', StringParam);
  const sort = sortParam || 'insertedAt.desc';
  const [showAvatarUpload, setShowAvatarUpload] = useState(false);

  const { data } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: userId,
      offset: (page - 1) * 10,
      sort,
    },
  });
  const confirmWith = useConfirmModal();
  const [updateUserMutation] = useMutation(USER_UPDATE_MUTATION);
  const updateUser = (data: any) =>
    updateUserMutation(data).catch(({ graphQLErrors }) => {
      const message =
        graphQLErrors && graphQLErrors.map((e: any) => e.message).join(', ');
      confirmWith({
        title: 'Error',
        message: message || 'Error saving information. Please, try again!',
        hideCancel: true,
      });
    });
  const sortDirection = (name: string) => {
    const fields: {
      [x: string]: 'descending' | 'ascending' | undefined;
    } = {
      [`${name}.desc`]: 'descending',
      [`${name}.asc`]: 'ascending',
    };
    return fields[sort];
  };
  const toggleSort = (name: string) => {
    const newSort =
      {
        [`${name}.desc`]: `${name}.asc`,
        [`${name}.asc`]: `${name}.desc`,
      }[sort] || `${name}.asc`;
    setSort(newSort);
  };
  const user = data && data.user;

  return (
    <View>
      {user && (
        <Card>
          <Card.Title
            title={user.name}
            left={(props) => (
              <Avatar.Image size={35} source={user.countryUrl} />
            )}
          ></Card.Title>

          <Card.Content>
            {currentUser.superadmin && (
              <Paragraph>
                <IconButton icon="email" size={15}></IconButton>
                {user.email}
              </Paragraph>
            )}
            {currentUser.superadmin && (
              <Paragraph>
                <IconButton icon="account" size={15}></IconButton>
                {user.concept2Info ? user.concept2Info.username : ''}
              </Paragraph>
            )}
            {currentUser.superadmin && (
              <Paragraph>
                <IconButton icon="wallet-membership" size={15}></IconButton>
                {[
                  user.appSubscription.statusMessage,
                  user.appSubscription.appPrice
                    ? `${user.appSubscription.appPrice.name}: current ${user.appSubscription.currentActiveUsersCount}`
                    : null,
                  user.appSubscription.store,
                  user.appSubscription.currentPeriodStart
                    ? `${moment(user.appSubscription.currentPeriodStart).format('LL')} - ${moment(
                        user.appSubscription.currentPeriodEnd
                      ).format('LL')}`
                    : null,
                ]
                  .filter((e) => e)
                  .join(' | ')}
                {!!user.stripeCustomerId && (
                  <>
                    {' | '}
                    <MuiLink
                      href={`https://dashboard.stripe.com/customers/${user.stripeCustomerId}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Stripe
                    </MuiLink>
                  </>
                )}
                <>
                  {' | '}
                  <MuiLink
                    href={`https://app.revenuecat.com/customers/54eae71f/${user.id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    RevenueCat
                  </MuiLink>
                </>
              </Paragraph>
            )}

            <Paragraph style={{ alignItems: 'center' }}>
              {!!user.bikeCount && (
                <>
                  <ErgIcon
                    ergType={ErgType.bike}
                    size={15}
                    color="black"
                    style={{ marginLeft: 13, marginRight: 8 }}
                  ></ErgIcon>
                  {user.bikeCount}
                </>
              )}
              {!!user.rowCount && (
                <>
                  <ErgIcon
                    ergType={ErgType.row}
                    size={15}
                    color="black"
                    style={{ marginLeft: 8, marginRight: 8 }}
                  ></ErgIcon>
                  {user.rowCount}
                </>
              )}
              {!!user.skiCount && (
                <>
                  <ErgIcon
                    ergType={ErgType.ski}
                    size={15}
                    color="black"
                    style={{ marginLeft: 8, marginRight: 8 }}
                  ></ErgIcon>
                  {user.skiCount}
                </>
              )}
              {!!user.multiCount && (
                <>
                  <ErgIcon
                    ergType={ErgType.multi}
                    size={15}
                    color="black"
                    style={{ marginLeft: 8, marginRight: 8 }}
                  ></ErgIcon>
                  {user.multiCount}
                </>
              )}
              {!!user.fitCount && (
                <>
                  <ErgIcon
                    ergType={ErgType.fit}
                    size={15}
                    color="black"
                    style={{ marginLeft: 8, marginRight: 8 }}
                  ></ErgIcon>
                  {user.fitCount}
                </>
              )}
            </Paragraph>

            {currentUser.id === userId && (
              <ButtonContainer>
                <Button
                  style={{ marginRight: 10 }}
                  icon="chart-bar"
                  mode="outlined"
                  color={colors.primaryColor}
                  onPress={() => {
                    history.push(`/stats`);
                  }}
                >
                  Stats
                </Button>
              </ButtonContainer>
            )}
          </Card.Content>
        </Card>
      )}

      <Card style={{ marginTop: 20 }}>
        {user && (
          <Tabs
            value={tab || ''}
            onChange={(_, newValue) =>
              history.push(`/users/${userId}/${newValue}`)
            }
          >
            <Tab
              value="results"
              label={'Results (' + user.resultsCount + ')'}
            />
            <Tab value="details" label="Details" />
            {currentUser.superadmin && <Tab value="tracks" label="Tracks" />}
          </Tabs>
        )}
        <Switch>
          <Route path={`/users/${userId}`} exact>
            <Redirect to={{ pathname: `/users/${userId}/results` }} />
          </Route>
          <Route path={`/users/${userId}/details`}>
            {user && (
              <VerticalTable>
                {currentUser.superadmin && !!user.dob && (
                  <Row>
                    <ContentCell>
                      <Text style={styles.boldText}>DOB</Text>
                    </ContentCell>
                    <HeaderCell>
                      <Text>
                        {new Date(`${user.dob}T00:00:00`).toLocaleDateString()}
                      </Text>
                    </HeaderCell>
                  </Row>
                )}
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Age</Text>
                  </ContentCell>
                  <HeaderCell>
                    <Text>{user.age}</Text>
                  </HeaderCell>
                </Row>
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Joined At</Text>
                  </ContentCell>
                  <HeaderCell>
                    <Text>
                      {new Date(user.insertedAt).toLocaleDateString()}
                    </Text>
                  </HeaderCell>
                </Row>
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Country</Text>
                  </ContentCell>
                  <HeaderCell>
                    <Text>{user.country}</Text>
                  </HeaderCell>
                </Row>
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Max HR/Resting HR</Text>
                  </ContentCell>
                  <HeaderCell>
                    <Text>
                      {user.maxHeartRate || '-'}
                      {' / '}
                      {user.restingHeartRate || '-'}
                    </Text>
                  </HeaderCell>
                </Row>
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Weight Class</Text>
                  </ContentCell>
                  <HeaderCell>
                    <Text>{user.weightClass}</Text>
                  </HeaderCell>
                </Row>
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Benchmarks</Text>
                  </ContentCell>
                  <HeaderCell>
                    <View>
                      {chain(user.settings.benchmarkValues as BenchmarkValue[])
                        .filter((v) => !!v.scoreNumber)
                        .groupBy((v) => v.ergType)
                        .map((benchmarks, ergType: ErgType) => {
                          return (
                            <View
                              key={ergType}
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingVertical: 2,
                              }}
                            >
                              <ErgIcon
                                size={18}
                                ergType={ergType}
                                color={'black'}
                              />
                              <Text>
                                {'    '}
                                {benchmarks
                                  .map(
                                    (b) =>
                                      `${b.name}: ${formatBenchmarkScoreNumber(b.scoreNumber || 0, b)}`
                                  )
                                  .join('   /   ')}
                              </Text>
                            </View>
                          );
                        })
                        .value()}
                    </View>
                  </HeaderCell>
                </Row>
                {currentUser.superadmin && (
                  <Row>
                    <ContentCell>
                      <Text style={styles.boldText}>Avatar</Text>
                    </ContentCell>
                    <HeaderCell>
                      <View style={{ alignItems: 'center', marginRight: 10 }}>
                        <Image
                          source={{ uri: user.avatarUrl }}
                          style={{ width: 100, height: 100 }}
                        />
                        {showAvatarUpload && (
                          <ImageUploadModal
                            showCircle
                            title="Avatar Upload"
                            path={`avatar/${slugify(user.name)}`}
                            width={200}
                            height={200}
                            onDismiss={() => setShowAvatarUpload(false)}
                            onUpload={(path) => {
                              updateUser({
                                variables: {
                                  user: { id: user.id, avatarUrl: path },
                                },
                              });
                              setShowAvatarUpload(false);
                            }}
                          />
                        )}
                        <Button onPress={() => setShowAvatarUpload(true)}>
                          Upload avatar
                        </Button>
                      </View>
                    </HeaderCell>
                  </Row>
                )}
              </VerticalTable>
            )}
          </Route>
          <Route path={`/users/${userId}/results`}>
            {user && (
              <DataTable>
                <DataTable.Header>
                  <DataTable.Title style={{ flex: 0.5 }}> </DataTable.Title>
                  <DataTable.Title style={{ flex: 2 }}>Workout</DataTable.Title>
                  <DataTable.Title style={{ flex: 1 }}>Time</DataTable.Title>
                  <DataTable.Title style={{ flex: 1 }}>
                    Distance
                  </DataTable.Title>
                  {currentUser.superadmin && (
                    <>
                      <DataTable.Title style={{ flex: 1 }}>BLE</DataTable.Title>
                      <DataTable.Title style={{ flex: 1 }}>
                        C2 Id
                      </DataTable.Title>
                      <DataTable.Title style={{ flex: 1 }}>OS</DataTable.Title>
                      <DataTable.Title style={{ flex: 1 }}>FW</DataTable.Title>
                      <DataTable.Title style={{ flex: 1 }}>HW</DataTable.Title>
                      <DataTable.Title style={{ flex: 1 }}>
                        Client Version
                      </DataTable.Title>
                    </>
                  )}
                  <DataTable.Title
                    sortDirection={sortDirection('insertedAt')}
                    onPress={() => toggleSort('insertedAt')}
                    style={{ flex: 1 }}
                  >
                    Started At
                  </DataTable.Title>
                </DataTable.Header>
                {user.workoutResults.map((result: any) => {
                  return (
                    <DataTableRow
                      key={result.id}
                      onPress={() => setResultId(result.id, 'replaceIn')}
                    >
                      <DataTable.Cell style={{ flex: 0.5 }}>
                        <ErgIcon
                          ergType={result.ergType}
                          rowerType={result.rowerType}
                          color="black"
                          size={17}
                        ></ErgIcon>
                      </DataTable.Cell>
                      <DataTable.Cell style={{ flex: 2 }}>
                        {result.workout.track.role === TrackRole.Admin ||
                        currentUser.superadmin ? (
                          <Link
                            to={`/workouts/${result.workout.id}/results`}
                            tooltip={result.workout.track.name}
                          >
                            {result.workout.title}
                          </Link>
                        ) : (
                          result.workout.title
                        )}
                      </DataTable.Cell>
                      <DataTable.Cell style={{ flex: 1 }}>
                        {result.workout.scoreType
                          ? `Score ${result.isRx ? 'Rx' : 'Scaled'}: ${formatFitScore(
                              result.scoreValue,
                              result.workout.scoreType
                            )}`
                          : formatTimeWithHours(result.elapsedTime / 100)}
                        {!!result.notes && (
                          <Tooltip title={result.notes}>
                            <span>
                              <IconButton
                                size={16}
                                color={colors.textColor}
                                icon="note-outline"
                              ></IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </DataTable.Cell>
                      <DataTable.Cell style={{ flex: 1 }}>
                        {result.workout.scoreType
                          ? '-'
                          : formatDistance(result.elapsedDistance / 10)}
                      </DataTable.Cell>
                      {currentUser.superadmin && (
                        <>
                          <DataTable.Cell style={{ flex: 1 }}>
                            <Tooltip
                              title={[
                                result.metadata['pm5Name'],
                                result.metadata['manufacturer'],
                              ]
                                .filter((f) => f)
                                .join(' - ')}
                            >
                              <span>{result.metadata['pm5Name']}</span>
                            </Tooltip>
                          </DataTable.Cell>
                          <DataTable.Cell style={{ flex: 1 }}>
                            {result.concept2Id || '-'}
                          </DataTable.Cell>
                          <DataTable.Cell style={{ flex: 1 }}>
                            {result.metadata['deviceOs']}{' '}
                            {result.metadata['deviceOsVersion']}
                          </DataTable.Cell>
                          <DataTable.Cell style={{ flex: 1 }}>
                            {pm5Firmware(result.metadata)}
                          </DataTable.Cell>
                          <DataTable.Cell style={{ flex: 1 }}>
                            {result.metadata['pm5Hw']}
                          </DataTable.Cell>
                          <DataTable.Cell style={{ flex: 1 }}>
                            {result.metadata['clientVersion']}
                          </DataTable.Cell>
                        </>
                      )}
                      <DataTable.Cell style={{ flex: 1 }}>
                        {new Date(result.insertedAt).toLocaleDateString()}
                      </DataTable.Cell>
                    </DataTableRow>
                  );
                })}

                <DataTable.Pagination
                  page={page}
                  numberOfPages={0}
                  onPageChange={setPage}
                  label={`Page ${page}`}
                />
              </DataTable>
            )}
          </Route>
          {currentUser.superadmin && (
            <Route path={`/users/${userId}/tracks`}>
              <TracksTable userId={userId} />
            </Route>
          )}
        </Switch>
      </Card>
      {resultId && (
        <ResultModal
          resultId={resultId}
          onDismiss={() => setResultId(undefined, 'replaceIn')}
        />
      )}
    </View>
  );
};

export default UserShow;
