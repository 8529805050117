import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Dialog, Portal } from 'react-native-paper';
import { ManualInputFields } from 'shared/components/Manual/ManualInputFields';
import { WORKOUT_RESULT_FRAGMENT } from 'shared/graphql/fragments';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import { colors, sizes } from 'shared/styles';
import { Track, WorkoutResult } from 'shared/types';
import { calculatePace } from 'shared/utils';
import { useImmer } from 'use-immer';

interface Props {
  track: Track;
}

interface QueryWorkout {
  title: string;
}
interface QueryUser {
  name: string;
}

interface QueryResult extends WorkoutResult {
  images: string[];
  user: QueryUser;
  workout: QueryWorkout;
}

interface QueryTrack {
  pendingResults: QueryResult[];
}
interface QueryData {
  track: QueryTrack;
}

const PENDING_FRAGMENT = gql`
  fragment PendingResultsFragment on Track {
    id
    pendingResults {
      ...ResultFragment
      images
      user {
        name
      }
      workout {
        title
      }
    }
  }
  ${WORKOUT_RESULT_FRAGMENT}
`;

const GET_PENDING_RESULTS = gql`
  query GetPendingResults($id: ID!) {
    track(id: $id) {
      ...PendingResultsFragment
    }
  }
  ${PENDING_FRAGMENT}
`;

const APPROVE_WORKOUT_RESULT = gql`
  mutation ApproveWorkoutResult($input: WorkoutResultManualApproveInput!) {
    approved: workoutResultManualApprove(input: $input) {
      id
      workout {
        track {
          ...PendingResultsFragment
        }
      }
    }
  }
  ${PENDING_FRAGMENT}
`;

const REJECT_WORKOUT_RESULT = gql`
  mutation RejectWorkoutResult($id: ID!) {
    rejected: workoutResultManualReject(id: $id) {
      id
      workout {
        track {
          ...PendingResultsFragment
        }
      }
    }
  }
  ${PENDING_FRAGMENT}
`;

const MINUTES = 5;

const PendingResults = ({ track }: Props) => {
  const confirmWith = useConfirmModal();
  const [selectedResult, updateResult] = useImmer<QueryResult | null>(null);

  const [approveWorkoutResult, { loading: approving }] = useMutation(
    APPROVE_WORKOUT_RESULT
  );
  const [rejectWorkoutResult, { loading: rejecting }] = useMutation(
    REJECT_WORKOUT_RESULT
  );
  const { data } = useQuery<QueryData>(GET_PENDING_RESULTS, {
    variables: { id: track.id },
    fetchPolicy: 'cache-and-network',
    pollInterval: MINUTES * 60 * 1000,
    skip: !track.compStatus,
  });
  const pendingResults = data ? data.track.pendingResults : [];

  if (pendingResults.length === 0) return null;

  const onDismiss = () => {
    updateResult(() => null);
  };

  const onApprove = () => {
    if (!selectedResult) return;

    confirmWith({
      message: 'Are you sure you want to approve this result?',
      confirmLabel: 'Approve Result',
      onConfirm: () => {
        approveWorkoutResult({
          variables: {
            input: {
              id: selectedResult.id,
              elapsedDistance: selectedResult.elapsedDistance || 0,
              elapsedTime: selectedResult.elapsedTime || 0,
              intervals: selectedResult.intervals.map((interval) => {
                return {
                  elapsedTime: interval.elapsedTime,
                  elapsedDistance: interval.elapsedDistance,
                  avgSpm: interval.avgSpm,
                  avgPace: calculatePace(
                    interval.elapsedTime || 0,
                    interval.elapsedDistance || 0,
                    selectedResult.ergType
                  ),
                };
              }),
            },
          },
        }).then(({ data }) => {
          if (data) {
            const dataTrack = data.approved.workout.track as QueryTrack;
            const nextResult = dataTrack.pendingResults[0];
            updateResult(() => nextResult || null);
          }
        });
      },
    });
  };

  const onReject = () => {
    if (!selectedResult) return;

    confirmWith({
      message: 'Are you sure you want to reject this result?',
      confirmLabel: 'Reject Result',
      confirmColor: colors.destructiveColor,
      onConfirm: () => {
        rejectWorkoutResult({
          variables: { id: selectedResult.id },
        }).then(({ data }) => {
          if (data) {
            const dataTrack = data.rejected.workout.track as QueryTrack;
            const nextResult = dataTrack.pendingResults[0];
            updateResult(() => nextResult || null);
          }
        });
      },
    });
  };

  return (
    <>
      <Button
        icon="timer-sand"
        mode="contained"
        color={colors.leaderboardColor}
        onPress={() => updateResult(() => pendingResults[0])}
      >
        {pendingResults.length} Pending Results
      </Button>
      {selectedResult && (
        <Portal>
          <Dialog visible onDismiss={onDismiss} style={styles.dialog}>
            <Dialog.Title>
              {selectedResult.workout.title} - {selectedResult.user.name}
            </Dialog.Title>

            <View style={styles.splitContainer}>
              <ScrollView style={styles.formContainer}>
                {selectedResult && (
                  <ManualInputFields
                    key={selectedResult.id}
                    result={selectedResult}
                    // @ts-ignore
                    updateResult={updateResult}
                  />
                )}
              </ScrollView>
              <ScrollView style={styles.imagesContainer}>
                {selectedResult.images.map((uri) => (
                  <Image key={uri} style={styles.image} source={{ uri }} />
                ))}
              </ScrollView>
            </View>
            <Dialog.Actions style={styles.actionsContainer}>
              <Button
                mode="contained"
                loading={rejecting}
                disabled={approving || rejecting}
                icon="close-circle-outline"
                onPress={onReject}
                color={colors.destructiveColor}
              >
                REJECT
              </Button>
              <Button
                loading={approving}
                disabled={approving || rejecting}
                mode="contained"
                icon="check-circle-outline"
                onPress={onApprove}
              >
                APPROVE
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      )}
    </>
  );
};

const dialogWidth = 60;
const formWidth = dialogWidth * 0.55;
const imagesWidth = dialogWidth - formWidth;

const styles = StyleSheet.create({
  dialog: {
    alignSelf: 'center',
    height: '80vh',
    width: `${dialogWidth}vw`,
  },
  splitContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  formContainer: {
    width: `${formWidth}vw`,
  },
  imagesContainer: {
    width: `${imagesWidth}vw`,
  },
  image: {
    width: `${imagesWidth}vw`,
    height: `${imagesWidth}vw`,
    marginBottom: 4,
  },
  actionsContainer: {
    paddingVertical: sizes.spacing,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
});
export default PendingResults;
