import React, { ReactNode } from 'react';
import { ViewStyle } from 'react-native';
import { DataTable } from 'react-native-paper';

interface Props {
  children: ReactNode;
  onPress?(): void;
  style?: ViewStyle | false;
}

const DataTableRow = ({ children, onPress, style }: Props) => {
  return (
    <DataTable.Row
      style={style}
      data-type="row"
      //@ts-ignore
      onPress={
        !onPress
          ? undefined
          : (e: any) => {
              if (e.target.nodeName !== 'A') {
                onPress && onPress();
              }
            }
      }
    >
      {children}
    </DataTable.Row>
  );
};

export default DataTableRow;
