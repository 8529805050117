import { useMutation, useQuery } from '@apollo/react-hooks';
import DataTableRow from 'components/DataTableRow';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import { keyBy, sortBy } from 'lodash';
import React from 'react';
import { Avatar, DataTable, IconButton } from 'react-native-paper';
import { useHistory } from 'react-router-dom';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import { MembershipStatus, Track, TrackMode, TrackRole } from 'shared/types';

const GET_USER = gql`
  query GetUserTracks($id: ID!) {
    user(id: $id) {
      id
      tracks {
        id
        role(userId: $id)
      }
      trackMemberships {
        trackId
        active
        statusMessage
      }
    }
  }
`;
const TRACK_MEMBERSHIP_UPDATE_MUTATION = gql`
  mutation TrackMembershipUpdate(
    $trackId: ID!
    $userId: ID!
    $status: String!
  ) {
    trackMembershipUpdate(trackId: $trackId, userId: $userId, status: $status) {
      id
    }
  }
`;

const TRACK_ROLE_UPDATE_MUTATION = gql`
  mutation TrackRoleUpdate($trackId: ID!, $userId: ID!, $role: String!) {
    trackRoleUpdate(trackId: $trackId, userId: $userId, role: $role) {
      id
      tracks {
        id
        role(userId: $userId)
      }
    }
  }
`;

const TracksTable = ({ userId }: { userId: string }) => {
  const confirmWith = useConfirmModal();
  const history = useHistory();
  const { adminTracks } = useCurrentUser();

  const { data, refetch } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: userId,
    },
  });
  const [trackMembershipUpdate, { loading: savingAccess }] = useMutation(
    TRACK_MEMBERSHIP_UPDATE_MUTATION
  );
  const [trackRoleUpdate, { loading: savingRole }] = useMutation(
    TRACK_ROLE_UPDATE_MUTATION
  );

  const confirmAccess = (isMember: boolean, track: Track) => {
    confirmWith({
      message: `Are you sure you want to ${isMember ? 'remove access to' : 'grant access to'} '${track.name}'?`,
      confirmLabel: isMember ? 'Remove Access' : 'Grant Access',
      onConfirm: () => {
        trackMembershipUpdate({
          variables: {
            userId,
            trackId: track.id,
            status: isMember
              ? MembershipStatus.AccessRemoved
              : MembershipStatus.AccessGranted,
          },
        }).then(() => refetch());
      },
    });
  };

  const confirmRole = (oldRole: TrackRole, track: Track) => {
    confirmWith({
      message: `Are you sure you want to ${
        oldRole === TrackRole.Admin
          ? 'remove admin access on'
          : 'grant admin access on'
      } '${track.name}'?`,
      confirmLabel:
        oldRole === TrackRole.Admin
          ? 'Remove Admin Access'
          : 'Grant Admin Access',
      onConfirm: () => {
        trackRoleUpdate({
          variables: {
            userId,
            trackId: track.id,
            role:
              oldRole === TrackRole.Admin ? TrackRole.Member : TrackRole.Admin,
          },
        });
      },
    });
  };

  const user = data && data.user;

  const userTracks = keyBy(user ? user.tracks : [], (t) => t.id);
  const trackMemberships = keyBy(
    user ? user.trackMemberships : [],
    (t) => t.trackId
  );

  return (
    <DataTable>
      <DataTable.Header>
        <DataTable.Title style={{ flex: 0.2 }}>Logo</DataTable.Title>
        <DataTable.Title style={{ flex: 0.5 }}>Track</DataTable.Title>
        <DataTable.Title style={{ flex: 0.5 }}>Source</DataTable.Title>
        <DataTable.Title style={{ flex: 0.5 }}>Role</DataTable.Title>
        <DataTable.Title style={{ flex: 0.2 }} numeric>
          Actions
        </DataTable.Title>
      </DataTable.Header>
      {sortBy(
        adminTracks.filter((t) => t.trackMode !== TrackMode.SingleUser),
        (track) => !userTracks[track.id]
      ).map((track) => {
        const userTrack = userTracks[track.id];
        const isMember = trackMemberships[track.id]
          ? trackMemberships[track.id].active
          : !!userTrack;

        return (
          <DataTableRow
            key={track.id}
            onPress={() => history.push(`/tracks/${track.id}`)}
          >
            <DataTable.Cell style={{ flex: 0.2 }}>
              <Avatar.Image size={30} source={{ uri: track.imageUrl }} />
            </DataTable.Cell>
            <DataTable.Cell style={{ flex: 0.5 }}>{track.name}</DataTable.Cell>
            <DataTable.Cell style={{ flex: 0.5 }}>
              {track.source.name}
            </DataTable.Cell>
            <DataTable.Cell style={{ flex: 0.5 }}>
              {userTracks[track.id] ? userTracks[track.id].role : ''}
              {trackMemberships[track.id]
                ? ` - ${trackMemberships[track.id].statusMessage}`
                : ''}
            </DataTable.Cell>
            <DataTable.Cell style={{ flex: 0.2 }} numeric>
              {isMember &&
                userTrack &&
                (userTrack.role === TrackRole.Admin ||
                  userTrack.role === TrackRole.Member) && (
                  <IconButton
                    disabled={savingRole}
                    icon={
                      userTrack.role === TrackRole.Member
                        ? 'shield-account'
                        : 'shield-off'
                    }
                    size={20}
                    onPress={() => confirmRole(userTrack.role, track)}
                  />
                )}
              <IconButton
                disabled={savingAccess}
                icon={isMember ? 'trash-can-outline' : 'plus-circle-outline'}
                size={20}
                onPress={() => confirmAccess(isMember, track)}
              />
            </DataTable.Cell>
          </DataTableRow>
        );
      })}
    </DataTable>
  );
};

export default TracksTable;
