import { useMutation } from '@apollo/react-hooks';
import DataTableRow from 'components/DataTableRow';
import gql from 'graphql-tag';
import { groupBy, omit } from 'lodash';
import Papa from 'papaparse';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Button, DataTable, Subheading, Title } from 'react-native-paper';
import styled from 'utils/styled';

const RESULTS_CREATION = gql`
  mutation CreateWorkoutResults($batchInput: WorkoutResultsBatchInput!) {
    results: workoutResultsBatch(batchInput: $batchInput) {
      id
    }
  }
`;

const FieldContainer = styled.view({
  flexDirection: 'row',
  marginTop: 10,
  alignItems: 'center',
});
const Container = styled.view({ margin: 10 });

interface Props {
  trackId: string;
}

interface MetricData {
  elapsedTime: number;
  elapsedDistance: number;
  avgPace: number;
  avgSpm: number;
  calories: number;
}

interface WorkoutResultInput {
  workoutName: String;
  logbookUser: String;
  summary: MetricData;
  date: String;
  intervals: MetricData[];
  id?: string;
}

const parseTime = (value: String) => {
  const [values, tenths] = value.split('.');
  const [seconds, minutes, hours] = values.split(':').reverse();

  let time = parseInt(tenths);

  time = time + parseInt(seconds) * 10;

  if (minutes) {
    time = time + parseInt(minutes) * 10 * 60;
  }

  if (hours) {
    time = time + parseInt(hours) * 10 * 60 * 60;
  }

  return time;
};

const CsvResultsImporter: React.FC<Props> = ({ trackId }) => {
  const [workoutResults, setWorkoutResults] = useState<WorkoutResultInput[]>(
    []
  );

  const [uploadResults, { loading, error }] = useMutation(RESULTS_CREATION, {});

  const handleCsvUpload = (file: any) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const workoutsByUser = groupBy(
          results.data,
          (row) => `${row.workout}${row.logbook}`
        );

        const workoutResults = Object.values(workoutsByUser).map((rows) => {
          const [summaryRow, ...intervalRows] = rows;
          const { workout, logbook, date, time, meter, pace, rate, calories } =
            summaryRow;
          return {
            workoutName: workout,
            logbookUser: logbook,
            date,
            summary: {
              elapsedTime: parseTime(time),
              elapsedDistance: parseInt(meter),
              avgPace: parseTime(pace),
              avgSpm: parseInt(rate),
              calories: parseInt(calories),
            },
            intervals: intervalRows.map((row) => ({
              elapsedTime: parseTime(row.time),
              elapsedDistance: parseInt(row.meter),
              avgPace: parseTime(row.pace),
              avgSpm: parseInt(row.rate),
              calories: parseInt(row.calories),
            })),
          };
        }) as WorkoutResultInput[];

        setWorkoutResults(workoutResults);
      },
    });
  };

  return (
    <Container>
      <Title>CSV Results Import</Title>
      <FieldContainer>
        <input
          type="file"
          accept=".csv"
          onChange={(e) => handleCsvUpload(e.target.files![0])}
        />
        {workoutResults.length > 0 && (
          <Button
            icon="content-save"
            loading={loading}
            mode="contained"
            onPress={() => {
              uploadResults({
                variables: {
                  batchInput: {
                    trackId,
                    resultsInput: workoutResults.map((r) => omit(r, 'id')),
                  },
                },
              }).then(({ data }) => {
                setWorkoutResults((workoutResults) =>
                  workoutResults.map((result, i) => {
                    return {
                      ...result,
                      id: data.results[i].id,
                    };
                  })
                );
              });
            }}
          >
            Upload Results
          </Button>
        )}
      </FieldContainer>

      {workoutResults.length > 0 && (
        <View>
          {error && (
            <Subheading style={{ color: 'red' }}>${error.message}</Subheading>
          )}

          <DataTable>
            <DataTable.Header>
              <DataTable.Title>Workout Name</DataTable.Title>
              <DataTable.Title>Logbook User</DataTable.Title>
              <DataTable.Title>Status</DataTable.Title>
            </DataTable.Header>
            {workoutResults.map((result, i) => (
              <DataTableRow key={i}>
                <DataTable.Cell>{result.workoutName}</DataTable.Cell>
                <DataTable.Cell>{result.logbookUser}</DataTable.Cell>
                <DataTable.Cell>{result.id ? 'Uploaded' : ''}</DataTable.Cell>
              </DataTableRow>
            ))}
          </DataTable>
        </View>
      )}
    </Container>
  );
};

export default CsvResultsImporter;
