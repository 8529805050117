import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DataTableRow from 'components/DataTableRow';
import gql from 'graphql-tag';
import React, { useRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import {
  ActivityIndicator,
  Button,
  Caption,
  DataTable,
  Dialog,
  IconButton,
  Portal,
  Text,
  TextInput,
} from 'react-native-paper';
import { WORKOUT_FRAGMENT } from 'shared/graphql/fragments';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import { colors, sizes } from 'shared/styles';
import { Interval, Workout } from 'shared/types';
import { formatIntervalValue, formatTime, parseTime } from 'shared/utils';
import { useImmer } from 'use-immer';

interface AdminWorkout extends Workout {
  youtubeId?: string;
}

interface Props {
  workout: AdminWorkout;
  isAudio: boolean;
  onDismiss(): void;
}

const VIDEO_MUTATION = gql`
  mutation UpdateWorkoutVideo($input: WorkoutVideoInput!) {
    workoutVideoUpdate(input: $input) {
      ...WorkoutFragment
    }
  }
  ${WORKOUT_FRAGMENT}
`;

const VIDEO_URL_MUTATION = gql`
  mutation UpdateWorkoutVideoUrl($input: WorkoutVideoUrlInput!) {
    workoutVideoUrlUpdate(input: $input) {
      ...WorkoutFragment
      youtubeId
    }
  }
  ${WORKOUT_FRAGMENT}
`;

const WORKOUT_QUERY = gql`
  query GetWorkout($id: ID!) {
    workout(id: $id) {
      ...WorkoutFragment
      youtubeId
    }
  }
  ${WORKOUT_FRAGMENT}
`;

function youtubeParser(url: string) {
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[1].length === 11 ? match[1] : false;
}

const MediaPlayer = ({
  videoRef,
  workout,
  isYoutubeUrl,
  isAudio,
  editUrl,
  onChangeUrl,
}: {
  videoRef: any;
  workout: AdminWorkout;
  isYoutubeUrl: boolean;
  isAudio: boolean;
  editUrl: boolean;
  onChangeUrl: () => void;
}) => {
  const videoUrl = workout.videoUrl;

  const [updateVideoUrl] = useMutation(VIDEO_URL_MUTATION, {});

  useQuery(WORKOUT_QUERY, {
    variables: { id: workout.id },
    skip: !isYoutubeUrl,
    pollInterval: isYoutubeUrl ? 10000 : undefined,
  });

  if (isYoutubeUrl) {
    return (
      <View style={{ padding: 20, flexDirection: 'row', alignItems: 'center' }}>
        <Alert severity="info" icon={<ActivityIndicator animating={true} />}>
          The video is being processed on ErgZone servers and will be available
          shortly. You may close the window and come back later or wait until
          it's done.
        </Alert>
      </View>
    );
  }

  if (editUrl || !videoUrl) {
    let placeholder = `${workout.youtubeId ? 'New ' : ''}YouTube video link`;
    if (isAudio) {
      placeholder = 'Enter audio link';
    }

    return (
      <View style={{ padding: 20 }}>
        {workout.youtubeId && (
          <Text style={{ marginBottom: 20 }}>
            Current YouTube video:{' '}
            <Link
              href={`https://www.youtube.com/watch?v=${workout.youtubeId}`}
              target="_blank"
              rel="noreferrer noopener"
            >{`https://www.youtube.com/watch?v=${workout.youtubeId}`}</Link>
          </Text>
        )}
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            style={{ flexGrow: 1 }}
            mode="outlined"
            dense
            placeholder={placeholder}
            onChangeText={(text) => {
              let url;
              if (isAudio) {
                if (
                  (text.endsWith('.mp3') || text.endsWith('.m4a')) &&
                  text !== workout.videoUrl
                ) {
                  url = text;
                }
              } else {
                const youtubeId = youtubeParser(text);
                if (youtubeId && youtubeId !== workout.youtubeId) {
                  url = `https://www.youtube.com/watch?v=${youtubeId}`;
                }
              }
              if (url) {
                onChangeUrl();
                updateVideoUrl({
                  variables: {
                    input: {
                      workoutId: workout.id,
                      url,
                    },
                  },
                });
              }
            }}
          />
          {!isAudio && (
            <Tooltip title="Unlisted videos from YouTube are preferable since they are not publicly available to everyone.">
              <span>
                <IconButton
                  color={colors.textColor}
                  icon="information"
                ></IconButton>
              </span>
            </Tooltip>
          )}
        </View>
      </View>
    );
  }
  return (
    <View style={styles.videoContainer}>
      <video ref={videoRef} width="50%" controls>
        <source src={workout.videoUrl} />
      </video>
    </View>
  );
};

const EditVideoModal: React.FC<Props> = ({ workout, isAudio, onDismiss }) => {
  const [updateWorkout, { loading }] = useMutation(VIDEO_MUTATION, {});
  const confirmWith = useConfirmModal();
  const [editUrl, setEditUrl] = useState(false);
  const [jumpTo, setJumpTo] = useState<string | undefined>();

  const [intervals, updateIntervals] = useImmer<Interval[]>(workout.intervals);
  const videoRef = useRef<any>();

  const isYoutubeUrl = workout.videoUrl
    ? workout.videoUrl.startsWith('https://www.youtube.com')
    : false;

  const setVideoTime = (index: number, value: any) => {
    updateIntervals((draft) => {
      draft[index].videoTime = value;
    });
  };

  const onSave = () => {
    updateWorkout({
      variables: {
        input: {
          workoutId: workout.id,
          videoTimes: intervals.map((i) => i.videoTime),
        },
      },
    })
      .then(() => onDismiss())
      .catch(({ graphQLErrors }) => {
        const message =
          graphQLErrors && graphQLErrors.map((e: any) => e.message).join(', ');
        confirmWith({
          title: 'Error',
          message:
            message || 'Error saving video information. Please, try again!',
          hideCancel: true,
        });
      });
  };

  return (
    <Portal>
      <Dialog visible onDismiss={onDismiss} style={styles.dialog}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Dialog.Title>Manage {isAudio ? 'Audio' : 'Video'}</Dialog.Title>
          {!isYoutubeUrl && (
            <IconButton
              style={{ alignSelf: 'flex-end' }}
              icon="pencil"
              color={colors.textColor}
              onPress={() => setEditUrl((value) => !value)}
            />
          )}
        </View>

        <MediaPlayer
          isYoutubeUrl={isYoutubeUrl}
          isAudio={isAudio}
          videoRef={videoRef}
          workout={workout}
          editUrl={editUrl}
          onChangeUrl={() => setEditUrl(false)}
        />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: sizes.spacing,
          }}
        >
          <TextInput
            style={{
              width: 100,
              marginRight: sizes.spacing / 2,
              marginBottom: 4,
            }}
            onChangeText={setJumpTo}
            dense
            mode="outlined"
            placeholder="Time"
          />
          <Button
            onPress={() => {
              const time = parseTime(jumpTo || '');
              if (time) {
                videoRef.current.currentTime = time / 10;
              }
            }}
          >
            Jump
          </Button>
        </View>

        <ScrollView>
          <DataTable>
            <DataTable.Header>
              <DataTable.Title>Interval</DataTable.Title>
              <DataTable.Title style={{ flex: 0.5 }}>
                Video Time
              </DataTable.Title>
              <DataTable.Title numeric>Actions</DataTable.Title>
            </DataTable.Header>

            {intervals.map((interval, index) => {
              return (
                <DataTableRow
                  key={index}
                  onPress={() => {
                    if (interval.videoTime) {
                      videoRef.current.currentTime = interval.videoTime;
                    }
                  }}
                >
                  <DataTable.Cell>
                    Interval {index + 1}{' '}
                    <Caption>({formatIntervalValue(interval)})</Caption>
                  </DataTable.Cell>
                  <DataTable.Cell style={{ flex: 0.5 }}>
                    {interval.videoTime
                      ? formatTime(interval.videoTime, true, true)
                      : '-'}
                  </DataTable.Cell>
                  <DataTable.Cell numeric>
                    {!!interval.videoTime && (
                      <IconButton
                        color={colors.textColor}
                        icon="close-circle"
                        onPress={() => setVideoTime(index, null)}
                      />
                    )}
                    <IconButton
                      icon="pin"
                      color={colors.textColor}
                      onPress={() => {
                        const value = videoRef.current.currentTime;
                        if (value) {
                          setVideoTime(index, Math.round(value * 10) / 10);
                        }
                      }}
                    />
                  </DataTable.Cell>
                </DataTableRow>
              );
            })}
          </DataTable>
        </ScrollView>
        <Dialog.Actions style={styles.actionsContainer}>
          <Button mode="outlined" onPress={onDismiss}>
            Cancel
          </Button>
          <Button
            loading={loading}
            mode="contained"
            icon="content-save"
            onPress={onSave}
          >
            Save
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: {
    alignSelf: 'center',
    height: '80vh',
    width: '60vw',
  },
  videoContainer: {
    overflow: 'hidden',
    width: '100%',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionsContainer: {
    paddingVertical: sizes.spacing,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});

export default EditVideoModal;
