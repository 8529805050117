import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  List,
  Text,
} from 'react-native-paper';
import OptionsPicker from 'shared/components/OptionsPicker';
import usePreferences from './useLeaderboardPreferences';

interface Props {
  onDismiss: () => void;
}

const gridOptions = Array.from(Array(6).keys()).map((i) => ({
  value: i + 1,
  name: String(i + 1),
}));

const WorkoutLeaderboardSettings = ({ onDismiss }: Props) => {
  const {
    gridRows,
    gridColumns,
    setGridRows,
    setGridColumns,
    hideFlag,
    setHideFlag,
    dark,
    setDark,
  } = usePreferences();

  return (
    <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
      <Dialog visible onDismiss={onDismiss} style={styles.dialog}>
        <Dialog.Title>Metrics</Dialog.Title>

        <ScrollView>
          <List.Item
            title="Columns"
            right={() => (
              <OptionsPicker
                style={{ width: 120 }}
                options={gridOptions}
                currentValue={gridColumns}
                onChange={(value) => {
                  setGridColumns(value);
                }}
              />
            )}
          />
          <List.Item
            title="Rows"
            right={() => (
              <OptionsPicker
                style={{ width: 120 }}
                options={gridOptions}
                currentValue={gridRows}
                onChange={(value) => {
                  setGridRows(value);
                }}
              />
            )}
          />
          <List.Item
            title={
              <Text>
                Show flag:{' '}
                <Text style={{ fontWeight: 'bold' }}>
                  {hideFlag ? 'off' : 'on'}
                </Text>
              </Text>
            }
            right={() => (
              <Checkbox.Android
                status={hideFlag ? 'unchecked' : 'checked'}
                onPress={() => setHideFlag(!hideFlag)}
              />
            )}
          />
          <List.Item
            title={
              <Text>
                Dark mode:{' '}
                <Text style={{ fontWeight: 'bold' }}>
                  {dark ? 'on' : 'off'}
                </Text>
              </Text>
            }
            right={() => (
              <Checkbox.Android
                status={dark ? 'checked' : 'unchecked'}
                onPress={() => setDark(!dark)}
              />
            )}
          />
        </ScrollView>

        <Divider />
        <Dialog.Actions>
          <Button onPress={onDismiss}>Close</Button>
        </Dialog.Actions>
      </Dialog>
    </div>
  );
};

const styles = StyleSheet.create({
  dialog: {
    height: '50vh',
    alignSelf: 'center',
    width: '90%',
    maxWidth: 400,
  },
});

export default WorkoutLeaderboardSettings;
