import { get } from 'lodash';

export const isMobileApp = () => !!get(window, 'ReactNativeWebView');

export const hasMobileState = () => !!get(window, 'initialState');

export const getMobileToken = () => get(window, 'initialState.token');

export const postMobileMessage = (message: any) => {
  if (isMobileApp()) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(message);
  }
};
