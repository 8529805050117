import {
  AppBar,
  Avatar,
  Button,
  Collapse,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import useCurrentUser, { AdminTrack } from 'hooks/useCurrentUser';
import { partition } from 'lodash';
import React, { useState } from 'react';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useHistory, useLocation } from 'react-router-dom';
import { AdminTrackAction } from 'shared/types';
import { isBillingAvailable } from 'utils/helpers';
import BeforeBody from './BeforeBody';
import NewTrackModal from './NewTrackModal';

interface Props {}

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  actionContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    overflow: 'auto',
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#f9f9f9',
    paddingBottom: 120,
  },
  header: {
    cursor: 'pointer',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
}));

const TracksMenuItem = ({
  name,
  tracks,
}: {
  name: string;
  tracks: AdminTrack[];
}) => {
  const [trackOpen, setTrackOpen] = useState(true);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  if (tracks.length === 0) return null;

  return (
    <>
      <ListItem button onClick={() => setTrackOpen((value) => !value)}>
        <ListItemText primary={name} />
        <MaterialCommunityIcon
          name={trackOpen ? 'chevron-up' : 'chevron-down'}
          size={24}
        />
      </ListItem>
      <Collapse in={trackOpen} timeout="auto" unmountOnExit>
        <List>
          {tracks.map((track) => (
            <ListItem
              key={track.id}
              button
              className={classes.nested}
              selected={location.pathname.startsWith(`/tracks/${track.id}`)}
              onClick={() =>
                history.push(
                  `/tracks/${track.id}/${track.planWeeks ? 'users' : 'workouts'}`
                )
              }
            >
              <Avatar src={track.imageUrl} className={classes.avatar} />
              <ListItemText
                primary={track.name}
                secondary={
                  track.adminActions.includes(AdminTrackAction.Edit)
                    ? ''
                    : 'Not active'
                }
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const AppLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const { currentUser, adminTracks, logout } = useCurrentUser();
  const location = useLocation();
  const history = useHistory();

  const [showTrackCreation, setShowTrackCreation] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (
    location.pathname !== '/live' &&
    location.pathname !== '/live/' &&
    (location.pathname.startsWith('/login') ||
      location.pathname.startsWith('/auth') ||
      location.pathname.startsWith('/stats') ||
      location.pathname.startsWith('/track-stats') ||
      location.pathname.startsWith('/antplus') ||
      location.pathname.endsWith('/leaderboard') ||
      location.pathname.includes('/live'))
  ) {
    return <>{children}</>;
  }

  const [plans, notPlansTracks] = partition(adminTracks, (t) => t.planWeeks);
  const [comps, otherTracks] = partition(notPlansTracks, (t) => t.compStatus);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {currentUser.superadmin && (
          <>
            <ListItem
              button
              selected={location.pathname.startsWith('/sources')}
              onClick={() => history.push('/sources')}
            >
              <ListItemText primary="Sources" />
            </ListItem>
            <ListItem
              button
              selected={
                location.pathname.startsWith('/users') &&
                !location.pathname.startsWith(`/users/${currentUser.id}`)
              }
              onClick={() => history.push('/users')}
            >
              <ListItemText primary="Users" />
            </ListItem>
          </>
        )}
        {!currentUser.superadmin && isBillingAvailable(currentUser) && (
          <ListItem
            button
            selected={location.pathname.startsWith('/billing')}
            onClick={() => history.push('/billing')}
          >
            <ListItemText primary="Billing" />
          </ListItem>
        )}
        <ListItem
          button
          selected={location.pathname.startsWith('/live')}
          onClick={() => history.push('/live')}
        >
          <ListItemText primary="Live" />
        </ListItem>
        <ListItem
          button
          selected={location.pathname.startsWith(`/users/${currentUser.id}`)}
          onClick={() => history.push(`/users/${currentUser.id}/results`)}
        >
          <ListItemText primary="My Profile" />
        </ListItem>
        {currentUser && (
          <div className={classes.actionContainer}>
            <Button
              variant="contained"
              disabled={!currentUser.appSubscription.active}
              onClick={() => setShowTrackCreation(true)}
              startIcon={<MaterialCommunityIcon name="plus" />}
            >
              New Group
            </Button>
          </div>
        )}

        <TracksMenuItem name="Groups" tracks={otherTracks} />
        <TracksMenuItem name="Plans" tracks={plans} />
        <TracksMenuItem name="Comps" tracks={comps} />
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MaterialCommunityIcon name="menu" color="white" size={30} />
          </IconButton>
          <Typography
            className={classes.header}
            variant="h6"
            noWrap
            onClick={() => history.push('/')}
          >
            ErgZone Admin
          </Typography>
          <Tooltip title="Logout" style={{ marginLeft: 'auto' }}>
            <span>
              <IconButton onClick={logout}>
                <MaterialCommunityIcon name="logout" color="white" size={25} />
              </IconButton>
            </span>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp>
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
      {showTrackCreation && (
        <NewTrackModal onDismiss={() => setShowTrackCreation(false)} />
      )}
      <BeforeBody currentUser={currentUser} />
    </div>
  );
};

export default AppLayout;
