import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { cloneDeep } from 'apollo-utilities';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import usePreferences from 'hooks/usePreferences';
import { sortBy, sumBy } from 'lodash';
import { Channel, Socket as PhoenixSocket } from 'phoenix';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import FlipMove from 'react-flip-move';
import { StyleSheet, View } from 'react-native';
import {
  Card,
  Headline,
  Provider as PaperProvider,
  Text,
  Theme,
} from 'react-native-paper';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useParams } from 'react-router-dom';
import { METRICS, MetricType } from 'shared/components/metrics';
import {
  SCHEDULED_WORKOUT_FRAGMENT,
  WORKOUT_FRAGMENT,
} from 'shared/graphql/fragments';
import useOrientation from 'shared/hooks/useOrientation';
import { colors, darkTheme } from 'shared/styles';
import {
  AdminTrackAction,
  BroadcastEventType,
  CurrentUser,
  DeviceStatus,
  ErgBenchmarks,
  HrSource,
  Interval,
  IntervalStatus,
  MonitorState,
  UserDisplay,
  Workout,
  WorkoutStatus,
} from 'shared/types';
import {
  calculateDeviceTypes,
  getErgBenchmarks,
  payloadToInterval,
  payloadToOverall,
  totalProgress,
  userColor,
} from 'shared/utils';
import { SERVER_URL } from 'utils/config';
import { isMobileApp, postMobileMessage } from 'utils/mobile';
import { getSessionToken } from 'utils/session';
import { LiveOrder, LiveViewMode } from 'utils/types';
import EmojiDisplay from './EmojiDisplay';
import LiveSettings from './LiveSettings';
import LiveUser from './LiveUser';
import RaceSettings from './RaceSettings';
import { IconButton } from './utils';

interface Params {
  workoutId?: string;
  workoutToken?: string;
}

interface UserMonitorState extends MonitorState {
  ergBenchmarks: ErgBenchmarks;
}

interface UserData extends CurrentUser {
  name: string;
}

interface WorkoutData extends Workout {
  disabledLiveViewerMessage?: string;
}

interface WorkoutQuery {
  workout: WorkoutData;
}

const WORKOUT_QUERY = gql`
  query BroadcastWorkout($workoutId: ID!) {
    workout(id: $workoutId) {
      ...WorkoutFragment
      disabledLiveViewerMessage
      scheduledWorkouts {
        ...ScheduledWorkoutFragment
      }
    }
  }
  ${WORKOUT_FRAGMENT}
  ${SCHEDULED_WORKOUT_FRAGMENT}
`;
const WORKOUT_BY_TOKEN_QUERY = gql`
  query BroadcastWorkout($workoutToken: String!) {
    workout: workoutByToken(token: $workoutToken) {
      ...WorkoutFragment
      disabledLiveViewerMessage
      scheduledWorkouts {
        ...ScheduledWorkoutFragment
      }
    }
  }
  ${WORKOUT_FRAGMENT}
  ${SCHEDULED_WORKOUT_FRAGMENT}
`;

const USERS_QUERY = gql`
  query BroadcastUsers($ids: [ID!], $userDisplay: Int) {
    users: usersById(ids: $ids) {
      id
      name(display: $userDisplay)
      settings {
        maxHeartRate
        restingHeartRate
        weight
        weightUnit
        age
        gender
        displayForceCurve
        benchmarkValues {
          distance
          name
          scoreNumber
          group
          field
          ergType
        }
      }
    }
  }
`;

const TotalProgress = ({
  color,
  intervals,
  activeIndex,
  isCurrentUser,
}: {
  color: string;
  intervals: Interval[];
  activeIndex: number;
  isCurrentUser: boolean;
}) => {
  return (
    <View style={styles.progressContainer}>
      {intervals.map((interval, i) => (
        <View
          key={i}
          data-type={
            i === activeIndex &&
            interval.status === IntervalStatus.Active &&
            isCurrentUser
              ? 'currentProgress'
              : undefined
          }
          style={[
            styles.progressBackground,
            {
              width: `${100 / intervals.length}%`,
              borderRightWidth: i === intervals.length - 1 ? 0 : 4,
            },
          ]}
        >
          <View
            style={{
              backgroundColor: color,
              width: `${i < activeIndex ? 100 : interval.progress || 0}%`,
              flex: 1,
            }}
          />
        </View>
      ))}
    </View>
  );
};

const defaultfontSize = 28;
const boldTheme = {
  ...darkTheme,
  fonts: {
    ...darkTheme.fonts,
    regular: darkTheme.fonts.medium,
  },
};
const inactiveTheme = {
  ...darkTheme,
  colors: {
    ...darkTheme.colors,
    text: darkTheme.colors.placeholder,
  },
};

const THEMES = {
  [IntervalStatus.Completed]: darkTheme,
  [IntervalStatus.Inactive]: inactiveTheme,
  [IntervalStatus.Rest]: boldTheme,
  [IntervalStatus.Active]: boldTheme,
};

const MetricRow = ({
  metric,
  user,
  monitorState,
  interval,
  showIntervalMetrics,
  fontSize,
  theme,
  showHeader = true,
}: {
  metric: MetricType;
  user: UserData;
  monitorState: UserMonitorState;
  interval?: Interval;
  showIntervalMetrics: boolean;
  fontSize: number;
  theme: Theme;
  showHeader?: boolean;
}) => {
  const header = METRICS[metric].shortName;
  const RenderInterval = METRICS[metric].renderCell;
  const RenderOverall = METRICS[metric].renderHeaderCell;

  return (
    <View
      key={metric}
      style={[
        styles.metricContainer,
        {
          paddingHorizontal: fontSize / 6,
          marginTop: fontSize / 6,
          height: fontSize * 1.8,
        },
      ]}
    >
      {showHeader ? (
        <Text
          numberOfLines={1}
          style={{ fontSize: fontSize * 0.6, color: colors.lightGray }}
        >
          {header}
        </Text>
      ) : (
        <View />
      )}
      <View>
        {showIntervalMetrics ? (
          <RenderInterval
            interval={interval!}
            fontSize={fontSize}
            theme={theme}
            currentUser={user}
            currentPace={monitorState.currentPace}
            forceCurve={monitorState.forceCurve}
            ergType={monitorState.ergType}
            rowerType={monitorState.rowerType}
            ergBenchmarks={monitorState.ergBenchmarks}
            isPro={monitorState.isPro}
          />
        ) : (
          <RenderOverall
            fontSize={fontSize}
            theme={theme}
            currentUser={user}
            intervals={monitorState.intervals}
            elapsedTime={monitorState.elapsedTime}
            elapsedDistance={monitorState.elapsedDistance}
            activeIndex={monitorState.activeIndex}
            currentPace={monitorState.currentPace}
            currentHeartRate={monitorState.currentHeartRate}
            currentStrokeRate={monitorState.currentStrokeRate}
            currentDragFactor={monitorState.currentDragFactor}
            currentWatts={monitorState.currentWatts}
            currentDriveLength={monitorState.currentDriveLength}
            currentStrokeDistance={monitorState.currentStrokeDistance}
            currentDriveTime={monitorState.currentDriveTime}
            currentRecoveryTime={monitorState.currentRecoveryTime}
            currentAvgForce={monitorState.currentAvgForce}
            currentPeakForce={monitorState.currentPeakForce}
            currentSpeed={monitorState.currentSpeed}
            forceCurve={monitorState.forceCurve}
            prevForceCurves={monitorState.prevForceCurves}
            ergType={monitorState.ergType}
            deviceTypes={monitorState.deviceTypes}
            rowerType={monitorState.rowerType}
            deviceStatus={monitorState.deviceStatus}
            ergBenchmarks={monitorState.ergBenchmarks}
            isPro={monitorState.isPro}
          />
        )}
      </View>
    </View>
  );
};

const DeviceStatusMessages: { [key: string]: string } = {
  [DeviceStatus.Disconnected]: 'Disconnected!',
  [DeviceStatus.Connecting]: 'Connecting erg...',
  [DeviceStatus.FlywheelRunning]: 'False start!',
  [DeviceStatus.SendingWorkout]: 'Configuring erg...',
  [DeviceStatus.SendingDataEnd]: 'Erg configured!',
  [DeviceStatus.ReadyToStart]: 'Ready to start!',
  [DeviceStatus.BluetoothDisconnect]: 'Bluetooth disconnected',
  [DeviceStatus.RaceExpired]: 'Missed race start!',
};

const DeviceStatusMessage = ({
  fontSize,
  deviceStatus,
}: {
  fontSize: number;
  deviceStatus: DeviceStatus;
}) => {
  const message = DeviceStatusMessages[deviceStatus];
  if (message === undefined) return null;
  return (
    <View style={styles.tileOverlayContainer}>
      <Headline style={{ fontSize: fontSize * 0.7 }}>{message}</Headline>
    </View>
  );
};

const UserTile = forwardRef(
  (
    {
      userId,
      user,
      index,
      liveOrder,
      showAlwaysFullName,
      currentUserId,
      monitorState,
      onUserSelected,
      metrics,
      showIntervalMetrics,
      liveViewMode,
      vw,
      fontSize,
    }: {
      userId: string;
      user?: UserData;
      index: number;
      liveOrder: LiveOrder;
      showAlwaysFullName: boolean;
      currentUserId: string;
      monitorState?: UserMonitorState;
      onUserSelected: (user: UserData) => void;
      metrics: MetricType[];
      liveViewMode: LiveViewMode;
      showIntervalMetrics: boolean;
      vw: (value: number) => number;
      fontSize: number;
    },
    ref
  ) => {
    if (!user || !monitorState) {
      return (
        <Card
          //@ts-ignore
          ref={ref}
          style={{
            marginTop: fontSize / 6,
            width: liveViewMode === LiveViewMode.Rows ? '60%' : fontSize * 8,
            paddingVertical: fontSize / 2,
            paddingHorizontal: fontSize,
          }}
        >
          <Text style={{ fontSize: fontSize * 0.7 }}>Loading User...</Text>
        </Card>
      );
    }

    const intervals = monitorState.intervals;
    const interval = intervals[monitorState.activeIndex];

    const color = userColor(user.name);
    const cellSpacing = fontSize / 6;

    if (liveViewMode === LiveViewMode.Rows) {
      const summaryColumn = fontSize * SUMMARY_COLUMN_FONT_RATIO;

      const metricColumn = Math.max(
        (vw(100) - summaryColumn) / intervals.length,
        fontSize * 5
      );

      return (
        <View
          //@ts-ignore
          ref={ref}
          style={[
            {
              paddingVertical: cellSpacing,
              flexDirection: 'row',
              width: 'fit-content',
              backgroundColor: 'rgb(30, 30, 30)',
            },
          ]}
        >
          <View
            style={[
              styles.sticky,
              {
                width: summaryColumn,
                paddingHorizontal: cellSpacing,
                left: 0,
                zIndex: 1,
                backgroundColor: 'rgb(30, 30, 30)',
              },
            ]}
          >
            <Text
              style={{
                color,
                fontSize: fontSize * 0.7,
                fontWeight: '800',
              }}
              numberOfLines={1}
            >
              {nameDisplay(user.name, index, liveOrder, showAlwaysFullName)}
            </Text>
            {monitorState.deviceStatus === DeviceStatus.WorkoutEnded && (
              <MaterialCommunityIcon
                style={{ position: 'absolute', top: 0, right: 0 }}
                name="check-circle-outline"
                size={fontSize}
                color="white"
              />
            )}

            <View>
              {metrics.map((metric) => (
                <MetricRow
                  key={metric}
                  metric={metric}
                  fontSize={fontSize}
                  theme={boldTheme}
                  showIntervalMetrics={false}
                  user={user}
                  monitorState={monitorState}
                />
              ))}
              <DeviceStatusMessage
                fontSize={fontSize}
                deviceStatus={monitorState.deviceStatus}
              />
            </View>
          </View>

          <View style={{ flex: 1, flexDirection: 'row', overflow: 'hidden' }}>
            {intervals.map((interval, i) => (
              <View
                key={i}
                data-type={
                  i === monitorState.activeIndex &&
                  interval.status === IntervalStatus.Active &&
                  currentUserId === userId
                    ? 'currentProgress'
                    : undefined
                }
                data-progress={interval.progress}
                data-index={i}
                style={[
                  {
                    width: metricColumn,
                    paddingRight: cellSpacing,
                  },
                ]}
              >
                <View
                  style={[
                    {
                      marginVertical: 'auto',
                      height: fontSize / 3,
                      width: '100%',
                      borderRightColor: 'rgb(30, 30, 30)',
                      backgroundColor: 'rgba(255,255,255, 0.15)',
                    },
                  ]}
                >
                  <View
                    style={{
                      backgroundColor: color,
                      width: `${i < monitorState.activeIndex ? 100 : interval.progress || 0}%`,
                      flex: 1,
                    }}
                  >
                    {i === monitorState.activeIndex && (
                      <View
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: -(cellSpacing * 2),
                          height: (metrics.length + 2) * (fontSize * 1.8),
                          width: 2,
                          backgroundColor: color,
                        }}
                      ></View>
                    )}
                  </View>
                </View>

                <View>
                  {metrics.map((metric) => (
                    <MetricRow
                      key={metric}
                      metric={metric}
                      fontSize={fontSize}
                      theme={
                        THEMES[
                          i < monitorState.activeIndex
                            ? IntervalStatus.Completed
                            : interval.status
                        ]
                      }
                      showIntervalMetrics
                      showHeader={false}
                      user={user}
                      monitorState={monitorState}
                      interval={interval}
                    />
                  ))}
                </View>
              </View>
            ))}
          </View>
        </View>
      );
    } else if (liveViewMode === LiveViewMode.Tiles) {
      return (
        <Card
          //@ts-ignore
          ref={ref}
          style={{ width: fontSize * 8, margin: fontSize / 3 }}
          onPress={() => onUserSelected(user)}
        >
          <View style={{ padding: cellSpacing, alignItems: 'center' }}>
            <Text
              style={{ color, fontSize: fontSize * 0.7, fontWeight: '800' }}
              numberOfLines={1}
            >
              {nameDisplay(user.name, index, liveOrder, showAlwaysFullName)}
            </Text>
            {monitorState.deviceStatus === DeviceStatus.WorkoutEnded && (
              <MaterialCommunityIcon
                style={{ position: 'absolute', top: 0, right: 0 }}
                name="check-circle-outline"
                size={fontSize}
                color="white"
              />
            )}
          </View>
          <TotalProgress
            color={color}
            intervals={monitorState.intervals}
            activeIndex={monitorState.activeIndex}
            isCurrentUser={currentUserId === userId}
          />
          <View style={{ margin: cellSpacing }}>
            {metrics.map((metric) => {
              const { show, modifiedInterval } =
                showIntervalMetricsForTilesView(
                  monitorState,
                  showIntervalMetrics,
                  metric,
                  interval
                );
              return (
                <MetricRow
                  key={metric}
                  metric={metric}
                  showIntervalMetrics={show}
                  fontSize={fontSize}
                  theme={boldTheme}
                  user={user}
                  monitorState={monitorState}
                  interval={modifiedInterval}
                />
              );
            })}
            <DeviceStatusMessage
              fontSize={fontSize}
              deviceStatus={monitorState.deviceStatus}
            />
          </View>
        </Card>
      );
    } else {
      return null;
    }
  }
);

interface Users {
  [key: string]: UserMonitorState;
}

interface Event {
  userId: string;
  payload: number[];
  type: BroadcastEventType;
}

enum ActionType {
  SET_MONITOR_STATE = 'SET_MONITOR_STATE',
  TERMINATE_STATE = 'TERMINATE_STATE',
}

interface SetMonitorState {
  type: ActionType.SET_MONITOR_STATE;
  monitorState: UserMonitorState;
  userId: string;
}

interface TerminateState {
  type: ActionType.TERMINATE_STATE;
  userId: string;
}

type AllActions = SetMonitorState | TerminateState | Event;

const AlwaysIntervalMetrics = new Set([
  MetricType.rest,
  MetricType.diffPace,
  MetricType.diffRate,
  MetricType.wattsDiff,
]);

function showIntervalMetricsForTilesView(
  monitorState: UserMonitorState,
  showIntervalMetrics: boolean,
  metric: MetricType,
  interval: Interval
) {
  if (monitorState.deviceStatus === DeviceStatus.WorkoutEnded) {
    return { show: false, modifiedInterval: interval };
  } else if (!showIntervalMetrics && AlwaysIntervalMetrics.has(metric)) {
    const modifiedInterval = { ...interval };

    if (metric === MetricType.diffPace) {
      interval.avgPace = monitorState.currentPace;
    } else if (metric === MetricType.wattsDiff) {
      interval.avgWatts = monitorState.currentWatts;
    } else if (metric === MetricType.diffRate) {
      interval.avgSpm = monitorState.currentStrokeRate;
    }

    return { show: true, modifiedInterval };
  } else {
    return { show: showIntervalMetrics, modifiedInterval: interval };
  }
}

function reducer(draft: Users, action: AllActions) {
  switch (action.type) {
    case BroadcastEventType.Interval: {
      const monitorState = draft[action.userId];
      if (monitorState) {
        payloadToInterval(action.payload, monitorState);

        for (
          let index = monitorState.activeIndex + 1;
          index < monitorState.intervals.length;
          index++
        ) {
          const nextInterval = monitorState.intervals[index];
          if (nextInterval && nextInterval.status !== IntervalStatus.Inactive) {
            nextInterval.status = IntervalStatus.Inactive;
            nextInterval.progress = 0;
            nextInterval.elapsedDistance = 0;
            nextInterval.elapsedTime = 0;
          } else {
            break;
          }
        }
      }
      return draft;
    }
    case BroadcastEventType.Overall: {
      const monitorState = draft[action.userId];
      if (monitorState) {
        payloadToOverall(action.payload, monitorState);
      } else {
        // @ts-ignore
        draft[action.userId] = undefined;
      }
      return draft;
    }
    case ActionType.SET_MONITOR_STATE: {
      draft[action.userId] = action.monitorState;
      return draft;
    }
    case ActionType.TERMINATE_STATE: {
      const state = draft[action.userId];
      if (state && state.deviceStatus !== DeviceStatus.WorkoutEnded) {
        state.deviceStatus = DeviceStatus.Disconnected;
      }
      return draft;
    }
    default:
      return draft;
  }
}

let userStates: Users = {};
const dispatch = (action: AllActions) => {
  userStates = reducer(userStates, action);
};

const EmptyMessage = ({
  fontSize,
  message,
}: {
  fontSize: number;
  message: string;
}) => {
  return (
    <View style={{ width: '100%', marginTop: 20, alignItems: 'center' }}>
      <Card
        style={{ paddingVertical: fontSize / 2, paddingHorizontal: fontSize }}
      >
        <Text style={{ fontSize: fontSize * 0.7, textAlign: 'center' }}>
          {message}
        </Text>
      </Card>
    </View>
  );
};

const sortByPace = (users: Users) => {
  return sortBy(Object.keys(users), (userId) => {
    const monitorState = users[userId];

    if (
      monitorState &&
      (monitorState.deviceStatus === DeviceStatus.WorkoutStarted ||
        totalProgress(monitorState.intervals) === 100)
    ) {
      const hasOverall =
        !!monitorState.elapsedDistance && !!monitorState.elapsedTime;
      const time = hasOverall
        ? monitorState.elapsedTime! / 100
        : sumBy(monitorState.intervals, (i) => i.elapsedTime || 0);
      const distance = hasOverall
        ? monitorState.elapsedDistance! / 10
        : sumBy(monitorState.intervals, (i) => i.elapsedDistance || 0);
      if (time && distance) {
        return time / distance;
      }
    }

    return Number.MAX_SAFE_INTEGER;
  });
};

const sortByDistanceTime = (users: Users) => {
  return sortBy(Object.keys(users), [
    (userId) => {
      const monitorState = users[userId];

      if (monitorState) {
        if (monitorState.elapsedDistance) {
          return -monitorState.elapsedDistance / 10;
        } else {
          return -sumBy(monitorState.intervals, (i) => i.elapsedDistance || 0);
        }
      }

      return 0;
    },
    (userId) => {
      const monitorState = users[userId];

      if (monitorState) {
        if (monitorState.elapsedTime) {
          return monitorState.elapsedTime / 100;
        } else {
          return sumBy(monitorState.intervals, (i) => i.elapsedTime || 0);
        }
      }

      return 0;
    },
  ]);
};

const sortUserIds = (users: Users, liveOrder: LiveOrder) => {
  switch (liveOrder) {
    case LiveOrder.Join:
      return Object.keys(users);
    case LiveOrder.Progress:
      return sortByDistanceTime(users);
    case LiveOrder.Pace:
      return sortByPace(users);

    default:
      return Object.keys(users);
  }
};

const phoenixSocket = new PhoenixSocket(
  `${SERVER_URL.replace('http', 'ws')}/socket`,
  {
    params: () => {
      const token = getSessionToken();
      return { token, viewer: 'true' };
    },
  }
);

const SUMMARY_COLUMN_FONT_RATIO = 8;

const FlipContainer = ({
  children,
  workingOut,
  liveOrder,
}: {
  children: React.ReactNode;
  workingOut: boolean;
  liveOrder: LiveOrder;
}) => {
  if (liveOrder !== LiveOrder.Join && workingOut) {
    return <>{children}</>;
  } else {
    return <FlipMove typeName={null}>{children}</FlipMove>;
  }
};

const WorkoutLive = () => {
  const { workoutId, workoutToken } = useParams<Params>();

  const {
    liveMetricTypes,
    showLiveIntervalMetrics,
    liveOrder,
    liveViewMode,
    setLiveViewMode,
    liveFontScale,
    setLiveFontScale,
    showLiveReactions,
    showAlwaysFullName,
    liveReactionIndex,
    setLiveReactionIndex,
  } = usePreferences();
  const { currentUser } = useCurrentUser();
  const [showSettings, setShowSettings] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserData | undefined>();
  const [state, setState] = useState<Users>({});
  const [userIds, setUserIds] = useState<string[]>([]);
  const { vw } = useOrientation();
  const channelRef = useRef<Channel>();
  const usersDataRef = useRef<{ [key: string]: UserData }>({});

  const onUserSelected = (user: UserData | undefined) => {
    if (!isMobileApp()) setSelectedUser(user);
  };

  const {
    id: currentUserId,
    appSubscription: { active: isPro },
  } = currentUser;

  const variables = workoutToken ? { workoutToken } : { workoutId };
  const { data, error } = useQuery<WorkoutQuery>(
    workoutToken ? WORKOUT_BY_TOKEN_QUERY : WORKOUT_QUERY,
    { variables }
  );

  const workout = data && data.workout;

  useEffect(() => {
    if (!workout || !!workout.disabledLiveViewerMessage) return;

    phoenixSocket.connect();
    const workoutChannel = phoenixSocket.channel(`workout:${workout.id}`, {});
    channelRef.current = workoutChannel;

    const userChannel = phoenixSocket.channel(
      `workout_user:${workout.id}/${currentUserId}`
    );

    [workoutChannel, userChannel].forEach((channel) => {
      channel.join();

      channel.on('new_event', dispatch);
    });

    workoutChannel.on('athlete_terminate', (data) => {
      dispatch({ type: ActionType.TERMINATE_STATE, userId: data.userId });
    });

    return () => {
      userChannel.leave();
      workoutChannel.leave();
      phoenixSocket.disconnect();
    };
  }, [workout, currentUserId]);

  useEffect(() => {
    // @ts-ignore
    document.body.style.backgroundColor = colors.darkBackground;

    setTimeout(() => postMobileMessage('loaded'), 100);
  }, []);

  const fontSize = Math.min(vw(5), defaultfontSize) * (liveFontScale / 100);

  const client = useApolloClient();

  useEffect(() => {
    if (!workout) return;

    const onUserLoaded = (user: UserData) => {
      usersDataRef.current[user.id] = user;

      if (!workout) return;

      setWorkingOut((oldValue) => oldValue || user.id === currentUserId);

      const ergBenchmarks = getErgBenchmarks(
        workout.intervals,
        true,
        user ? user.settings : undefined,
        'A'
      );

      const monitorState = {
        ergBenchmarks,
        intervals: workout.intervals.map((i) => ({
          ...i,
          status: IntervalStatus.Inactive,
          distance: 0,
          elapsedTime: 0,
          progress: 0,
          hrZones: [0, 0, 0, 0, 0],
          hrRestZones: [0, 0, 0, 0, 0],
          initialRest: i.rest,
        })),
        ergType: workout.ergType,
        hrSource: HrSource.MACHINE,
        workoutMode: workout.workoutMode!,
        userSettings: user.settings,
        deviceStatus: DeviceStatus.Disconnected,
        deviceInfo: {},
        deviceTypes: calculateDeviceTypes(workout.intervals),
        progress: 0,
        activeIndex: 0,
        workoutResultId: '',
        isPro,
      };

      dispatch({
        type: ActionType.SET_MONITOR_STATE,
        userId: user.id,
        monitorState,
      });

      if (channelRef.current) {
        channelRef.current.push('viewer_joined', { viewerId: currentUserId });
      }
    };

    const loadMissingUsers = () => {
      const ids = Object.keys(userStates).filter((userId) => {
        const isMissingState = !userStates[userId];
        const isDataLoaded = usersDataRef.current.hasOwnProperty(userId);
        if (!isDataLoaded) {
          // @ts-ignore
          usersDataRef.current[userId] = undefined;
        }

        return isMissingState && !isDataLoaded;
      });

      if (ids.length > 0) {
        const userDisplay = workout
          ? workout.track.userDisplay
          : UserDisplay.FirstName;

        client
          .query({
            query: USERS_QUERY,
            variables: { ids, userDisplay },
          })
          .then(({ data }) => {
            if (data && data.users) {
              data.users.forEach(onUserLoaded);
            }
          })
          .catch(() => {});
      }
    };

    const reload = () => {
      const newState = cloneDeep(userStates);
      setState(newState);
      setUserIds(sortUserIds(newState, liveOrder));

      loadMissingUsers();

      const element = document.querySelector('[data-type="currentProgress"]');
      if (element) {
        element.parentElement!.scrollIntoView({ block: 'center' });
        // @ts-ignore
        const { index, progress } = element.dataset;
        // @ts-ignore
        const width = element.offsetWidth;
        const offsetRight = width * index + width * (progress / 100);
        const summaryColumnWidth = fontSize * SUMMARY_COLUMN_FONT_RATIO;
        const left = offsetRight - (window.innerWidth - summaryColumnWidth) / 2;
        window.scroll({ left });
      }
    };
    reload();
    const interval = setInterval(reload, 1000);
    return () => clearInterval(interval);
  }, [liveOrder, fontSize, workout, client, currentUserId, isPro]);

  const [workingOut, setWorkingOut] = useState(false);

  const sendRaceEvent = useCallback((raceEvent) => {
    if (channelRef.current) {
      channelRef.current.push('race_update', raceEvent);
    }
  }, []);

  const isRaceAdmin =
    workout &&
    workout.status === WorkoutStatus.raceLocked &&
    workout.track.adminActions.includes(AdminTrackAction.Edit);

  return (
    <View>
      <PaperProvider theme={darkTheme}>
        <View style={[styles.fixed]}>
          {showLiveReactions && channelRef.current && (
            <EmojiDisplay
              channel={channelRef.current}
              liveReactionIndex={liveReactionIndex}
              setLiveReactionIndex={setLiveReactionIndex}
            />
          )}
          {isMobileApp() && <View style={{ width: 50 }} />}
          <IconButton
            icon="settings"
            onPress={() => {
              postMobileMessage('settings-toggle');
              setShowSettings(true);
            }}
          />
          <IconButton
            icon="view-grid"
            style={liveViewMode !== LiveViewMode.Tiles && { opacity: 0.3 }}
            onPress={() => setLiveViewMode(LiveViewMode.Tiles)}
          />
          <IconButton
            icon="view-sequential"
            style={liveViewMode !== LiveViewMode.Rows && { opacity: 0.3 }}
            onPress={() => setLiveViewMode(LiveViewMode.Rows)}
          />
          <IconButton
            icon="format-font-size-decrease"
            onPress={() => setLiveFontScale(Math.max(10, liveFontScale - 10))}
          />
          <Text>{liveFontScale}%</Text>
          <IconButton
            icon="format-font-size-increase"
            onPress={() => setLiveFontScale(liveFontScale + 10)}
          />
        </View>
        <View style={{ height: 48, width: '100%' }} />
        <View
          style={[
            styles.container,
            liveViewMode === LiveViewMode.Tiles && {
              justifyContent: 'space-evenly',
            },
          ]}
        >
          {isRaceAdmin && workout && (
            <RaceSettings workout={workout} sendRaceEvent={sendRaceEvent} />
          )}
          {workout && !!workout.disabledLiveViewerMessage && (
            <EmptyMessage
              fontSize={fontSize * 2}
              message={workout.disabledLiveViewerMessage}
            />
          )}
          {userIds.length === 0 && (
            <EmptyMessage
              fontSize={fontSize}
              message={
                error
                  ? error.graphQLErrors && error.graphQLErrors.length > 0
                    ? error.graphQLErrors[0].message
                    : 'Unexpected error.'
                  : 'Waiting for athletes...'
              }
            />
          )}
          <FlipContainer liveOrder={liveOrder} workingOut={workingOut}>
            {userIds.map((id, index) => (
              <UserTile
                key={id}
                userId={id}
                user={usersDataRef.current[id]}
                index={index}
                liveOrder={liveOrder}
                showAlwaysFullName={showAlwaysFullName}
                currentUserId={currentUserId}
                vw={vw}
                fontSize={fontSize}
                monitorState={state[id]}
                metrics={liveMetricTypes}
                showIntervalMetrics={showLiveIntervalMetrics}
                liveViewMode={liveViewMode}
                onUserSelected={onUserSelected}
              />
            ))}
          </FlipContainer>
        </View>
      </PaperProvider>
      {selectedUser && (
        <LiveUser
          user={selectedUser}
          monitorState={state[selectedUser.id]}
          onDismiss={() => onUserSelected(undefined)}
        />
      )}
      {showSettings && (
        <LiveSettings
          onDismiss={() => {
            postMobileMessage('settings-toggle');
            setShowSettings(false);
          }}
        />
      )}
    </View>
  );
};

const nameDisplay = (
  name: string,
  index: number,
  liveOrder: LiveOrder,
  showAlwaysFullName: boolean
) => {
  const [first, last] = name.split(' ');
  const position = liveOrder !== LiveOrder.Join ? `${index + 1}. ` : '';
  return `${position}${first} ${last ? (showAlwaysFullName ? last : last[0]) : ''}`;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.darkBackground,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    paddingBottom: 20,
  },
  metricContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0, 0.3)',
  },
  progressContainer: {
    width: '100%',
    flexDirection: 'row',
    height: 4,
  },
  progressBackground: {
    borderRightColor: 'rgb(30, 30, 30)',
    height: 4,
    flex: 1,
    backgroundColor: 'rgba(255,255,255, 0.15)',
  },
  tileOverlayContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0, 0.8)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  finishContainer: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  sticky: {
    // @ts-ignore
    position: 'sticky',
  },
  fixed: {
    // @ts-ignore
    position: 'fixed',
    flexDirection: 'row',
    zIndex: 10,
    alignItems: 'center',
    backgroundColor: colors.darkBackground,
  },
});

export default WorkoutLive;
