import { useQuery } from '@apollo/react-hooks';
import { Tab, Tabs } from '@material-ui/core';
import Link from 'components/Link';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import moment from 'moment';
import WorkoutModal from 'pages/TrackShow/WorkoutModal';
import React, { useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Avatar,
  Button,
  Card,
  DataTable,
  IconButton,
  Paragraph,
  Text,
} from 'react-native-paper';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import ErgIcon from 'shared/components/ErgIcon';
import IntervalRow, {
  INTERVAL_HEADERS,
} from 'shared/components/WorkoutEdit/IntervalRow';
import { WORKOUT_FRAGMENT } from 'shared/graphql/fragments';
import { colors, sizes } from 'shared/styles';
import { AdminTrackAction, ErgType, TrackFeature, Workout } from 'shared/types';
import styled from 'utils/styled';
import ResultsTable from './ResultsTable';
import ManualResultModal from './ManualResultModal';

interface Props {}
interface Params {
  workoutId: string;
  tab: string;
}

const VerticalTable = styled.view({});

const ActionsContainer = styled.view({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  margin: 10,
});

const Row = styled.view({
  flexDirection: 'row',
  borderBottomColor: colors.dividerColor,
  borderBottomWidth: sizes.pixel,
  padding: 10,
});

const HeaderCell = styled.view({
  display: 'flex',
  margin: 10,
  padding: 1,
  flexDirection: 'row',
  flex: 0.8,
});

const ContentCell = styled.view({
  borderBottomColor: '#ccc',
  marginBottom: '10px solid #ccc',
  display: 'flex',
  margin: 10,
  padding: 1,
  flexDirection: 'row',
  flex: 0.2,
});

const styles = StyleSheet.create({
  boldText: {
    fontWeight: 'bold',
  },
});

const GET_WORKOUT = gql`
  query GetWorkout($id: ID!) {
    workout(id: $id) {
      ...WorkoutFragment
      originalWorkoutId
      deletedAt
      appUrl
      live
      workoutResultsCount
    }
  }
  ${WORKOUT_FRAGMENT}
`;

interface WorkoutData extends Workout {
  deletedAt: string;
  workoutResultsCount: number;
}

interface Query {
  workout: WorkoutData;
}

const WorkoutShow: React.FC<Props> = () => {
  const { workoutId, tab } = useParams<Params>();
  const { currentUser } = useCurrentUser();
  const [showEdit, setShowEdit] = useState(false);
  const [showManualResult, setShowManualResult] = useState(false);
  const history = useHistory();

  const { data } = useQuery<Query>(GET_WORKOUT, {
    fetchPolicy: 'cache-and-network',
    variables: { id: workoutId },
  });
  const workout = data && data.workout;

  const actions = useMemo(() => {
    const values = [];
    if (workout) {
      if (workout.live && workout.ergType !== ErgType.fit) values.push('live');
      if (workout.hasLeaderboard) values.push('leaderboard');
      if (workout.track.adminActions.includes(AdminTrackAction.Edit))
        values.push('edit');
      if (
        !workout.disabledMessage &&
        workout.track.features.includes(TrackFeature.Impersonate)
      ) {
        values.push('impersonate');
      }
    }
    return values;
  }, [workout]);

  return (
    <View>
      {workout && (
        <Card>
          <Card.Title
            title={workout.title}
            subtitle={
              <Link to={`/tracks/${workout.track.id}/workouts`}>
                {workout.track.name}
              </Link>
            }
            left={() => (
              <Avatar.Image
                size={48}
                source={{ uri: workout.track.imageUrl }}
              />
            )}
          />
          <Card.Content>
            <Paragraph>
              <IconButton icon="calendar" size={15}></IconButton>
              <Text>{moment(workout.publishedAt).format('L')} </Text>
            </Paragraph>
            {actions.length > 0 && (
              <ActionsContainer>
                {actions.includes('live') && (
                  <Button
                    style={{ marginRight: 10 }}
                    icon="radio-tower"
                    mode="outlined"
                    onPress={() =>
                      (window.location.href = `/workouts/${workoutId}/live`)
                    }
                  >
                    Open Live
                  </Button>
                )}
                {actions.includes('leaderboard') && (
                  <Button
                    style={{ marginRight: 10 }}
                    icon="podium"
                    mode="outlined"
                    onPress={() =>
                      (window.location.href = `/workouts/${workoutId}/leaderboard`)
                    }
                  >
                    Leaderboard
                  </Button>
                )}
                {actions.includes('edit') && (
                  <Button
                    style={{ marginRight: 10 }}
                    icon="pencil"
                    mode="outlined"
                    onPress={() => setShowEdit(true)}
                  >
                    Edit Workout
                  </Button>
                )}
                {actions.includes('impersonate') && (
                  <Button
                    style={{ marginRight: 10 }}
                    icon="keyboard"
                    mode="outlined"
                    onPress={() => setShowManualResult(true)}
                  >
                    Add Manual Result
                  </Button>
                )}
              </ActionsContainer>
            )}
          </Card.Content>
        </Card>
      )}

      <Card style={{ marginTop: 20 }}>
        <Tabs
          value={tab || ''}
          onChange={(_, newValue) =>
            history.push(`/workouts/${workoutId}/${newValue}`)
          }
        >
          <Tab
            value="results"
            label={`Results (${workout ? workout.workoutResultsCount : 0})`}
          />
          <Tab value="details" label="Details" />
        </Tabs>

        <Switch>
          <Route path={`/workouts/${workoutId}`} exact>
            <Redirect to={{ pathname: `/workouts/${workoutId}/results` }} />
          </Route>
          <Route path={`/workouts/${workoutId}/details`}>
            {workout && (
              <VerticalTable>
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Type</Text>
                  </ContentCell>
                  <HeaderCell>
                    <ErgIcon
                      ergType={workout.ergType}
                      color="black"
                      size={15}
                    />
                  </HeaderCell>
                </Row>

                {workout.videoUrl && (
                  <Row>
                    <ContentCell>
                      <Text style={styles.boldText}>Video</Text>
                    </ContentCell>
                    <HeaderCell>
                      <Link to={workout.videoUrl}>{workout.videoUrl}</Link>
                    </HeaderCell>
                  </Row>
                )}

                {currentUser.superadmin && workout.originalWorkoutId && (
                  <Row>
                    <ContentCell>
                      <Text style={styles.boldText}>Original Workout</Text>
                    </ContentCell>
                    <HeaderCell>
                      <Text>{workout.originalWorkoutId} </Text>
                    </HeaderCell>
                  </Row>
                )}
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Description</Text>
                  </ContentCell>
                  <HeaderCell>
                    <Text>{workout.description || '-'} </Text>
                  </HeaderCell>
                </Row>
                {workout.intervals.length > 0 && (
                  <Row>
                    <ContentCell>
                      <Text style={styles.boldText}>Intervals</Text>
                    </ContentCell>
                    <HeaderCell>
                      <View style={{ width: 400 }}>
                        <DataTable.Header>
                          {INTERVAL_HEADERS.map((header) => (
                            <DataTable.Title
                              style={{ flex: 1 }}
                              key={header}
                              numeric
                            >
                              {header}
                            </DataTable.Title>
                          ))}
                        </DataTable.Header>
                        {workout.intervals.map((interval, index) => (
                          <IntervalRow
                            interval={interval}
                            workoutType={workout.ergType}
                            key={index}
                            benchmarkValues={
                              currentUser.settings.benchmarkValues
                            }
                            showPaceCalculation={false}
                            index={index}
                          />
                        ))}
                      </View>
                    </HeaderCell>
                  </Row>
                )}
                {workout.appUrl && (
                  <Row>
                    <ContentCell>
                      <Text style={styles.boldText}>Share Link</Text>
                    </ContentCell>
                    <HeaderCell>
                      <Link to={workout.appUrl}>
                        {workout.appUrl.replace(/https?:\/\//, '')}
                      </Link>
                    </HeaderCell>
                  </Row>
                )}
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Status</Text>
                  </ContentCell>
                  <HeaderCell>
                    <Text>{workout.status || '-'} </Text>
                  </HeaderCell>
                </Row>
                <Row>
                  <ContentCell>
                    <Text style={styles.boldText}>Published At</Text>
                  </ContentCell>
                  <HeaderCell>
                    <Text>{moment(workout.publishedAt).format('L')}</Text>
                  </HeaderCell>
                </Row>
                {workout.deletedAt && (
                  <Row>
                    <ContentCell>
                      <Text style={styles.boldText}>Deleted At</Text>
                    </ContentCell>
                    <HeaderCell>
                      <Text>{moment(workout.deletedAt).format('L')}</Text>
                    </HeaderCell>
                  </Row>
                )}
              </VerticalTable>
            )}
          </Route>

          <Route path={`/workouts/${workoutId}/results`} exact>
            {workout && (
              <ResultsTable
                key={`${workout.id}-${workout.workoutResultsCount}`}
                workoutId={workoutId}
                userDisplay={workout.track.userDisplay}
                resultsCount={workout.workoutResultsCount}
                showActions={workout.ergType !== ErgType.fit}
              />
            )}
            <View></View>
          </Route>
        </Switch>
      </Card>

      {showEdit && workout && (
        <WorkoutModal workout={workout} onDismiss={() => setShowEdit(false)} />
      )}
      {showManualResult && workout && (
        <ManualResultModal
          workout={workout}
          onDismiss={() => setShowManualResult(false)}
        />
      )}
    </View>
  );
};

export default WorkoutShow;
