import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Button as MuiButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListSubheader,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { loadStripe } from '@stripe/stripe-js/pure';
import { MuiLink } from 'components/Link';
import gql from 'graphql-tag';
import { chain, sortBy, startCase, ObjectChain } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Button,
  Caption,
  Card,
  List,
  Paragraph,
  Subheading,
} from 'react-native-paper';
import { useLocation } from 'react-router-dom';

interface AppSubscription {
  appPrice?: AppPrice;
  active: boolean;
  statusMessage: string;
  status: string;
  currentPeriodStart?: string;
  currentPeriodEnd?: string;
  currentActiveUsersCount: number;
}

interface CurrentUserData {
  hasStripeId: boolean;
  appSubscription: AppSubscription;
}

interface AppPrice {
  id: string;
  amountCents: number;
  name: string;
  maxUsers: number;
  recurring: string;
  moneyDisplay: string;
}
interface QueryData {
  currentUser: CurrentUserData;
  availableAppPrices: AppPrice[];
  tieredAppPrices: AppPrice[];
  premiumAppPrices: AppPrice[];
}

const CREATE_PORTAL_SESSION = gql`
  mutation CreateAppBillingPortalSession {
    url: appBillingPortalCreate
  }
`;

const CHECKOUT_CREATION = gql`
  mutation CreateAppCheckout($input: AppPriceCheckoutInput!) {
    checkout: appPriceCheckout(input: $input) {
      sessionId
      stripeKey
    }
  }
`;

const APP_PRICE_FRAGMENT = gql`
  fragment AppPriceFragment on AppPrice {
    id
    amountCents
    recurring
    name
    maxUsers
    moneyDisplay
  }
`;

const GET_BILLING = gql`
  query GetBillingInfo {
    currentUser {
      id
      hasStripeId
      appSubscription {
        active
        currentActiveUsersCount
        currentPeriodStart
        currentPeriodEnd
        status
        statusMessage
        appPrice {
          ...AppPriceFragment
        }
      }
    }
    availableAppPrices {
      ...AppPriceFragment
    }
    tieredAppPrices: appPrices(tiered: true) {
      ...AppPriceFragment
    }
    premiumAppPrices: appPrices(tiered: false) {
      ...AppPriceFragment
    }
  }
  ${APP_PRICE_FRAGMENT}
`;

const formatMonthly = (price: AppPrice) => `${price.moneyDisplay} /mo`;
const formatAnnual = (price: AppPrice) => `${price.moneyDisplay} /yr`;
const formatPrice = (price: AppPrice) => {
  if (price.recurring === 'monthly') {
    return formatMonthly(price);
  } else if (price.recurring === 'annual') {
    return formatAnnual(price);
  } else {
    return `${price.moneyDisplay} ${price.recurring}`;
  }
};
const getCurrency = (moneyDisplay: string) =>
  moneyDisplay.replace(/[0-9,]/g, '');

function morePlansDisplay(lastPrice: ObjectChain<AppPrice>): React.ReactNode {
  const currency = getCurrency(lastPrice.get('moneyDisplay').value() || '$');
  return `More than ${lastPrice
    .get('maxUsers')
    .value()} users: ${currency}30/mo or ${currency}300/yr per 100 additional users. `;
}

const PricesSelector = ({
  premiumPrices,
  prices,
  checkout,
}: {
  premiumPrices: AppPrice[];
  prices: AppPrice[];
  checkout: (price: AppPrice) => void;
}) => {
  const [planName, setPlanName] = useState<string>();
  const allPrices = [...prices, ...premiumPrices];
  const priceOptions = allPrices.filter((p) => p.name === planName);

  return (
    <Card style={styles.card}>
      <Card.Title title="Plan" />
      <Card.Content>
        <FormControl
          variant="outlined"
          style={{ marginRight: 10, minWidth: 80, maxWidth: 250 }}
          margin="dense"
        >
          <InputLabel htmlFor="tier-select">Choose Plan</InputLabel>

          <Select
            value={planName}
            onChange={({ target }) => setPlanName(target.value as string)}
            id="tier-select"
            label="Choose Plan"
          >
            <ListSubheader>Individual</ListSubheader>
            {chain(premiumPrices)
              .groupBy('name')
              .map((prices, name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))
              .value()}
            <ListSubheader>Groups</ListSubheader>
            {chain(prices)
              .groupBy('name')
              .map((prices, name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))
              .value()}
          </Select>
        </FormControl>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {sortBy(priceOptions, (p) => p.recurring)
            .reverse()
            .map((price) => (
              <List.Item
                key={price.id}
                title={formatPrice(price)}
                titleStyle={{ width: 100 }}
                description={
                  price.recurring === 'annual' ? '2 months free' : ''
                }
                left={() => (
                  <MuiButton
                    style={{ alignSelf: 'center', width: 200 }}
                    color="primary"
                    variant="contained"
                    onClick={() => checkout(price)}
                  >
                    Choose{' '}
                    <strong style={{ paddingLeft: 4 }}>
                      {startCase(price.recurring)}
                    </strong>
                  </MuiButton>
                )}
              />
            ))}
        </View>
      </Card.Content>
    </Card>
  );
};

const BillingPage = () => {
  const location = useLocation();

  const stripeSuccess = location.search.includes('stripeSuccess=true');

  const { data } = useQuery<QueryData>(GET_BILLING, {
    fetchPolicy: 'cache-and-network',
    pollInterval: stripeSuccess ? 5000 : undefined,
  });

  const [createCheckout] = useMutation(CHECKOUT_CREATION, {});
  const [createPortalSession, { loading: loadingPortal }] = useMutation(
    CREATE_PORTAL_SESSION
  );

  const checkout = (price: AppPrice) => {
    const url = window.location.href;
    createCheckout({
      variables: {
        input: {
          appPriceId: price.id,
          successUrl: `${url}?stripeSuccess=true`,
          cancelUrl: url,
        },
      },
    }).then(({ data }) => {
      if (data) {
        loadStripe(data.checkout.stripeKey).then((stripe) => {
          if (stripe) {
            stripe.redirectToCheckout({ sessionId: data.checkout.sessionId });
          }
        });
      }
    });
  };

  const availableAppPrices = data ? data.availableAppPrices : [];
  const allTieredAppPrices = data ? data.tieredAppPrices : [];
  const premiumAppPrices = data ? data.premiumAppPrices : [];
  const hasStripeId = data && data.currentUser.hasStripeId;
  const currentSubscription = data && data.currentUser.appSubscription;
  const usersCount = currentSubscription
    ? currentSubscription.currentActiveUsersCount
    : 1;

  const lastPrice = chain(allTieredAppPrices).last();

  return (
    <View>
      {stripeSuccess && (
        <Alert severity="success" style={{ marginBottom: 20 }}>
          You subscription is now active.
        </Alert>
      )}
      <Card>
        <Card.Title title="Billing" />

        <Card.Content>
          <Paragraph>
            <strong>Number of users with access</strong>: {usersCount}
          </Paragraph>
          {currentSubscription && (
            <>
              {currentSubscription.appPrice && (
                <Paragraph>
                  <strong>Current plan</strong>:{' '}
                  {currentSubscription.appPrice.name} -{' '}
                  {formatPrice(currentSubscription.appPrice)}
                </Paragraph>
              )}
              {currentSubscription.currentPeriodStart &&
                currentSubscription.currentPeriodEnd && (
                  <Paragraph>
                    <strong>Period</strong>:{' '}
                    {moment(currentSubscription.currentPeriodStart).format(
                      'LL'
                    )}{' '}
                    -{' '}
                    {moment(currentSubscription.currentPeriodEnd).format('LL')}
                  </Paragraph>
                )}
              <Paragraph>
                <strong>Status</strong>: {currentSubscription.statusMessage}
              </Paragraph>
            </>
          )}

          {hasStripeId && (
            <Button
              style={{ alignSelf: 'flex-start', marginTop: 10 }}
              compact
              icon="credit-card-settings"
              mode="outlined"
              loading={loadingPortal}
              uppercase={false}
              onPress={() => {
                createPortalSession().then(({ data }) => {
                  if (data) window.location.href = data.url;
                });
              }}
            >
              Manage Plan
            </Button>
          )}
        </Card.Content>
      </Card>

      {availableAppPrices.length > 0 && (
        <PricesSelector
          premiumPrices={premiumAppPrices}
          prices={availableAppPrices}
          checkout={checkout}
        />
      )}

      <View style={{ marginTop: 20 }}>
        <Subheading>Individual Plan</Subheading>
        <Caption>
          The individual plan unlocks premium features for your own account. The
          options are to pay monthly or annually and they automatically renew at
          the end of the billing cycle.
        </Caption>
        <Caption>
          All individual plans:{'\n'}
          {chain(premiumAppPrices)
            .groupBy('name')
            .map(
              (prices, name) =>
                `${name}: ${prices.map(formatPrice).join(' or ')}`
            )
            .join('\n')
            .value()}
        </Caption>
        <Caption>Additional taxes may apply based on your location.</Caption>
        <Caption>
          <MuiLink
            href="https://help.erg.zone/article/153-what-is-ergzone-plus"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </MuiLink>{' '}
          to learn more about all ErgZone+ features.
        </Caption>
        <Subheading>Groups Plan</Subheading>
        <Caption>
          <strong>The system automatically adjusts your price</strong> (up, down
          or keep the same) based on the number of users that had access during
          the billing cycle. The calculation happens one week before the next
          billing cycle starts and you'll receive an email in case your plan
          changes.
        </Caption>
        <Caption>
          Your own user is accounted for the plan. Example: Tier 1 (up to 2
          users) includes you plus one more user.
        </Caption>
        <Caption>
          Annual plans are only available for certain tiers and besides of
          saving money, you are also locking the price for the entire year, so
          even if the number of users increases, you won't be charged more
          during that period.
        </Caption>
        <Caption>
          You can cancel at any time and you'll only lose access to admin
          features when the billing period ends.
        </Caption>
        <Caption>
          <strong>
            ErgZone+ Premium features (like advanced metrics) are only unlocked
            to the owner of the group.
          </strong>
        </Caption>
        <Caption>
          All groups plans:{'\n'}
          {chain(allTieredAppPrices)
            .groupBy('name')
            .map(
              (prices, name) =>
                `${name}: ${prices.map(formatPrice).join(' or ')}`
            )
            .join('\n')
            .value()}
        </Caption>
        <Caption>{morePlansDisplay(lastPrice)}</Caption>
        <Caption>Additional taxes may apply based on your location.</Caption>
        <Caption>
          Contact us at support@erg.zone for more plans options.
        </Caption>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  scroll: {
    flex: 1,
    padding: 10,
  },
  card: {
    marginTop: 20,
  },
});

export default BillingPage;
