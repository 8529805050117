import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import {
  Avatar,
  IconButton,
  Provider as PaperProvider,
  Title,
} from 'react-native-paper';
import { useParams } from 'react-router-dom';
import WorkoutLeaderboard from 'shared/components/WorkoutLeaderboard';
import { WORKOUT_FRAGMENT } from 'shared/graphql/fragments';
import { darkTheme, sizes, theme as lightTheme } from 'shared/styles';
import { Workout } from 'shared/types';
import styled from 'utils/styled';
import usePreferences, {
  PreferencesProvider,
} from './useLeaderboardPreferences';
import Settings from './WorkoutLeaderboardSettings';

const ActionsContainer = styled.view({
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

interface Params {
  workoutId: string;
}

interface WorkoutQuery {
  workout: Workout;
}

const WORKOUT_QUERY = gql`
  query WorkoutQuery($workoutId: ID!) {
    workout(id: $workoutId) {
      ...WorkoutFragment
    }
  }
  ${WORKOUT_FRAGMENT}
`;

function calculatedUsersLimit(gridSize: number) {
  return Math.max(3, Math.floor(100 / gridSize));
}

const WorkoutLeaderboardPage = () => {
  const [showSettings, setShowSettings] = useState(false);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const { workoutId } = useParams<Params>();

  useEffect(() => {
    const interval = setInterval(
      () => {
        console.log(new Date());
        setCurrentTime(Date.now());
      },
      1000 * 60 * 60 * 12
    );

    return () => {
      clearInterval(interval);
    };
  }, [setCurrentTime]);

  const { gridRows, gridColumns, hideFlag, paramsList, updateParam, dark } =
    usePreferences();

  const gridSize = gridColumns * gridRows;

  const usersLimit = calculatedUsersLimit(gridSize);

  const theme = dark ? darkTheme : lightTheme;

  const { currentUser } = useCurrentUser();

  const variables = { workoutId };
  const { data } = useQuery<WorkoutQuery>(WORKOUT_QUERY, { variables });
  const workout = data && data.workout;

  return (
    <PaperProvider theme={theme}>
      <View
        style={{ backgroundColor: theme.colors.background, height: '100vh' }}
      >
        <ActionsContainer>
          {workout ? (
            <View
              style={{
                flexDirection: 'row',
                paddingHorizontal: sizes.spacing,
                alignItems: 'center',
              }}
            >
              <Avatar.Image
                size={30}
                source={{ uri: workout.track.imageUrl }}
              />
              <View style={{ marginLeft: sizes.spacing, flex: 1 }}>
                <Title>{workout.title}</Title>
              </View>
            </View>
          ) : (
            <View />
          )}
          <IconButton icon="settings" onPress={() => setShowSettings(true)} />
        </ActionsContainer>

        {!!workout && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${gridColumns}, minmax(0, 1fr))`,
              overflow: 'hidden',
            }}
          >
            {Array.from(Array(gridSize).keys()).map((i) => {
              return (
                <div
                  key={i + currentTime}
                  style={{
                    height: `calc((100vh - 50px) / ${gridRows})`,
                    width: '100%',
                    overflow: 'auto',
                    borderTop: 4,
                    borderRight: (i + 1) % gridColumns === 0 ? 0 : 4,
                    borderColor: dark ? 'black' : 'rgb(204, 204, 204)',
                    borderTopStyle: 'solid',
                    borderRightStyle: 'solid',
                    boxSizing: 'border-box',
                  }}
                >
                  <WorkoutLeaderboard
                    workout={workout}
                    disableResultDetails
                    hideTrackDetails
                    hideFlag={hideFlag}
                    currentUser={currentUser}
                    limit={usersLimit}
                    params={paramsList[i] || {}}
                    onChangeParam={(param, value) => {
                      updateParam(i, param, value);
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
        {showSettings && <Settings onDismiss={() => setShowSettings(false)} />}
      </View>
    </PaperProvider>
  );
};

const WorkoutLeaderboardPageWithPreferences = () => (
  <PreferencesProvider>
    <WorkoutLeaderboardPage />
  </PreferencesProvider>
);

export default WorkoutLeaderboardPageWithPreferences;
