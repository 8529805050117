import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Button, Checkbox, List } from 'react-native-paper';
import { METRICS, MetricType } from 'shared/components/metrics';
import OptionsPicker from 'shared/components/OptionsPicker';
import { useImmer } from 'use-immer';

const MetricRowItem = ({
  metricRow,
  index,
  availableMetrics,
  metricTypes,
  updateMetrics,
  startIndexLabel,
}: {
  metricRow: MetricTypeRow;
  index: number;
  availableMetrics: MetricType[];
  metricTypes: MetricType[];
  updateMetrics: (f: (draft: MetricTypeRow[]) => void) => void;
  startIndexLabel: number;
}) => {
  const options = availableMetrics
    .filter((m) => m === metricRow.metricType || !metricTypes.includes(m))
    .map((m) => ({
      value: m,
      name: METRICS[m].name,
    }));

  const toggle = () => {
    updateMetrics((draft) => {
      draft[index].selected = !draft[index].selected;
    });
  };

  return (
    <List.Item
      title={`Metric ${index + startIndexLabel}`}
      onPress={toggle}
      left={() => (
        <Checkbox.Android
          status={metricRow.selected ? 'checked' : 'unchecked'}
          onPress={toggle}
        />
      )}
      right={() => (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <OptionsPicker
            options={options}
            currentValue={metricRow.metricType}
            onChange={(newMetric) => {
              updateMetrics((draft) => {
                draft[index].metricType = newMetric;
              });
            }}
          />
        </View>
      )}
    />
  );
};

interface Props {
  metricTypes: MetricType[];
  availableMetrics: MetricType[];
  setMetricTypes: (types: MetricType[]) => void;
  startIndexLabel?: number;
}

interface MetricTypeRow {
  metricType: MetricType;
  selected?: boolean;
}

const MetricTypesField = ({
  metricTypes,
  availableMetrics,
  setMetricTypes,
  startIndexLabel,
}: Props) => {
  const [metrics, updateMetrics] = useImmer<MetricTypeRow[]>(
    metricTypes.map((t) => ({ metricType: t }))
  );

  useEffect(() => {
    setMetricTypes(metrics.map((m) => m.metricType));
  }, [metrics, setMetricTypes]);

  const indexFirstSelected = metrics.findIndex((i) => i.selected);
  const selectedCount = metrics.filter((i) => i.selected).length;
  const indexLastSelected = metrics.map((i) => i.selected).lastIndexOf(true);

  const hasSelected = selectedCount > 0;
  const selectedAllTogether =
    indexLastSelected - indexFirstSelected + 1 === selectedCount;
  const canMoveUp = selectedAllTogether && indexFirstSelected > 0;
  const canMoveDown =
    selectedAllTogether && indexLastSelected + 1 < metrics.length;

  const moveUp = () => {
    updateMetrics((draft) => {
      const indexToMove = indexFirstSelected - 1;
      const toBeMoved = draft.splice(indexFirstSelected, selectedCount);

      draft.splice(indexToMove, 0, ...toBeMoved);
    });
  };

  const moveDown = () => {
    updateMetrics((draft) => {
      const indexToMove = indexFirstSelected + 1;
      const toBeMoved = draft.splice(indexFirstSelected, selectedCount);

      draft.splice(indexToMove, 0, ...toBeMoved);
    });
  };

  const removeMetrics = () => {
    updateMetrics((draft) => {
      return draft.filter((m) => !m.selected);
    });
  };

  return (
    <View>
      {metrics.map((metric, index) => (
        <MetricRowItem
          key={metric.metricType}
          index={index}
          availableMetrics={availableMetrics}
          metricTypes={metricTypes}
          metricRow={metric}
          updateMetrics={updateMetrics}
          startIndexLabel={startIndexLabel || 1}
        />
      ))}
      {hasSelected && (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Button icon="arrow-up-bold" onPress={moveUp} disabled={!canMoveUp}>
            Move up
          </Button>
          <Button
            icon="arrow-down-bold"
            onPress={moveDown}
            disabled={!canMoveDown}
          >
            Move down
          </Button>
          <Button
            icon="trash-can-outline"
            color={'red'}
            onPress={removeMetrics}
          >
            Remove
          </Button>
        </View>
      )}
      <Button
        icon="plus"
        style={{ alignSelf: 'center' }}
        onPress={() => {
          const nextMetric = availableMetrics.find(
            (m) => !metricTypes.includes(m)
          );
          if (nextMetric) {
            updateMetrics((draft) => {
              draft.push({ metricType: nextMetric });
            });
          }
        }}
      >
        Add new metric
      </Button>
    </View>
  );
};

export default MetricTypesField;
