import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import {
  ActivityIndicator,
  Button,
  Dialog,
  Portal,
  Text,
} from 'react-native-paper';
import IntervalsProgress from 'shared/components/IntervalsProgress';
import ChartResult from 'shared/components/ChartResult';
import NotesContent from 'shared/components/NotesContent';
import { MetricType } from 'shared/components/metrics';
import {
  WORKOUT_FRAGMENT,
  WORKOUT_RESULT_FRAGMENT,
} from 'shared/graphql/fragments';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import { colors, sizes } from 'shared/styles';
import {
  CurrentUser,
  DeviceStatus,
  ErgType,
  HrSource,
  IntervalStatus,
  User,
  UserSettings,
  Workout,
  WorkoutResult,
} from 'shared/types';
import { calculateDeviceTypes, formatFitScore } from 'shared/utils';
import styled from 'utils/styled';

interface Props {
  resultId?: string;
  onDismiss(): void;
}

interface ResultUser extends User {
  settings: UserSettings;
}

interface CurrentResult extends WorkoutResult {
  workout: Workout;
  user: CurrentUser;
}

interface QueryData {
  workoutResult: CurrentResult;
}

const GET_RESULT = gql`
  query GetResult($id: ID!) {
    workoutResult(id: $id) {
      ...ResultFragment
      workout {
        ...WorkoutFragment
      }
      user {
        id
        name
        settings {
          displayHeartRate
          maxHeartRate
          restingHeartRate
          weightClass
          gender
          benchmarkValues {
            distance
            name
            scoreNumber
            group
            field
            ergType
          }
        }
      }
    }
  }
  ${WORKOUT_FRAGMENT}
  ${WORKOUT_RESULT_FRAGMENT}
`;

const REJECT_WORKOUT_RESULT = gql`
  mutation RejectWorkoutResult($id: ID!) {
    rejected: workoutResultManualReject(id: $id) {
      ...ResultFragment
      workout {
        ...WorkoutFragment
      }
    }
  }
  ${WORKOUT_FRAGMENT}
  ${WORKOUT_RESULT_FRAGMENT}
`;

function buildInitialMetrics(ergType: ErgType) {
  const metrics: MetricType[] = [];
  if (ergType === ErgType.multi) {
    metrics.push(MetricType.ergType);
  }
  metrics.push(MetricType.time);
  metrics.push(MetricType.distance);
  metrics.push(MetricType.diffPace);
  metrics.push(MetricType.diffRate);
  metrics.push(MetricType.calories);
  metrics.push(MetricType.strokes);
  metrics.push(MetricType.dragFactor);
  metrics.push(MetricType.watts);
  metrics.push(MetricType.spi);
  metrics.push(MetricType.benchmark);
  metrics.push(MetricType.heartRate);
  metrics.push(MetricType.rest);

  return metrics;
}

const Row = styled.view({
  flexDirection: 'row',
  borderBottomColor: colors.dividerColor,
  borderBottomWidth: sizes.pixel,
  padding: 10,
});

const ContentCell = styled.view({
  display: 'flex',
  margin: 10,
  padding: 1,
  flexDirection: 'row',
  flex: 0.8,
});

const HeaderCell = styled.view({
  borderBottomColor: '#ccc',
  marginBottom: '10px solid #ccc',
  display: 'flex',
  margin: 10,
  padding: 1,
  flexDirection: 'row',
  flex: 0.2,
});

const FitResult = ({ result }: { result: CurrentResult }) => {
  return (
    <View>
      <Row>
        <HeaderCell>
          <Text style={styles.boldText}>Workout</Text>
        </HeaderCell>
        <ContentCell>
          <Text>{result.workout.title}</Text>
        </ContentCell>
      </Row>
      <Row>
        <HeaderCell>
          <Text style={styles.boldText}>Description</Text>
        </HeaderCell>
        <ContentCell>
          <Text>{result.workout.description || '-'}</Text>
        </ContentCell>
      </Row>
      <Row>
        <HeaderCell>
          <Text style={styles.boldText}>Athlete</Text>
        </HeaderCell>
        <ContentCell>
          <Text>{result.user.name}</Text>
        </ContentCell>
      </Row>
      <Row>
        <HeaderCell>
          <Text style={styles.boldText}>Date</Text>
        </HeaderCell>
        <ContentCell>
          <Text>{new Date(result.insertedAt).toLocaleDateString()}</Text>
        </ContentCell>
      </Row>
      <Row>
        <HeaderCell>
          <Text style={styles.boldText}>Score</Text>
        </HeaderCell>
        <ContentCell>
          <Text>
            {formatFitScore(result.scoreValue, result.workout.scoreType)}
          </Text>
        </ContentCell>
      </Row>
      <Row>
        <HeaderCell>
          <Text style={styles.boldText}>Rx</Text>
        </HeaderCell>
        <ContentCell>
          <Text>{result.isRx ? 'Yes' : 'No'}</Text>
        </ContentCell>
      </Row>
      <Row>
        <HeaderCell>
          <Text style={styles.boldText}>Notes</Text>
        </HeaderCell>
        <ContentCell>
          <Text>{result.notes || '-'}</Text>
        </ContentCell>
      </Row>
    </View>
  );
};

const IntervalsResult = ({
  workoutResult,
  currentUser,
}: {
  workoutResult: CurrentResult;
  currentUser: CurrentUser;
}) => {
  const { intervals, id, elapsedTime, elapsedDistance } = workoutResult;

  const workoutState = {
    intervals: intervals.map((i) => ({
      ...i,
      status:
        i.status === IntervalStatus.Inactive
          ? IntervalStatus.Inactive
          : IntervalStatus.Completed,
    })),
    ergType: workoutResult.ergType,
    hrSource: HrSource.MACHINE,
    workoutMode: workoutResult.workout.workoutMode!,
    userSettings: { ...workoutResult.user.settings, displayRollingStart: true },
    elapsedTime,
    elapsedDistance,
    deviceStatus: DeviceStatus.WorkoutEnded,
    workoutResultId: id,
    progress: 100,
    activeIndex: -1,
    deviceInfo: {},
    deviceTypes: calculateDeviceTypes(intervals),
    isPro: currentUser.appSubscription.active,
  };

  return (
    <IntervalsProgress
      sharing={false}
      currentUser={{
        ...workoutResult.user,
        settings: workoutState.userSettings,
      }}
      workoutState={workoutState}
      initialMetrics={buildInitialMetrics(workoutResult.ergType)}
      fontSizeVw={1.0}
      darkMode={false}
      hideLastRow
    />
  );
};
const ResultModal: React.FC<Props> = ({ resultId, onDismiss }) => {
  const confirmWith = useConfirmModal();
  const { currentUser } = useCurrentUser();
  const { data } = useQuery<QueryData>(GET_RESULT, {
    variables: { id: resultId },
    fetchPolicy: 'cache-and-network',
  });

  const [rejectWorkoutResult, { loading: rejecting }] = useMutation(
    REJECT_WORKOUT_RESULT
  );

  const onReject = () => {
    confirmWith({
      message:
        'Are you sure you want to reject this result?\nThis result will be unranked from the leaderboard.',
      confirmLabel: 'Reject Result',
      confirmColor: colors.destructiveColor,
      onConfirm: () => {
        rejectWorkoutResult({
          variables: { id: resultId },
        });
      },
    });
  };

  const result = data && data.workoutResult;

  return (
    <Portal>
      <Dialog
        visible={!!resultId}
        onDismiss={onDismiss}
        style={{
          height: '80vh',
          width: '60vw',
          alignSelf: 'center',
        }}
      >
        {result ? (
          <Dialog.ScrollArea>
            <ScrollView>
              {!!result.workout.scoreType && <FitResult result={result} />}
              {result.intervals.length > 0 && (
                <>
                  <IntervalsResult
                    workoutResult={result}
                    currentUser={currentUser}
                  />

                  <ChartResult
                    currentUser={result.user}
                    workoutResult={result}
                    sharing={false}
                  />

                  <NotesContent
                    workout={result.workout}
                    workoutResult={result}
                  />
                </>
              )}
            </ScrollView>
            {result.workout.track.compStatus && (
              <Dialog.Actions style={styles.actionsContainer}>
                {result.hasScore && !result.workout.disabledMessage ? (
                  <Button
                    mode="contained"
                    loading={rejecting}
                    disabled={rejecting}
                    icon="close-circle-outline"
                    onPress={onReject}
                    color={colors.destructiveColor}
                  >
                    REJECT RESULT
                  </Button>
                ) : (
                  <Button mode="outlined" disabled>
                    STATUS:{' '}
                    {result.status ||
                      (result.hasScore ? 'Ranked' : 'Not Ranked')}
                  </Button>
                )}
              </Dialog.Actions>
            )}
          </Dialog.ScrollArea>
        ) : (
          <Dialog.Content>
            <ActivityIndicator />
          </Dialog.Content>
        )}
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  actionsContainer: {
    paddingVertical: sizes.spacing,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
});

export default ResultModal;
