import { useMutation } from '@apollo/react-hooks';
import 'cropperjs/dist/cropper.css';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import Cropper from 'react-cropper';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { Button, Card, Dialog, Portal } from 'react-native-paper';
import CardTitle from 'shared/components/CardTitle';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import { colors } from 'shared/styles';
import { slugify } from 'shared/utils';
import uuid from 'uuid/v4';

interface Props {
  onDismiss(): void;
}

const SIGNED_URL_MUTATION = gql`
  mutation CreateSignedSource($file: String!) {
    signedPublicUrl(file: $file) {
      uploadUrl
    }
  }
`;

const SOURCE_MUTATION = gql`
  mutation CreateSource($input: SourceInput!) {
    sourceCreate(input: $input) {
      id
      name
      imageUrl
    }
  }
`;

const imageToBlob = (imageFile: any) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      const canvas = document.createElement('canvas') as any;
      var ctx = canvas.getContext('2d');
      // @ts-ignore
      canvas.height = this.naturalHeight;
      // @ts-ignore
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      canvas.toBlob((blob: any) => resolve(blob), 'image/jpeg', 0.99);
    };
    img.src = imageFile;
  });
};

const SourceEditModal: React.FC<Props> = ({ onDismiss }) => {
  const confirmWith = useConfirmModal();

  const [bgColor, setBgColor] = useState<string>(colors.primaryColor);
  const [image, setImage] = useState<string>();
  const [cropper, setCropper] = useState<any>();
  const [uploadId] = useState(uuid());

  const getCropData = () => {
    return new Promise((resolve) => {
      cropper
        .getCroppedCanvas({ fillColor: bgColor, width: 200, height: 200 })
        .toBlob((blob: any) => resolve(blob), 'image/jpeg');
    });
  };

  const [saving, setSaving] = useState<boolean>(false);
  const [name, setName] = useState('');

  const [createSource] = useMutation(SOURCE_MUTATION);
  const [createSignedUrl] = useMutation(SIGNED_URL_MUTATION);

  const displayError = (message: string) => {
    confirmWith({ title: 'Error', message, hideCancel: true });
  };

  const validateSource = () => {
    const errors = [];
    if (!name) errors.push("Name can't be blank");
    if (!image) errors.push("Image can't be blank");

    return errors;
  };
  const saveSource = async () => {
    const validationErrors = validateSource();
    if (validationErrors.length > 0) {
      displayError(validationErrors.join('\n'));
      return;
    }
    if (saving) return;
    setSaving(true);

    const uploadFile = async (fileName: string, blob: any) => {
      const {
        data: {
          signedPublicUrl: { uploadUrl },
        },
      } = await createSignedUrl({ variables: { file: fileName } });

      const headers = new Headers();
      headers.append('Content-Type', 'image/jpeg');

      await fetch(uploadUrl, {
        method: 'PUT',
        headers: headers,
        body: blob,
      });
    };

    try {
      const sourceSlug = slugify(name);

      const file = `source/${sourceSlug}/${uploadId}.jpg`;
      const fileOriginal = `source/${sourceSlug}/${uploadId}-original.jpg`;

      const originalBlob = await imageToBlob(image);
      const croppedBlob = await getCropData();

      await uploadFile(fileOriginal, originalBlob);
      await uploadFile(file, croppedBlob);

      await createSource({
        variables: {
          input: {
            name,
            imageUrl: `/${file}`,
          },
        },
      });
      setSaving(false);
      onDismiss();
    } catch (error) {
      setSaving(false);
      if (error) {
        // @ts-ignore
        displayError(error.message);
      }
    }
  };

  return (
    <Portal>
      <Dialog visible onDismiss={onDismiss} style={styles.dialog}>
        <Dialog.Title>New Source</Dialog.Title>
        <ScrollView
          keyboardDismissMode="on-drag"
          contentInsetAdjustmentBehavior="automatic"
          style={styles.container}
        >
          <Card style={styles.cardContainer}>
            <CardTitle
              style={{ marginVertical: 10 }}
              editableTitleProps={{
                value: name,
                onChangeText: setName,
                placeholder: 'Add Name',
              }}
              left={(props) => (
                <TouchableOpacity activeOpacity={0.7} onPress={() => {}}>
                  <div
                    className="img-preview"
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: '50%',
                      overflow: 'hidden',
                      backgroundColor: bgColor,
                    }}
                  />
                </TouchableOpacity>
              )}
            />

            <Card.Content>
              <input
                type="file"
                accept=".jpg,.png"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const reader = new FileReader();
                    const file = e.target.files[0];

                    reader.addEventListener('load', (e) => {
                      setImage(reader.result as any);
                    });
                    reader.readAsDataURL(file);
                  }
                }}
              />

              <label style={{ marginTop: 10, marginBottom: 10 }}>
                <input
                  type="color"
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                  style={{ marginRight: 5 }}
                />
                Background color: {bgColor}
              </label>

              <Cropper
                style={{ height: 400, width: 400, backgroundColor: bgColor }}
                aspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={0}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={false}
              />
            </Card.Content>

            <Card.Actions style={styles.cardActionsContainer}>
              <Button
                mode="outlined"
                onPress={() => {
                  onDismiss();
                }}
              >
                CANCEL
              </Button>
              <Button
                icon="content-save"
                mode="contained"
                onPress={() => {
                  saveSource();
                }}
                loading={saving}
              >
                SAVE
              </Button>
            </Card.Actions>
          </Card>
        </ScrollView>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: { alignSelf: 'center', height: '80vh', width: '80vw', maxWidth: 768 },
  container: {
    backgroundColor: '#eee',
    flex: 1,
  },
  cardContainer: {
    margin: 10,
  },
  cardActionsContainer: {
    justifyContent: 'space-evenly',
  },
});

export default SourceEditModal;
