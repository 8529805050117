import { Link as MuiLink, Tooltip } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';

interface Props {
  to: string;
  tooltip?: string;
  children: ReactNode;
  style?: React.CSSProperties;
}

export { MuiLink };

const Link = ({ to, tooltip, children, style }: Props) => {
  const history = useHistory();
  const link = (
    <MuiLink
      style={style}
      href={to}
      onClick={(e: any) => {
        e.preventDefault();
        if (to.startsWith('/')) {
          history.push(to);
        } else {
          window.location.href = to;
        }
      }}
    >
      {children}
    </MuiLink>
  );

  return !!tooltip ? <Tooltip title={tooltip}>{link}</Tooltip> : link;
};

export default Link;
