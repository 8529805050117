import { useQuery } from '@apollo/react-hooks';
import DataTableRow from 'components/DataTableRow';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Avatar, Button, Card, DataTable, Headline } from 'react-native-paper';
import styled from 'utils/styled';
import SourceEditModal from './SourceEditModal';

const GET_SOURCES = gql`
  query GetSources {
    sources {
      id
      name
      imageUrl
      insertedAt
    }
  }
`;

interface Props {}

const ActionsContainer = styled.view({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  margin: 10,
});

const SourcesList: React.FC<Props> = () => {
  const { data, refetch } = useQuery(GET_SOURCES, {
    fetchPolicy: 'cache-and-network',
  });
  const [showSourceEdit, setShowSourceEdit] = useState(false);

  const sources = data ? data.sources : [];
  return (
    <View>
      <Headline>Sources List</Headline>
      <Card>
        <ActionsContainer>
          <Button
            icon="plus"
            mode="outlined"
            onPress={() => setShowSourceEdit(true)}
          >
            New Source
          </Button>
        </ActionsContainer>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title style={{ flex: 0.2 }}>Image URL</DataTable.Title>
            <DataTable.Title>Name</DataTable.Title>
            <DataTable.Title style={{ flex: 0.4 }}>Inserted At</DataTable.Title>
          </DataTable.Header>

          {sources.map((source: any) => {
            return (
              <DataTableRow key={source.id}>
                <DataTable.Cell style={{ flex: 0.2 }}>
                  <Avatar.Image size={30} source={{ uri: source.imageUrl }} />
                </DataTable.Cell>
                <DataTable.Cell>{source.name}</DataTable.Cell>
                <DataTable.Cell style={{ flex: 0.4 }}>
                  {moment(source.insertedAt).format('L')}
                </DataTable.Cell>
              </DataTableRow>
            );
          })}
        </DataTable>
      </Card>

      {showSourceEdit && (
        <SourceEditModal
          onDismiss={() => {
            refetch();
            setShowSourceEdit(false);
          }}
        />
      )}
    </View>
  );
};

export default SourcesList;
