import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MetricType } from 'shared/components/metrics';
import { ErgType, GridMetricType, IntervalConfig } from 'shared/types';
import { LiveOrder, LiveViewMode } from 'utils/types';

interface PreferencesValue {
  defaultErgType: ErgType;
  setDefaultErgType: (ergType: ErgType) => void;
  intervalConfig: IntervalConfig;
  setIntervalConfig: (config: IntervalConfig) => void;
  showLiveIntervalMetrics: boolean;
  setShowLiveIntervalMetrics: (value: boolean) => void;
  liveMetricTypes: MetricType[];
  setLiveMetricTypes: (types: MetricType[]) => void;
  resultMetricTypes: MetricType[];
  setResultMetricTypes: (types: MetricType[]) => void;
  liveOrder: LiveOrder;
  setLiveOrder: (liveOrder: LiveOrder) => void;
  liveViewMode: LiveViewMode;
  setLiveViewMode: (liveViewMode: LiveViewMode) => void;
  liveFontScale: number;
  setLiveFontScale: (scale: number) => void;
  showLiveReactions: boolean;
  toggleLiveReactions: () => void;
  showAlwaysFullName: boolean;
  toggleShowAlwaysFullName: () => void;
  liveReactionIndex: number;
  setLiveReactionIndex: (index: number) => void;
  calendarMode: boolean;
  toggleCalendarMode: () => void;
  tableMetrics: MetricType[];
  setTableMetrics: (tableMetrics: MetricType[]) => void;
  gridMetrics: GridMetricType[];
  setGridMetrics: (gridMetrics: GridMetricType[]) => void;
}

const PreferencesContext = createContext<PreferencesValue>(
  // @ts-ignore
  {}
);

const USER_PREFERENCES = 'USER_PREFERENCES';

interface Preferences {
  defaultErgType: ErgType;
  intervalConfig: IntervalConfig;
  liveOrder: LiveOrder;
  liveViewMode: LiveViewMode;
  showLiveIntervalMetrics: boolean;
  liveMetricTypes: MetricType[];
  resultMetricTypes: MetricType[];
  liveFontScale: number;
  showLiveReactions: boolean;
  showAlwaysFullName: boolean;
  liveReactionIndex: number;
  calendarMode: boolean;
  tableMetrics: MetricType[];
  gridMetrics: GridMetricType[];
}

let initialPreferences: Preferences = {
  defaultErgType: ErgType.row,
  intervalConfig: {},
  liveOrder: LiveOrder.Progress,
  liveViewMode: LiveViewMode.Rows,
  showLiveIntervalMetrics: false,
  liveMetricTypes: [MetricType.diffPace],
  resultMetricTypes: [],
  liveFontScale: 100,
  showLiveReactions: true,
  showAlwaysFullName: false,
  liveReactionIndex: 0,
  calendarMode: false,
  tableMetrics: [
    MetricType.time,
    MetricType.distance,
    MetricType.diffPace,
    MetricType.diffRate,
    MetricType.calories,
    MetricType.strokes,
    MetricType.dragFactor,
    MetricType.watts,
    MetricType.spi,
    MetricType.benchmark,
    MetricType.heartRate,
    MetricType.hrZone,
    MetricType.rest,
  ],
  gridMetrics: [],
};

const Store = {
  getPreferences: () => {
    const value = localStorage.getItem(USER_PREFERENCES);
    if (value) {
      let { liveOrder, ...storedValues } = JSON.parse(value) as Preferences;
      if (!Object.values(LiveOrder).includes(liveOrder)) {
        liveOrder = initialPreferences.liveOrder;
      }
      return { ...initialPreferences, ...storedValues, liveOrder };
    } else {
      return initialPreferences;
    }
  },
  setPreferences: (preferences: Preferences) =>
    localStorage.setItem(USER_PREFERENCES, JSON.stringify(preferences)),
};

export const PreferencesProvider = (props: any) => {
  const [preferences, setPreferences] = useState(initialPreferences);

  const savePreferences = useCallback((newPreferences: Preferences) => {
    initialPreferences = newPreferences;
    setPreferences(newPreferences);
    Store.setPreferences(newPreferences);
  }, []);

  useEffect(() => {
    const preferences = Store.getPreferences();
    initialPreferences = preferences;
    setPreferences(preferences);
  }, []);

  const {
    defaultErgType,
    intervalConfig,
    liveOrder,
    liveViewMode,
    liveMetricTypes,
    resultMetricTypes,
    showLiveIntervalMetrics,
    liveFontScale,
    showLiveReactions,
    showAlwaysFullName,
    liveReactionIndex,
    calendarMode,
    tableMetrics,
    gridMetrics,
  } = preferences;

  const setDefaultErgType = useCallback(
    (defaultErgType: ErgType) => {
      savePreferences({ ...initialPreferences, defaultErgType });
    },
    [savePreferences]
  );

  const setIntervalConfig = useCallback(
    (config: IntervalConfig) => {
      savePreferences({ ...initialPreferences, intervalConfig: config });
    },
    [savePreferences]
  );

  const setLiveOrder = useCallback(
    (liveOrder: LiveOrder) => {
      savePreferences({ ...initialPreferences, liveOrder });
    },
    [savePreferences]
  );

  const setLiveViewMode = useCallback(
    (liveViewMode: LiveViewMode) => {
      savePreferences({ ...initialPreferences, liveViewMode });
    },
    [savePreferences]
  );

  const setShowLiveIntervalMetrics = useCallback(
    (value: boolean) => {
      savePreferences({
        ...initialPreferences,
        showLiveIntervalMetrics: value,
      });
    },
    [savePreferences]
  );

  const setLiveMetricTypes = useCallback(
    (types: MetricType[]) => {
      savePreferences({
        ...initialPreferences,
        liveMetricTypes: types,
      });
    },
    [savePreferences]
  );

  const setResultMetricTypes = useCallback(
    (types: MetricType[]) => {
      savePreferences({
        ...initialPreferences,
        resultMetricTypes: types,
      });
    },
    [savePreferences]
  );

  const setLiveFontScale = useCallback(
    (liveFontScale: number) => {
      savePreferences({ ...initialPreferences, liveFontScale });
    },
    [savePreferences]
  );

  const toggleLiveReactions = useCallback(() => {
    savePreferences({
      ...initialPreferences,
      showLiveReactions: !initialPreferences.showLiveReactions,
    });
  }, [savePreferences]);

  const toggleShowAlwaysFullName = useCallback(() => {
    savePreferences({
      ...initialPreferences,
      showAlwaysFullName: !initialPreferences.showAlwaysFullName,
    });
  }, [savePreferences]);

  const setLiveReactionIndex = useCallback(
    (liveReactionIndex: number) => {
      savePreferences({
        ...initialPreferences,
        liveReactionIndex,
      });
    },
    [savePreferences]
  );

  const toggleCalendarMode = useCallback(() => {
    savePreferences({
      ...initialPreferences,
      calendarMode: !initialPreferences.calendarMode,
    });
  }, [savePreferences]);

  const setTableMetrics = useCallback(
    (metrics: MetricType[]) => {
      savePreferences({ ...initialPreferences, tableMetrics: metrics });
    },
    [savePreferences]
  );

  const setGridMetrics = useCallback(
    (metrics: GridMetricType[]) => {
      savePreferences({ ...initialPreferences, gridMetrics: metrics });
    },
    [savePreferences]
  );

  const value = {
    defaultErgType,
    setDefaultErgType,
    intervalConfig,
    setIntervalConfig,
    liveOrder,
    setLiveOrder,
    liveViewMode,
    setLiveViewMode,
    showLiveIntervalMetrics,
    setShowLiveIntervalMetrics,
    liveMetricTypes,
    setLiveMetricTypes,
    resultMetricTypes,
    setResultMetricTypes,
    liveFontScale,
    setLiveFontScale,
    showLiveReactions,
    toggleLiveReactions,
    showAlwaysFullName,
    toggleShowAlwaysFullName,
    liveReactionIndex,
    setLiveReactionIndex,
    calendarMode,
    toggleCalendarMode,
    tableMetrics,
    setTableMetrics,
    gridMetrics,
    setGridMetrics,
  };

  return <PreferencesContext.Provider value={value} {...props} />;
};

const usePreferences = () => {
  return useContext(PreferencesContext) as PreferencesValue;
};

export default usePreferences;
