export enum LiveOrder {
  Join = 'join',
  Progress = 'progress',
  Pace = 'pace',
}

export enum LiveViewMode {
  Tiles = 'tiles',
  Rows = 'rows',
}
