import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Button, Dialog, Portal, Text } from 'react-native-paper';
import OptionsPicker from 'shared/components/OptionsPicker';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import useOrientation from 'shared/hooks/useOrientation';
import useSnackBar from 'shared/hooks/useSnackBar';
import { sizes } from 'shared/styles';
import { WorkoutStatus } from 'shared/types';
import { pluralize } from 'shared/utils';

interface Props {
  workoutIds: string[];
  onDismiss: () => void;
  onSuccess: () => void;
}

const BULK_MUTATION = gql`
  mutation BulkUpdateWorkouts($input: WorkoutBulkUpdateInput!) {
    workoutBulkUpdate(input: $input) {
      id
      status
    }
  }
`;

const statusOptions = Object.values(WorkoutStatus).map((status) => ({
  value: status,
  name: capitalize(status),
}));

const WorkoutsBulkStatus = ({ onDismiss, workoutIds, onSuccess }: Props) => {
  const confirmWith = useConfirmModal();
  const showSnackBar = useSnackBar();
  const { vh } = useOrientation();
  const [status, setStatus] = useState(WorkoutStatus.published);

  const [bulkChangeWorkouts, { loading }] = useMutation(BULK_MUTATION);

  const displayError = (message: string) => {
    confirmWith({ title: 'Error', message, hideCancel: true });
  };

  const onSave = () => {
    bulkChangeWorkouts({
      variables: {
        input: {
          status,
          ids: workoutIds,
        },
      },
    })
      .then(() => {
        onSuccess();
        showSnackBar({
          message: `Successfully updated ${workoutIds.length} ${pluralize(workoutIds.length, 'workout', 'workouts')}`,
        });
      })
      .catch(({ graphQLErrors }) => {
        const message =
          graphQLErrors && graphQLErrors.map((e: any) => e.message).join(', ');
        displayError(message || 'Error updating workouts. Please, try again!');
      });
  };

  return (
    <Portal>
      <Dialog
        visible
        onDismiss={onDismiss}
        style={[{ maxHeight: vh(60) }, styles.dialog]}
      >
        <Dialog.Title>
          Update {workoutIds.length}{' '}
          {pluralize(workoutIds.length, 'Workout', 'Workouts')}
        </Dialog.Title>
        <View
          style={{
            marginHorizontal: 20,
            marginBottom: sizes.spacing,
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              marginTop: 10,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text style={{ marginRight: 20 }}>Status:</Text>
            <OptionsPicker
              options={statusOptions}
              currentValue={status}
              onChange={setStatus}
            />
          </View>
        </View>

        <Dialog.Actions style={styles.actionsContainer}>
          <Button mode="outlined" onPress={onDismiss}>
            Cancel
          </Button>
          <Button
            loading={loading}
            disabled={loading}
            mode="contained"
            icon="content-save"
            onPress={onSave}
          >
            Save
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: Platform.select({
    web: {
      alignSelf: 'center',
      width: 500,
    },
  }),
  actionsContainer: {
    paddingVertical: sizes.spacing,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});

export default WorkoutsBulkStatus;
