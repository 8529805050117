import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from '@material-ui/core';
import DataTableRow from 'components/DataTableRow';
import gql from 'graphql-tag';
import usePreferences from 'hooks/usePreferences';
import { capitalize, chain, uniq } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Calendar, momentLocalizer, ToolbarProps } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { TouchableOpacity, View } from 'react-native';
import {
  Button,
  Caption,
  Checkbox,
  DataTable,
  Headline,
  IconButton,
  Menu,
  Searchbar,
  Text,
} from 'react-native-paper';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useHistory, useLocation } from 'react-router-dom';
import ErgIcon from 'shared/components/ErgIcon';
import OptionsPicker from 'shared/components/OptionsPicker';
import { WORKOUT_FRAGMENT } from 'shared/graphql/fragments';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import { colors } from 'shared/styles';
import {
  ErgType,
  Label,
  LabelType,
  Track,
  TrackFeature,
  Workout,
  WorkoutStatus,
} from 'shared/types';
import { getLabelIcon, pluralizePrepend } from 'shared/utils';
import {
  DelimitedArrayParam,
  NumberParam,
  useQueryParam,
} from 'use-query-params';
import styled from 'utils/styled';
import EditVideoModal from './EditVideoModal';
import WorkoutModal from './WorkoutModal';
import WorkoutsBulkDate from './WorkoutsBulkDate';
import WorkoutsBulkDuplicate from './WorkoutsBulkDuplicate';
import WorkoutsBulkStatus from './WorkoutsBulkStatus';
import useDebounceState from 'shared/hooks/useDebounceState';

const localizer = momentLocalizer(moment);
// @ts-ignore
const DnDCalendar = withDragAndDrop(Calendar);

const ActionsContainer = styled.view({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  margin: 10,
});

const GET_WORKOUTS = gql`
  query GetTrack(
    $id: ID!
    $offset: Int
    $search: String
    $limit: Int
    $startDate: String
    $endDate: String
    $labelIds: [ID]
    $ergTypes: [String]
    $workoutStatus: [String]
  ) {
    track(id: $id) {
      ...TrackFragment
      features
      labels {
        id
        name
        type
      }
      ergTypes
      workoutStatuses
      workouts(
        offset: $offset
        search: $search
        limit: $limit
        startDate: $startDate
        endDate: $endDate
        labelIds: $labelIds
        ergType: $ergTypes
        workoutStatus: $workoutStatus
      ) {
        ...WorkoutFragment
        workoutResultsCount
        youtubeId
      }
    }
  }
  ${WORKOUT_FRAGMENT}
`;

const DELETE_WORKOUT_MUTATION = gql`
  mutation DeleteWorkout($workoutId: ID!) {
    workoutDelete(id: $workoutId) {
      id
    }
  }
`;

const BULK_UPDATE_DATE_MUTATION = gql`
  mutation BulkUpdateWorkouts($input: WorkoutBulkUpdateInput!) {
    workoutBulkUpdate(input: $input) {
      id
      publishedAt
    }
  }
`;

interface Props {
  trackId: string;
  isPlan: boolean;
}

interface AdminWorkout extends Workout {
  youtubeId?: string;
  workoutResultsCount?: number;
}

interface AdminTrack extends Track {
  workouts: AdminWorkout[];
  labels: Label[];
  ergTypes: ErgType[];
  workoutStatuses: WorkoutStatus[];
}

interface QueryData {
  track: AdminTrack;
}

interface WorkoutMenuVisible {
  workout: AdminWorkout;
  anchor: {
    x: number;
    y: number;
  };
}

const StatusColor = {
  [WorkoutStatus.draft]: colors.destructiveColor,
  [WorkoutStatus.published]: colors.secondaryColor,
  [WorkoutStatus.locked]: colors.darkBackground,
  [WorkoutStatus.raceLocked]: colors.leaderboardColor,
};
const CalendarEvent = ({ event }: any) => {
  const { workout }: { workout: AdminWorkout } = event;
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text style={{ color: 'white', flexShrink: 1 }} numberOfLines={1}>
        {event.title}
      </Text>
      {workout.status !== WorkoutStatus.published && (
        <Caption
          style={{
            backgroundColor: StatusColor[workout.status],
            color: 'white',
            borderRadius: 8,
            marginLeft: 5,
            paddingHorizontal: 5,
          }}
        >
          {event.workout.status}
        </Caption>
      )}
      {!!workout.workoutResultsCount && (
        <View
          style={{
            width: 20,
            height: 20,
            borderRadius: 10,
            marginLeft: 'auto',
            backgroundColor: colors.membershipColor,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Caption style={{ color: 'white' }}>
            {workout.workoutResultsCount}
          </Caption>
        </View>
      )}
    </View>
  );
};

const CalendarToolbar = ({ onNavigate, label }: ToolbarProps) => {
  return (
    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
      <Headline style={{ width: 200 }}>{label}</Headline>
      <View style={{ marginLeft: 'auto', flexDirection: 'row' }}>
        <Button onPress={() => onNavigate('TODAY')}>Today</Button>
        <Button onPress={() => onNavigate('PREV')}>
          <MaterialCommunityIcon name="chevron-double-left" />
          Previous Month
        </Button>
        <Button onPress={() => onNavigate('NEXT')}>
          Next Month
          <MaterialCommunityIcon name="chevron-double-right" />
        </Button>
      </View>
    </View>
  );
};

const limitOptions = [10, 25, 50];

const getInitialStartEndDate = () => {
  return [
    moment().startOf('month').add(-6, 'days').format('YYYY-MM-DD'),
    moment().endOf('month').add(6, 'days').format('YYYY-MM-DD'),
  ];
};

const WorkoutsTable = ({ trackId, isPlan }: Props) => {
  const confirmWith = useConfirmModal();
  const [selectedWorkout, setSelectedWorkout] = useState<
    AdminWorkout | undefined
  >();
  const [selectedVideoWorkoutId, setSelectedVideoWorkoutId] = useState<
    string | undefined
  >();
  const { calendarMode, toggleCalendarMode } = usePreferences();
  const [workoutMenuVisible, setWorkoutMenuVisibleLocal] = useState<
    WorkoutMenuVisible | undefined
  >();
  const [ergTypes, setErgTypes] = useQueryParam('erg', DelimitedArrayParam);
  const [workoutStatus, setWorkoutStatus] = useQueryParam(
    'status',
    DelimitedArrayParam
  );
  const [labelIds, setLabelIds] = useQueryParam('label', DelimitedArrayParam);
  const handleLabelIdsChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setLabelIds(value ? [value] : []);
  };
  const handleErgTypeChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setErgTypes(value ? [value] : []);
  };
  const handleWorkoutStatusChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setWorkoutStatus(value ? [value] : []);
  };
  const setWorkoutMenuVisible = (visible?: WorkoutMenuVisible) => {
    if (visible) {
      setWorkoutMenuVisibleLocal(visible);
    } else {
      const overlay = document.querySelector('.rbc-overlay');
      if (overlay) {
        // @ts-ignore
        overlay.style.display = 'none';
      }
      setWorkoutMenuVisibleLocal(undefined);
    }
  };
  const [bulkDuplicateVisible, setBulkDuplicateVisible] = useState(false);
  const [bulkDateVisible, setBulkDateVisible] = useState(false);
  const [bulkStatusVisible, setBulkStatusVisible] = useState(false);
  const [pageParam, setPage] = useQueryParam('page', NumberParam);
  const [limitParam, setLimit] = useQueryParam('limit', NumberParam);
  const limit = limitOptions.includes(limitParam || 0)
    ? limitParam!
    : limitOptions[0];
  const page = pageParam || 1;

  const history = useHistory();
  const location = useLocation();

  const { defaultErgType } = usePreferences();

  const [search, setSearch, unbouncedSearch] = useDebounceState(
    new URLSearchParams(location.search).get('search') || '',
    (newValue) => {
      const searchParams = new URLSearchParams(location.search);
      if (newValue) {
        searchParams.set('search', newValue);
      } else {
        searchParams.delete('search');
      }
      history.replace({ search: searchParams.toString() });
    }
  );

  const [selectedWorkoutIds, setSelectedWorkoutIds] = useState<string[]>([]);
  const [startEndDate, setStartEndDate] = useQueryParam(
    'date',
    DelimitedArrayParam
  );
  const [startDate, endDate] =
    !isPlan && calendarMode
      ? (startEndDate || []).length > 0
        ? startEndDate!
        : getInitialStartEndDate()
      : [];

  const displayError = (message: string) => {
    confirmWith({ title: 'Error', message, hideCancel: true });
  };

  const toggleWorkoutId = (id: string) => {
    setSelectedWorkoutIds((ids) => {
      if (ids.includes(id)) {
        return ids.filter((i) => i !== id);
      } else {
        return [...ids, id];
      }
    });
  };

  const [deleteWorkout] = useMutation(DELETE_WORKOUT_MUTATION);
  const [bulkUpdateWorkoutDate] = useMutation(BULK_UPDATE_DATE_MUTATION);
  const { data, refetch } = useQuery<QueryData>(GET_WORKOUTS, {
    variables: {
      offset: startDate ? 0 : (page - 1) * limit,
      id: trackId,
      limit: startDate ? 100 : limit,
      search: !!search ? search : null,
      startDate,
      endDate,
      labelIds,
      ergTypes,
      workoutStatus,
    },
    fetchPolicy: 'cache-and-network',
  });
  const track = data && data.track;

  const trackWorkoutIds = track ? track.workouts.map((w) => w.id) : [];
  const isAllSelected = useMemo(() => {
    return (
      trackWorkoutIds.length > 0 &&
      trackWorkoutIds.every((id) => selectedWorkoutIds.includes(id))
    );
  }, [selectedWorkoutIds, trackWorkoutIds]);

  if (!track) return null;

  const updateWorkoutDate = (workoutId: string, publishedAt: string) => {
    bulkUpdateWorkoutDate({
      variables: { input: { ids: [workoutId], publishedAt } },
    }).catch(({ graphQLErrors }) => {
      const message =
        graphQLErrors && graphQLErrors.map((e: any) => e.message).join(', ');
      displayError(
        message || 'Error updating workout date. Please, try again!'
      );
    });
  };

  const selectedVideoWorkout = selectedVideoWorkoutId
    ? track.workouts.find((w) => w.id === selectedVideoWorkoutId)
    : undefined;

  const manageVideo = track.features.includes(TrackFeature.Video);
  const manageAudio = track.features.includes(TrackFeature.Audio);
  const hasLabels = track.labels.length > 0;
  const hasErgTypes = track.ergTypes.length > 1;
  const hasWorkoutStatuses = track.workoutStatuses.length > 1;

  return (
    <>
      <ActionsContainer>
        <Searchbar
          style={{ marginRight: 10 }}
          inputStyle={{ width: 300, fontSize: 15 }}
          placeholder="Search title and description"
          onChangeText={setSearch}
          value={unbouncedSearch}
        />
        {hasErgTypes && (
          <FormControl
            variant="outlined"
            style={{ marginRight: 10, minWidth: 80 }}
            margin="dense"
          >
            <InputLabel htmlFor="ergs-select">Erg</InputLabel>

            <Select
              value={ergTypes}
              onChange={handleErgTypeChange}
              id="ergs-select"
              label="Erg"
            >
              <MenuItem value="">
                <em>Clear</em>
              </MenuItem>
              {track.ergTypes.map((ergType) => {
                return (
                  <MenuItem key={ergType} value={ergType}>
                    {capitalize(ergType)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {hasWorkoutStatuses && (
          <FormControl
            variant="outlined"
            style={{ marginRight: 10, minWidth: 100 }}
            margin="dense"
          >
            <InputLabel htmlFor="status-select">Status</InputLabel>

            <Select
              value={workoutStatus}
              onChange={handleWorkoutStatusChange}
              id="status-select"
              label="Status"
            >
              <MenuItem value="">
                <em>Clear</em>
              </MenuItem>
              {track.workoutStatuses.map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    {capitalize(status)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        {hasLabels && (
          <FormControl
            variant="outlined"
            style={{ marginRight: 10, minWidth: 90 }}
            margin="dense"
          >
            <InputLabel htmlFor="labels-select">Label</InputLabel>

            <Select
              value={labelIds}
              onChange={handleLabelIdsChange}
              id="labels-select"
              label="Label"
            >
              <MenuItem value="">
                <em>Clear</em>
              </MenuItem>
              {chain(track.labels)
                .groupBy('type')
                .map((labels, type) => [type, ...(labels || [])])
                .flattenDeep()
                .map((value) => {
                  if (typeof value === 'string') {
                    return (
                      <ListSubheader key={value}>
                        <MaterialCommunityIcon
                          name={getLabelIcon(value as LabelType)}
                          style={{ marginRight: 5 }}
                          color={colors.textColor}
                        />
                        {capitalize(value)}
                      </ListSubheader>
                    );
                  } else {
                    const label = value;
                    return (
                      <MenuItem key={label.id} value={label.id}>
                        {label.name}
                      </MenuItem>
                    );
                  }
                })
                .value()}
            </Select>
          </FormControl>
        )}
        {!isPlan && (
          <FormControlLabel
            style={{ marginRight: 10 }}
            control={
              <Switch
                checked={!!calendarMode}
                onChange={() => {
                  if (calendarMode) {
                    setStartEndDate([]);
                  }
                  toggleCalendarMode();
                }}
              />
            }
            label="Calendar"
          />
        )}
        <Button
          style={{ marginLeft: 'auto' }}
          icon="plus"
          mode="outlined"
          onPress={() =>
            setSelectedWorkout({
              id: undefined!,
              title: undefined!,
              description: undefined!,
              hasLeaderboard: false,
              publishedAt: moment().format('YYYY-MM-DD'),
              status: WorkoutStatus.published,
              intervals: [],
              labels: [],
              track,
              ergType: defaultErgType || ErgType.row,
              machineTypes: [],
            })
          }
        >
          New Workout
        </Button>
      </ActionsContainer>
      {selectedWorkoutIds.length > 0 && (
        <ActionsContainer>
          <Button
            mode="contained"
            style={{ marginLeft: 10 }}
            icon="content-copy"
            onPress={() => setBulkDuplicateVisible(true)}
          >
            Duplicate
          </Button>
          <Button
            mode="contained"
            style={{ marginLeft: 10 }}
            icon="calendar-blank"
            onPress={() => setBulkDateVisible(true)}
          >
            Update Date
          </Button>
          <Button
            mode="contained"
            style={{ marginLeft: 10 }}
            icon="eye-circle-outline"
            onPress={() => setBulkStatusVisible(true)}
          >
            Update Status
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            onPress={() => setSelectedWorkoutIds([])}
          >
            Unselect {selectedWorkoutIds.length}
          </Button>
        </ActionsContainer>
      )}

      {calendarMode && !isPlan ? (
        <DnDCalendar
          key={trackId}
          defaultDate={
            startDate
              ? moment(startDate).add(7, 'days').toDate()
              : moment().toDate()
          }
          views={['month']}
          events={track.workouts.map((w) => ({
            id: w.id,
            title: w.title,
            workout: w,
            start: moment(w.publishedAt).toDate(),
            end: moment(w.publishedAt).toDate(),
            allDay: true,
          }))}
          tooltipAccessor={(e) =>
            // @ts-ignore
            [e.title, e.workout.description].join('\n')
          }
          localizer={localizer}
          onEventDrop={(data) => {
            updateWorkoutDate(
              // @ts-ignore
              data.event.id,
              moment(data.start).format('YYYY-MM-DD')
            );
          }}
          onRangeChange={(data) => {
            setStartEndDate([
              // @ts-ignore
              moment(data.start).format('YYYY-MM-DD'),
              // @ts-ignore
              moment(data.end).format('YYYY-MM-DD'),
            ]);
          }}
          onSelectEvent={(event, e) => {
            // @ts-ignore
            const rect = e.target.getBoundingClientRect();
            const anchor = { x: rect.left, y: rect.top };

            // @ts-ignore
            const overlay = e.target.closest('.rbc-overlay');
            if (overlay) {
              //@ts-ignore
              let overlayRect = e.target.getBoundingClientRect();
              if (overlayRect.right + 200 > window.innerWidth) {
                anchor.x -= overlay.offsetWidth;
              } else {
                anchor.x += overlay.offsetWidth;
              }
            }

            setWorkoutMenuVisible({
              // @ts-ignore
              workout: event.workout,
              anchor,
            });
          }}
          resizable={false}
          popup
          style={{ height: 800, margin: 10 }}
          components={{
            toolbar: CalendarToolbar,
            event: CalendarEvent,
          }}
        />
      ) : (
        <DataTable>
          <DataTable.Header>
            <DataTable.Cell style={{ flex: 0.2 }}>
              <Checkbox.Android
                status={isAllSelected ? 'checked' : 'unchecked'}
                onPress={() =>
                  isAllSelected
                    ? setSelectedWorkoutIds(
                        selectedWorkoutIds.filter(
                          (id) => !trackWorkoutIds.includes(id)
                        )
                      )
                    : setSelectedWorkoutIds(
                        uniq([...selectedWorkoutIds, ...trackWorkoutIds])
                      )
                }
              />
            </DataTable.Cell>
            <DataTable.Title style={{ flex: 0.2 }}>Erg</DataTable.Title>
            <DataTable.Title style={{ flex: 2 }}>Title</DataTable.Title>
            {hasLabels && <DataTable.Title>Labels</DataTable.Title>}
            <DataTable.Title>Results Count</DataTable.Title>
            <DataTable.Title>Status</DataTable.Title>
            {isPlan ? (
              <DataTable.Title>Week</DataTable.Title>
            ) : (
              <DataTable.Title>Published At</DataTable.Title>
            )}
            <DataTable.Title style={{ flex: 0.5 }} numeric>
              Actions
            </DataTable.Title>
          </DataTable.Header>
          {track.workouts.map((workout) => {
            return (
              <DataTableRow
                style={
                  workoutMenuVisible &&
                  workoutMenuVisible.workout.id === workout.id && {
                    backgroundColor: colors.lightGray,
                  }
                }
                onPress={() => history.push(`/workouts/${workout.id}/results`)}
                key={workout.id}
              >
                <View style={{ flex: 0.2 }}>
                  <TouchableOpacity
                    style={{
                      marginLeft: -16,
                      height: '100%',
                      paddingLeft: 16,
                      justifyContent: 'center',
                    }}
                    activeOpacity={1}
                    onPress={() => {}}
                  >
                    <Checkbox.Android
                      status={
                        selectedWorkoutIds.includes(workout.id)
                          ? 'checked'
                          : 'unchecked'
                      }
                      onPress={() => toggleWorkoutId(workout.id)}
                    />
                  </TouchableOpacity>
                </View>
                <DataTable.Cell style={{ flex: 0.2 }}>
                  <ErgIcon
                    ergType={workout.ergType}
                    color="black"
                    size={17}
                  ></ErgIcon>
                </DataTable.Cell>
                <DataTable.Cell style={{ flex: 2 }}>
                  {!!workout.description ? (
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                          {workout.description}
                        </span>
                      }
                    >
                      <span>{workout.title}</span>
                    </Tooltip>
                  ) : (
                    workout.title
                  )}
                </DataTable.Cell>
                {hasLabels && (
                  <DataTable.Cell>
                    {workout.labels.map((l) => l.name).join(', ')}
                  </DataTable.Cell>
                )}
                <DataTable.Cell>{workout.workoutResultsCount}</DataTable.Cell>
                <DataTable.Cell>{workout.status}</DataTable.Cell>
                {isPlan ? (
                  <DataTable.Cell>
                    W{workout.planWeek} - D{workout.planDay}
                  </DataTable.Cell>
                ) : (
                  <DataTable.Cell>{workout.publishedAt}</DataTable.Cell>
                )}
                <View style={{ flex: 0.5, alignItems: 'flex-end' }}>
                  <TouchableOpacity
                    style={{
                      marginRight: -16,
                      height: '100%',
                      paddingRight: 16,
                      justifyContent: 'center',
                    }}
                    activeOpacity={1}
                    onPress={() => {}}
                  >
                    <IconButton
                      testID="workout-card-options"
                      icon="dots-vertical"
                      // @ts-ignore
                      onPress={(e) => {
                        // @ts-ignore
                        const rect = e.target.getBoundingClientRect();
                        setWorkoutMenuVisible({
                          workout,
                          anchor: { x: rect.left, y: rect.top },
                        });
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </DataTableRow>
            );
          })}

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <OptionsPicker
              currentValue={limit}
              onChange={setLimit}
              options={limitOptions.map((o) => ({ value: o, name: String(o) }))}
            />
            <DataTable.Pagination
              page={page}
              numberOfPages={0}
              onPageChange={setPage}
              label={`Page ${page}`}
            />
          </View>
        </DataTable>
      )}
      <WorkoutModal
        workout={selectedWorkout}
        onDismiss={() => setSelectedWorkout(undefined)}
        refreshWorkouts={refetch}
      />
      {selectedVideoWorkout && (
        <EditVideoModal
          workout={selectedVideoWorkout}
          isAudio={manageAudio}
          onDismiss={() => setSelectedVideoWorkoutId(undefined)}
        />
      )}
      {bulkDuplicateVisible && (
        <WorkoutsBulkDuplicate
          currentTrackId={track.id}
          workoutIds={selectedWorkoutIds}
          onDismiss={() => setBulkDuplicateVisible(false)}
          onSuccess={(trackId) => {
            setBulkDuplicateVisible(false);
            setSelectedWorkoutIds([]);
            if (trackId !== track.id) {
              history.push(`/tracks/${trackId}/workouts`);
            } else {
              refetch();
            }
          }}
        />
      )}
      {bulkDateVisible && (
        <WorkoutsBulkDate
          workoutIds={selectedWorkoutIds}
          onDismiss={() => setBulkDateVisible(false)}
          onSuccess={() => {
            setBulkDateVisible(false);
            refetch();
          }}
        />
      )}
      {bulkStatusVisible && (
        <WorkoutsBulkStatus
          workoutIds={selectedWorkoutIds}
          onDismiss={() => setBulkStatusVisible(false)}
          onSuccess={() => {
            setBulkStatusVisible(false);
            refetch();
          }}
        />
      )}
      <Menu
        visible={!!workoutMenuVisible}
        onDismiss={() => setWorkoutMenuVisible(undefined)}
        anchor={workoutMenuVisible ? workoutMenuVisible.anchor : {}}
      >
        {workoutMenuVisible && (
          <>
            <Caption
              style={{ marginLeft: 10, maxWidth: 200 }}
              numberOfLines={1}
            >
              {workoutMenuVisible.workout.title}
            </Caption>
            {manageVideo && (
              <Menu.Item
                icon="play-circle"
                title="Manage Video"
                onPress={() => {
                  setSelectedVideoWorkoutId(workoutMenuVisible.workout.id);
                  setWorkoutMenuVisible(undefined);
                }}
              />
            )}
            {manageAudio && (
              <Menu.Item
                icon="play-circle"
                title="Manage Audio"
                onPress={() => {
                  setSelectedVideoWorkoutId(workoutMenuVisible.workout.id);
                  setWorkoutMenuVisible(undefined);
                }}
              />
            )}
            <Menu.Item
              icon="pencil"
              title="Edit Workout"
              onPress={() => {
                setSelectedWorkout(workoutMenuVisible.workout);
                setWorkoutMenuVisible(undefined);
              }}
            />
            <Menu.Item
              icon="content-copy"
              title="Duplicate Workout"
              onPress={() => {
                setSelectedWorkout({
                  ...workoutMenuVisible.workout,
                  id: undefined!,
                  originalWorkoutId: workoutMenuVisible.workout.id,
                  scoreOptions: [],
                  publishedAt: moment().format('YYYY-MM-DD'),
                });
                setWorkoutMenuVisible(undefined);
              }}
            />
            <Menu.Item
              icon="trash-can-outline"
              title="Delete Workout"
              onPress={() => {
                const workout = workoutMenuVisible.workout;
                confirmWith({
                  message: `This workout and all its results will be removed. Are you sure you want to delete '${workout.title}'?`,
                  confirmLabel: 'Delete',
                  onConfirm: () => {
                    deleteWorkout({
                      variables: { workoutId: workout.id },
                    }).then(() => {
                      refetch();
                    });
                  },
                });
                setWorkoutMenuVisible(undefined);
              }}
            />
            <Menu.Item
              icon="speedometer"
              title={`View ${pluralizePrepend(workoutMenuVisible.workout.workoutResultsCount || 0, 'Result', 'Results')}`}
              onPress={() => {
                history.push(
                  `/workouts/${workoutMenuVisible.workout.id}/results`
                );
              }}
            />
          </>
        )}
      </Menu>
    </>
  );
};

export default WorkoutsTable;
