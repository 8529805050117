import { Switch } from '@material-ui/core';
import usePreferences from 'hooks/usePreferences';
import React, { useMemo } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Dialog, Divider, List, Text } from 'react-native-paper';
import { METRICS, MetricType } from 'shared/components/metrics';
import { LiveOrder, LiveViewMode } from 'utils/types';
import MetricTypesField from './MetricTypesField';
import { sortBy } from 'lodash';
import OptionsPicker from 'shared/components/OptionsPicker';

interface Props {
  onDismiss: () => void;
}

const AVAILABLE_METRICS = [
  MetricType.time,
  MetricType.distance,
  MetricType.pace,
  MetricType.rate,
  MetricType.diffPace,
  MetricType.diffRate,
  MetricType.avgPace,
  MetricType.heartRate,
  MetricType.heartRateRaw,
  MetricType.hrZone,
  MetricType.watts,
  MetricType.wattsDiff,
  MetricType.spi,
  MetricType.calories,
  MetricType.benchmark,
  MetricType.spiPercent,
  MetricType.ftpPercent,
  MetricType.maxPowerPercent,
  MetricType.rest,
  MetricType.strokes,
  MetricType.dragFactor,
  MetricType.timePlusRest,
  MetricType.distancePlusRest,
  MetricType.driveLength,
  MetricType.strokeDistance,
  MetricType.peakPower,
  MetricType.avgForce,
  MetricType.peakForce,
  MetricType.forceEfficiency,
  MetricType.ratio,
  MetricType.wattsKg,
  MetricType.forceCurve,
  MetricType.calHour,
  MetricType.kph,
  MetricType.mph,
  MetricType.projectedTime,
  MetricType.projectedDistance,
  MetricType.notes,
  MetricType.ergType,
  MetricType.division,
];

const LiveSettings = ({ onDismiss }: Props) => {
  const {
    liveMetricTypes,
    setLiveMetricTypes,
    showLiveIntervalMetrics,
    setShowLiveIntervalMetrics,
    liveOrder,
    liveViewMode,
    setLiveOrder,
    showLiveReactions,
    toggleLiveReactions,
    showAlwaysFullName,
    toggleShowAlwaysFullName,
  } = usePreferences();

  const availableMetrics = useMemo(() => {
    return sortBy(AVAILABLE_METRICS, (metric) => METRICS[metric].name);
  }, []);

  return (
    <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
      <Dialog visible onDismiss={onDismiss} style={styles.dialog}>
        <Dialog.Title>Metrics</Dialog.Title>

        <ScrollView>
          {liveViewMode === LiveViewMode.Tiles && (
            <List.Item
              title={
                <Text>
                  Display current metrics:{' '}
                  <Text style={{ fontWeight: 'bold' }}>
                    {showLiveIntervalMetrics ? 'off' : 'on'}
                  </Text>
                </Text>
              }
              description="When off it displays interval metrics"
              right={() => (
                <Switch
                  checked={!showLiveIntervalMetrics}
                  onChange={() => {
                    setShowLiveIntervalMetrics(!showLiveIntervalMetrics);
                  }}
                />
              )}
            />
          )}
          <List.Item
            title={
              <Text>
                Race mode:{' '}
                <Text style={{ fontWeight: 'bold' }}>
                  {liveOrder !== LiveOrder.Join ? 'on' : 'off'}
                </Text>
              </Text>
            }
            right={() => (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <OptionsPicker
                  options={[
                    { value: LiveOrder.Join, name: 'Off' },
                    { value: LiveOrder.Pace, name: 'By Avg Pace' },
                    {
                      value: LiveOrder.Progress,
                      name: 'By Distance',
                    },
                  ]}
                  currentValue={liveOrder}
                  onChange={(newOrder) => {
                    setLiveOrder(newOrder);
                  }}
                />
              </View>
            )}
          />
          <List.Item
            title={
              <Text>
                Live reactions:{' '}
                <Text style={{ fontWeight: 'bold' }}>
                  {showLiveReactions ? 'on' : 'off'}
                </Text>
              </Text>
            }
            right={() => (
              <Switch
                checked={showLiveReactions}
                onChange={() => {
                  toggleLiveReactions();
                }}
              />
            )}
          />
          <List.Item
            title={
              <Text>
                Always Full Name:{' '}
                <Text style={{ fontWeight: 'bold' }}>
                  {showAlwaysFullName ? 'on' : 'off'}
                </Text>
              </Text>
            }
            right={() => (
              <Switch
                checked={showAlwaysFullName}
                onChange={() => {
                  toggleShowAlwaysFullName();
                }}
              />
            )}
          />
          <MetricTypesField
            availableMetrics={availableMetrics}
            metricTypes={liveMetricTypes}
            setMetricTypes={setLiveMetricTypes}
          />
        </ScrollView>

        <Divider />
        <Dialog.Actions>
          <Button onPress={onDismiss}>Close</Button>
        </Dialog.Actions>
      </Dialog>
    </div>
  );
};

const styles = StyleSheet.create({
  dialog: {
    height: '90vh',
    alignSelf: 'center',
    width: '90%',
    maxWidth: 400,
  },
});

export default LiveSettings;
