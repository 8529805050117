import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import { capitalize, sortBy } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import {
  Avatar,
  Button,
  Dialog,
  List,
  Portal,
  RadioButton,
  Searchbar,
  Text,
} from 'react-native-paper';
import DatePicker from 'shared/components/DatePicker';
import OptionsPicker from 'shared/components/OptionsPicker';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import useDebouncedState from 'shared/hooks/useDebounceState';
import useOrientation from 'shared/hooks/useOrientation';
import useSnackBar from 'shared/hooks/useSnackBar';
import { rem, sizes } from 'shared/styles';
import { Track, WorkoutStatus } from 'shared/types';
import { pluralize } from 'shared/utils';

interface Props {
  currentTrackId: string;
  workoutIds: string[];
  onDismiss: () => void;
  onSuccess: (trackId: string) => void;
}

const DUPLICATE_MUTATION = gql`
  mutation DuplicateWorkouts($input: WorkoutBulkDuplicateInput!) {
    workoutBulkDuplicate(input: $input) {
      id
    }
  }
`;

const TrackItem = ({
  track,
  onSelected,
  selected,
}: {
  track: Track;
  selected: boolean;
  onSelected: () => void;
}) => {
  return (
    <List.Item
      title={track.name}
      description={track.source.name}
      left={() => (
        <Avatar.Image
          size={rem(3)}
          source={{
            uri: track.imageUrl,
          }}
        />
      )}
      right={() => (
        <RadioButton.Android
          onPress={onSelected}
          value={track.id}
          status={selected ? 'checked' : 'unchecked'}
        />
      )}
      onPress={onSelected}
    />
  );
};

enum DateOption {
  Today = 'Today',
  Original = 'Original Date',
  Other = 'Choose Date',
}

const statusOptions = Object.values(WorkoutStatus).map((status) => ({
  value: status,
  name: capitalize(status),
}));

const dateOptions = Object.values(DateOption).map((option) => ({
  value: option,
  name: option,
}));

const WorkoutsBulkDuplicate = ({
  onDismiss,
  currentTrackId,
  workoutIds,
  onSuccess,
}: Props) => {
  const confirmWith = useConfirmModal();
  const showSnackBar = useSnackBar();
  const { vh } = useOrientation();
  const { adminTracks } = useCurrentUser();
  const [publishedAt, setPublishedAt] = useState<string | null>(
    moment().format('YYYY-MM-DD')
  );
  const [status, setStatus] = useState(WorkoutStatus.draft);
  const [selectedTrackId, setSelectedTrackId] = useState(currentTrackId);
  const [dateOption, setDateOption] = useState(DateOption.Today);
  const [search, setSearch, unbouncedSearch] = useDebouncedState('');

  const [duplicateWorkouts, { loading }] = useMutation(DUPLICATE_MUTATION);

  const displayError = (message: string) => {
    confirmWith({ title: 'Error', message, hideCancel: true });
  };

  const tracks = sortBy(
    adminTracks.filter((s) =>
      s.name.toLowerCase().includes(search.toLowerCase())
    ),
    (t) => t.id !== currentTrackId
  );

  const onSave = () => {
    duplicateWorkouts({
      variables: {
        input: {
          status,
          publishedAt,
          ids: workoutIds,
          trackId: selectedTrackId,
        },
      },
    })
      .then(() => {
        onSuccess(selectedTrackId);
        showSnackBar({
          message: `Successfully duplicated ${workoutIds.length} ${pluralize(workoutIds.length, 'workout', 'workouts')}`,
        });
      })
      .catch(({ graphQLErrors }) => {
        const message =
          graphQLErrors && graphQLErrors.map((e: any) => e.message).join(', ');
        displayError(
          message || 'Error duplicating workouts. Please, try again!'
        );
      });
  };

  return (
    <Portal>
      <Dialog
        visible
        onDismiss={onDismiss}
        style={[{ height: vh(60) }, styles.dialog]}
      >
        <Dialog.Title>
          Duplicate {workoutIds.length}{' '}
          {pluralize(workoutIds.length, 'Workout', 'Workouts')}
        </Dialog.Title>
        <View
          style={{
            marginHorizontal: 20,
            marginBottom: sizes.spacing,
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text style={{ marginRight: 20 }}>Publish Date:</Text>
            <OptionsPicker
              style={{ marginRight: 10 }}
              options={dateOptions}
              currentValue={dateOption}
              onChange={(option) => {
                setDateOption(option);
                switch (option) {
                  case DateOption.Original:
                    setPublishedAt(null);
                    break;
                  default:
                    setPublishedAt(moment().format('YYYY-MM-DD'));
                    break;
                }
              }}
            />
            {dateOption === DateOption.Other && publishedAt && (
              <DatePicker
                date={moment(publishedAt)}
                onConfirm={(date) => {
                  setPublishedAt(date.format('YYYY-MM-DD'));
                }}
                title="Publish Date"
              />
            )}
          </View>
          <View
            style={{
              marginTop: 10,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text style={{ marginRight: 20 }}>Status:</Text>
            <OptionsPicker
              options={statusOptions}
              currentValue={status}
              onChange={setStatus}
            />
          </View>

          <Searchbar
            style={{ height: 40, marginTop: 10 }}
            placeholder="Search"
            value={unbouncedSearch}
            onChangeText={setSearch}
          />
        </View>
        <Dialog.ScrollArea>
          <ScrollView>
            {tracks.map((track) => (
              <TrackItem
                key={track.id}
                selected={selectedTrackId === track.id}
                track={track}
                onSelected={() => setSelectedTrackId(track.id)}
              />
            ))}
          </ScrollView>
        </Dialog.ScrollArea>

        <Dialog.Actions style={styles.actionsContainer}>
          <Button mode="outlined" onPress={onDismiss}>
            Cancel
          </Button>
          <Button
            loading={loading}
            disabled={loading}
            mode="contained"
            icon="content-save"
            onPress={onSave}
          >
            Save
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: Platform.select({
    web: {
      alignSelf: 'center',
      width: 500,
    },
  }),
  actionsContainer: {
    paddingVertical: sizes.spacing,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});

export default WorkoutsBulkDuplicate;
